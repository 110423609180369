import React, { useEffect, useState } from "react";
import { Table, Spin, Select } from "antd";
import { Typography, Button, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmptyFamilyGeneric,
  editFamilyGeneric
} from "./store/actions/familyGeneric.actions";
import ConfirmDialog from "../common/confirmDialog";
import getNepaliNumber from "../../../../utils/getNepaliNumber";
import OptionSelection from "./OptionSelection";

const { Column } = Table;

export default function FamilyGeneric(props) {
  const config = [
    "primaryDetails",
    "secondaryDetails",
    "contactNumber",
    "gender",
    "ethnics",
    "religion",
    "martialStatus",
    "caste",
    "job",
    "business",
    "healthCondition",
    "motherTongue",
    "bloodGroup",
    "voterId",
    "unhealthyDisease",
    "type",
    "condition",
    "hasCard",
    "cardType",
    "skill"
  ];
  const dispatch = useDispatch();
  const { list, loading } = useSelector(
    ({ dataCorrection }) => dataCorrection.emptyField.familyGeneric
  );
  const [open, setOpen] = React.useState(false);
  const [memberId, setMemberId] = useState("");
  const [inputText, setText] = useState({});
  const [editIndex, setEditIndex] = useState(-1);
  const [errorType, setErrorType] = useState("");

  const handleClickOpen = (id, index, errorType) => () => {
    setEditIndex(index);
    setMemberId(id);
    setErrorType(errorType);
  };

  const handleClose = value => {
    setEditIndex(-1);
    setMemberId("");
    setErrorType("");
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getEmptyFamilyGeneric());
  }, [dispatch]);

  const handleChange = (name, value) => {
    setText({ [name]: value });
  };

  const onSave = () => {
    let data = {
      inputText,
      memberId: memberId,
      errorType: errorType
    };
    dispatch(editFamilyGeneric(data));
    handleClose();
  };

  let mapColumn = [
    {
      title: "सर्वेक्षण काेड",
      dataIndex: "dataCollectionCode",
      key: "dataCollectionCode",
      width: 200
    },
    {
      title: "घर नं",
      dataIndex: "houseNumber",
      key: "houseNumber",
      width: 150,
      render: getNepaliNumber,
      sorter: (a, b) => a.houseNumber - b.houseNumber
    },
    {
      title: "वडा नं",
      dataIndex: "wardNumber",
      key: "wardNumber",
      render: getNepaliNumber,
      width: 150
    },
    {
      title: "नाम/थर",
      dataIndex: "fullname",
      key: "fullname",
      width: 200
    },
    {
      title: "खाली छोडनुभएको",
      dataIndex: "errorType",
      key: "errorType",
      filters: config.map(each => ({ text: each, value: each })),
      onFilter: (value, record) => record.errorType.indexOf(value) === 0,
      width: 250
    },
    {
      title: "घरमुली संगको नाता",
      dataIndex: "key",
      key: "key",
      width: 250,
      render: (relation, record, index) =>
        // editIndex === index ? optionSelection(record.errorType) : ""
        editIndex === index ? (
          <OptionSelection
            handleChange={handleChange}
            options={record.errorType}
          />
        ) : (
          ""
        )
    }
  ];

  return (
    <div>
      <Typography variant="h4" component="h2" align="center">
        परिवार सदस्यको खाली रहेको डाटा
      </Typography>
      <Grid container spacing={24}>
        {loading ? (
          // getLoading(5)
          <div style={{ display: "block", margin: "0 auto" }}>
            <Spin size="large" />
          </div>
        ) : (
          <Table
            style={{ width: "100%", margin: "0px 5px" }}
            dataSource={list}
            rowKey="uid"
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: true
              //   pageSizeOptions: ["10", "20", "30"]
            }}
          >
            <Column
              title={<span className="font-semibold">क्र.श</span>}
              dataIndex={"sn"}
              key={"sn"}
              render={(text, record, index) => <p>{index + 1}</p>}
            />

            {mapColumn.map(column => (
              <Column {...column} />
            ))}
            <Column
              title={<span className="font-semibold">क्रियाकलाप</span>}
              key="action"
              render={(each, record, index) => {
                return (
                  <span>
                    <Button
                      color="primary"
                      //   onClick={handleClickOpen(each.key, each.errorType)}
                      disabled={editIndex !== -1 && editIndex !== index}
                      onClick={
                        editIndex === 0
                          ? onSave
                          : handleClickOpen(each.key, index, each.errorType)
                      }
                    >
                      {editIndex === 0 ? "सच्चाउनुहोस" : "edit"}
                    </Button>
                    {editIndex === index && (
                      <Button color="primary" onClick={handleClose}>
                        रद्द गर्नुहोस्
                      </Button>
                    )}
                  </span>
                );
              }}
            />
          </Table>
        )}
      </Grid>
      <ConfirmDialog
        open={open}
        onChange={onSave}
        onClose={handleClose}
        title="घरमुली संगको नाता"
        fullWidth={false}
      >
        <Typography variant="h5">Are you sure you want to edit</Typography>
      </ConfirmDialog>
    </div>
  );
}
