import { Grid, IconButton } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  Button,
  Divider,
  Form,
  Icon,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Select,
  Typography,
  Upload,
  Tooltip
} from "antd";
import Axios from "axios";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addInstitutionSurvey } from "../../../actions/instituteAction";
import {
  add,
  remove,
  address,
  forestInfoQn,
  forestInfoList,
  name,
  wardQuestion,
  photo,
  area,
  presidentName,
  presidentContact
} from "../../../variable/institute";
import isEmpty from "../../../utils/validation/is-empty";
import { no, selectedLanguage, ward, yes } from "../../../variable/global";
import getNepaliNumber from "../../../utils/getNepaliNumber";
import { getForest } from "./api";
const { Option } = Select;
const { TextArea } = Input;

class Forest extends Component {
  random = (low, high) => {
    return Math.random() * (high - low) + low;
  };
  state = {
    // image upload state
    uniqueIdentifier:
      this.props.match.params.category +
      "_" +
      this.random(10000, 99999) +
      this.random(1000, 9999),
    previewVisible: false,
    previewImage: "",
    loaded: 0,
    name: "",
    ward: "",
    address: "",
    fileList: {
      instituteImage: []
    },
    area: "",
    forestInfo: [],
    presidentName: "",
    presidentContact: ""
  };

  // image functions uplocad
  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true
    });
  };

  // imageUpload
  handleChangeFile = (name, value, imageName) => {
    //name ma house and value ma list of filelist
    console.log("CF", value);
    this.setState(
      prevState => ({
        ...prevState,
        fileList: {
          ...prevState.fileList,
          [name]: {
            image: value.fileList,
            fileName: prevState.uniqueIdentifier + "_" + imageName + ".jpg"
          }
        }
      }),
      () => {
        if (value.fileList.length > 0) this.handleUpload(name, imageName);
        if (value.fileList.length === 0) this.removeUpload(name, imageName);
      }
    );
  };

  // upload image
  handleUpload = (name, imageName) => {
    const fileList = this.state.fileList[name];
    const data = new FormData();
    const imageObject = fileList.image[0].originFileObj;

    let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";
    data.append("file", imageObject, fileName);

    Axios.post("/api/imageUpload/upload", data, {
      onUploadProgress: ProgressEvent => {
        this.setState({
          loaded: Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)
        });
      }
    })
      .then(res => {
        // then print response status
        message.success("upload successfully.");
        console.log(res.statusText);
      })
      .catch(err => message.error("upload failed."));
  };

  // remove uploaded image
  removeUpload = imageName => {
    let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";

    Axios.get(`/api/imageUpload/delete/${fileName}`)
      .then(res => {
        // then print response status
        message.success("deleted successfully.");
        console.log(res.statusText);
      })
      .catch(err => message.error("upload failed."));
  };

  handleSelectChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  componentDidMount() {
    let forestId = this.props.match.params.id;
    if (forestId) {
      getForest(forestId).then(res =>
        this.setState({
          name: res.data.name,
          address: res.data.address,
          ward: res.data.ward,
          area: res.data.area,
          forestInfo: res.data.forestInfo,
          presidentName: res.data.president.name,
          presidentContact: res.data.president.contact,
          // image
          fileList: {
            tourism: {
              fileName: res.data.uniqueIdentifier + "_institute.jpg",
              image: [
                {
                  uid: "-1",
                  name: `${res.data.uniqueIdentifier}_institute.jpg`,
                  status: "done",
                  url: `/api/imageUpload/image/${res.data.uniqueIdentifier}_institute.jpg`
                }
              ]
            }
          }
        })
      );
    }
  }

  onSubmit = event => {
    event.preventDefault();
    const newInstitute = {
      name: this.state.name,
      uniqueIdentifier: this.state.uniqueIdentifier,
      ward: this.state.ward,
      address: this.state.address,
      area: this.state.area,
      forestInfo: this.state.forestInfo,
      presidentName: this.state.presidentName,
      presidentContact: this.state.presidentContact
    };

    if (this.props.isUpdate) {
      newInstitute.id = this.props.match.params.id;
      this.props.addInstitutionSurvey(
        "forest",
        newInstitute,
        this.props.history,
        this.props.match.params.category
      );
    } else
      this.props.addInstitutionSurvey(
        "forest",
        newInstitute,
        this.props.history,
        this.props.match.params.category
      );
    // this.props.history.push(
    //   `/survey-by-institution/show/${this.props.match.params.category}`
    // );
  };

  render() {
    const { classes, title } = this.props;
    const { previewVisible, previewImage } = this.state;
    const fileList = this.state.fileList;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <Paper className={classes.root} elevation={1}>
        <Typography variant="h5">{title}</Typography>
        <Form {...formItemLayout} onSubmit={this.onSubmit}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid xs={6}>
              <Form.Item label={name[selectedLanguage]}>
                <Input
                  onChange={this.handleInputChange("name")}
                  value={this.state.name}
                  disabled={this.state.isView}
                />
              </Form.Item>
            </Grid>
            <Grid xs={6}>
              <Form.Item label={wardQuestion[selectedLanguage]}>
                <Select
                  placeholder="------------"
                  onChange={val => this.handleSelectChange("ward", val)}
                  value={this.state.ward}
                  disabled={this.state.isView}
                >
                  {ward.map(woda => (
                    <Option value={woda.value}>{woda.ward}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Grid>
            <Grid xs={6}>
              <Form.Item label={address[selectedLanguage]}>
                <Input
                  onChange={this.handleInputChange("address")}
                  value={this.state.address}
                  disabled={this.state.isView}
                />
              </Form.Item>
            </Grid>

            <Grid xs={6}>
              <Form.Item label={area[selectedLanguage]}>
                <Input
                  type="number"
                  min={0}
                  addonAfter="square meter"
                  onChange={this.handleInputChange("area")}
                  value={this.state.area}
                />
              </Form.Item>
            </Grid>

            <Grid xs={6}>
              <Form.Item label={forestInfoQn[selectedLanguage]}>
                <Select
                  mode="multiple"
                  name="forestInfo"
                  value={this.state.forestInfo}
                  placeholder="Please select"
                  onChange={val => this.handleSelectChange("forestInfo", val)}
                >
                  {forestInfoList.map(facility => (
                    <Option value={facility.value}>
                      {facility[selectedLanguage]}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Grid>

            <Grid xs={6}>
              <Form.Item label={presidentName[selectedLanguage]}>
                <Input
                  onChange={this.handleInputChange("presidentName")}
                  value={this.state.presidentName}
                />
              </Form.Item>
            </Grid>

            <Grid xs={6}>
              <Form.Item label={presidentContact[selectedLanguage]}>
                <Input
                  type="number"
                  min={0}
                  onChange={this.handleInputChange("presidentContact")}
                  value={this.state.presidentContact}
                />
              </Form.Item>
            </Grid>

            <Grid xs={6}>
              <Form.Item label={photo[selectedLanguage]}>
                <Fragment>
                  {!this.state.isView ? (
                    <Upload
                      name="file"
                      // beforeUpload={this.props.beforeUpload}
                      multiple={false}
                      // action="/api/imageUpload/upload"
                      accept="image/*"
                      listType="picture-card"
                      fileList={
                        fileList.collegeImage && fileList.collegeImage.image
                      }
                      onPreview={this.handlePreview}
                      onChange={fileList =>
                        this.handleChangeFile("forestImage", fileList, "school")
                      }
                      disabled={this.state.isView}
                    >
                      {fileList &&
                      fileList.collegeImage &&
                      fileList.collegeImage.image &&
                      fileList.collegeImage.image.length === 1
                        ? null
                        : uploadButton}
                    </Upload>
                  ) : (
                    <Modal
                      visible={previewVisible}
                      footer={null}
                      onCancel={this.handleCancel}
                    >
                      <img
                        alt="example"
                        style={{ width: "100%" }}
                        src={previewImage}
                      />
                    </Modal>
                  )}
                </Fragment>
              </Form.Item>
            </Grid>

            <Grid xs={12}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={this.state.isView}
              >
                {this.props.isUpdate ? "update" : add[selectedLanguage]}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Paper>
    );
  }
}

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  }
});

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

const mapStateToProps = state => ({});

export default withStyles(styles)(
  connect(mapStateToProps, { addInstitutionSurvey })(withRouter(Forest))
);
