import axios from "axios";
import { GET_FILENAME_LIST, SET_ANALYSIS_LOADING } from "./types";

export const getProfileReportList = () => dispatch => {
  dispatch({
    type: SET_ANALYSIS_LOADING,
    payload: true
  });
  axios
    .get(`/api/villageReport/list`)
    .then(res => {
      dispatch({
        type: GET_FILENAME_LIST,
        payload: res.data
      });
    })
    .catch(err => {
      console.error(err);
    });
};
