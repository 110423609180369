import React from "react";
import HouseLocation from "./HouseLocation";
import IncorrectMemberCount from "./IncorrectMemberCount";

export default function index() {
  return (
    <div>
      <HouseLocation />
      <IncorrectMemberCount />
    </div>
  );
}
