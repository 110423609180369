import * as Actions from "../actions/houseLocation.actions";

const initialState = {
  locations: [],
  geoJson: {},
  polygonCheck: false
};

const houseLocationReducer = function(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_HOUSELOCATION_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.GET_HOUSELOCATION_LIST:
      return {
        ...state,
        locations: action.payload,
        loading: false
      };
    case Actions.GET_GEOJSON_LIST:
      return {
        ...state,
        geoJson: {
          ...state.geoJson,
          [`ward${action.ward}`]: action.payload
        },
        loading: false
      };
    case Actions.EDIT_ERROR_LOCATIONPLOT:
      return {
        ...state,
        polygonCheck: action.payload
      };
    default:
      return state;
  }
};

export default houseLocationReducer;
