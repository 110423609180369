import axios from "axios";
import {
  SAVE_HOUSE,
  SHOW_HOUSE,
  SAVE_FAMILY,
  SAVE_DEATH,
  SAVE_MIGRATION
} from "./types";

export const addHouseSurvey = data => dispatch => {
  axios.post(`/api/housesurvey/survey`, data).then(res => {
    console.log(res.data, "house");
    dispatch({
      type: SAVE_HOUSE,
      payload: res.data
    });
  });
};

export const addFamilyMemberSurvey = data => dispatch => {
  axios.post(`/api/housesurvey/newMember`, data).then(res => {
    console.log(res.data, "house");
    dispatch({
      type: SAVE_FAMILY,
      payload: res.data
    });
  });
};

export const editHouseMigration = data => dispatch => {
  axios.post(`/api/house/migrate`, data).then(res => {
    console.log(res.data, "house");
    dispatch({
      type: SAVE_MIGRATION,
      payload: res.data
    });
  });
};

export const addDeathMember = data => dispatch => {
  axios.post(`/api/house/memberDeath`, data).then(res => {
    console.log(res.data, "house");
    dispatch({
      type: SAVE_DEATH,
      payload: res.data
    });
  });
};

export const editHouseSurvey = id => dispatch => {
  console.log("editHouseSurvey", id);
  axios
    .get(`/api/house/listhouse/${id}`)
    .then(res => {
      console.log(res);
      dispatch({
        type: SHOW_HOUSE,
        payload: res.data
      });
    })
    .catch(err => console.log(err, "ac"));
};
