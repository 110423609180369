import React, { Component } from "react";
import Fab from "@material-ui/core/Fab";
import { Button } from "antd";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField
} from "@material-ui/core";
import Axios from "axios";
import { connect } from "react-redux";
import { getProfileReportList } from "../../../actions/profileReportAction";

class FilePreview extends Component {
  state = {
    filenames: [],
    addDialog: false,
    filename: "",
    nameError: ""
  };

  onAddClick = () => {
    this.setState(({ addDialog }) => ({ addDialog: !addDialog }));
  };

  onDownload = () => {
    Axios.get(`/api/villageReport/report`)
      .then(res => {
        res.data.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "demo.docx";
          a.click();
        });
      })
      .catch(error => console.log(error));
  };

  handleClose = () => {
    this.setState({ addDialog: false, designation: "" });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  resetError = () => this.setState({ nameError: "" });

  onSubmit = () => {
    if (this.state.filename) {
      Axios.get(`/api/villageReport/save/${this.state.filename}`)
        .then(res => {
          res.data.success && this.handleClose();
          this.props.getProfileReportList();
        })
        .catch(err => {});
    } else {
      this.setState({ nameError: "Filename cannot be empty" });
    }
  };

  render() {
    const { preview } = this.props;
    return (
      <Grid item xs={12} md={12}>
        <Dialog
          disableBackdropClick
          minWidth="sm"
          open={this.state.addDialog}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"title"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <TextField
                onFocus={this.resetError}
                id="outlined-filename"
                label="File Name"
                error={this.state.nameError}
                helperText={this.state.nameError}
                // className={classes.textField}
                value={this.state.filename}
                onChange={this.handleChange("filename")}
                margin="normal"
                variant="outlined"
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={this.onSubmit}
              color="primary"
              autoFocus
              disabled={!this.state.filename}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <a href="/api/villageReport/download">
          <Button
            onClick={this.onDownload}
            type="primary"
            icon="download"
            size={"default"}
            style={{ marginRight: 10 }}
          >
            Download
          </Button>
        </a>
        <Button
          onClick={this.onAddClick}
          type="primary"
          size={"default"}
          style={{ marginRight: 10 }}
        >
          Save
        </Button>
      </Grid>
    );
  }
}

export default connect(null, { getProfileReportList })(FilePreview);
