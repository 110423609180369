import Axios from "axios";
import { startSnackBar } from "../../../../../../actions/utilAction";

export const GET_HOUSENUMBER_LIST = "GET_HOUSENUMBER_LIST";
export const SET_HOUSENUMBER_LOADING = "SET_HOUSENUMBER_LOADING";
export const EDIT_ERROR_HOUSENUMBER = "EDIT_ERROR_HOUSENUMBER";

export const getEmptyHouseNumber = () => dispatch => {
  dispatch({
    type: SET_HOUSENUMBER_LOADING,
    payload: true
  });

  Axios.get("/api/errorCorrection/emptyRequiredField/houseNumber").then(
    response => {
      return dispatch({
        type: GET_HOUSENUMBER_LIST,
        payload: response.data
      });
    }
  );
};

export function editHouseNumber(data) {
  return dispatch =>
    Axios.post(
      "/api/errorCorrection/emptyRequiredField/houseNumberUpdate",
      data
    )
      .then(response => {
        dispatch(getEmptyHouseNumber());

        dispatch(
          startSnackBar({
            open: true,
            variant: "success",
            // message: response.data.message
            message: "घर नं सफलतापूर्वक अद्यावधिक गरियो"
          })
        );
        return dispatch({
          type: EDIT_ERROR_HOUSENUMBER,
          payload: response.data
        });
      })
      .catch(err => console.log(err));
}
