import React from "react";
import HouseNumberDuplicate from "./HouseNumberDuplicate";

export default function index() {
  return (
    <div>
      <HouseNumberDuplicate />
    </div>
  );
}
