import React, { useEffect, useState } from "react";
import { Table, InputNumber, Input, Form, Radio } from "antd";
import { Typography, Button, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmptyDateOfBirth,
  editDateOfBirth
} from "./store/actions/dateOfBirth.actions";
import {
  sabikGabisa,
  sabikGabisaQuestion
} from "../../../../variable/houseSurvey";
import { selectedLanguage } from "../../../../variable/global";
import ConfirmDialog from "../common/confirmDialog";

const { Column } = Table;

export default function DateOfBirth() {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(
    ({ dataCorrection }) => dataCorrection.emptyField.dateOfBirth
  );
  const [open, setOpen] = React.useState(false);
  const [memberId, setMemberId] = useState("");
  const [age, setAge] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [editIndex, setEditIndex] = useState(-1);

  const handleClickOpen = (id, index) => () => {
    if (dateOfBirth === "") setDateOfBirth(list.mistakeData[index].dateOfBirth);
    if (age === "" || age === null) setAge(list.mistakeData[index].age);
    setMemberId(id);
    setEditIndex(index);

    setOpen(true);
    console.log("dob", dateOfBirth, "age", age);
  };

  const handleClose = value => {
    // setDateOfBirth("");
    // setAge("");
    setEditIndex(-1);

    setOpen(false);
  };

  useEffect(() => {
    dispatch(getEmptyDateOfBirth());
    // setData(list);
  }, [dispatch]);

  // const onChange = name => value => {
  //   setTotal({
  //     [name]: value
  //   });
  // };

  const onSave = () => {
    let data = {
      dateOfBirth: dateOfBirth,
      age: age,
      // totalFamilyMember: incorrectTotal,
      memberId: memberId
    };
    dispatch(editDateOfBirth(data));
    handleClose();
  };

  let mapColumn = [
    {
      title: "सर्वेक्षण काेड",
      dataIndex: "dataCollectionCode",
      key: "dataCollectionCode",
      width: 200
    },
    {
      title: "घर नं",
      dataIndex: "houseNumber",
      key: "houseNumber",
      width: 150
    },
    {
      title: "वडा नं",
      dataIndex: "wardNumber",
      key: "wardNumber",
      width: 150
    },
    {
      title: "fullName",
      dataIndex: "fullName",
      key: "fullName",
      width: 200
    },
    {
      title: "Date Of Birth",
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
      width: 200,
      render: (item, record, index) =>
        record.status === "dateOfBirth" ||
        record.status === "notMatch" ||
        record.status === "empty" ? (
          <Input
            type="text"
            defaultValue={item}
            onChange={({ target: { value } }) => setDateOfBirth(value)}
          />
        ) : (
          <p>{item}</p>
        )
    },
    {
      title: "age",
      dataIndex: "age",
      key: "age",
      width: 200,
      render: (item, record, index) =>
        record.status === "age" ||
        record.status === "notMatch" ||
        record.status === "empty" ? (
          <Input
            type="text"
            defaultValue={item}
            onChange={({ target: { value } }) => setAge(value)}
          />
        ) : (
          <p>{item}</p>
        )
    }
  ];
  console.log("dateOf", dateOfBirth);

  return (
    <div>
      <Typography variant="h4" component="h2" align="center">
        जन्म मिती /उमेर
      </Typography>
      <Table
        style={{ width: "100%", margin: "0px 5px" }}
        dataSource={list.mistakeData}
        rowKey="uid"
        pagination={{
          defaultPageSize: 5,
          showSizeChanger: true
          //   pageSizeOptions: ["10", "20", "30"]
        }}
      >
        <Column
          title={<span className="font-semibold">क्र.श</span>}
          dataIndex={"sn"}
          key={"sn"}
          render={(text, record, index) => <p>{index + 1}</p>}
        />

        {mapColumn.map(column => (
          <Column {...column} />
        ))}
        <Column
          title={<span className="font-semibold">क्रियाकलाप</span>}
          key="action"
          render={(each, record, index) => {
            return (
              <span>
                <Button
                  color="primary"
                  // disabled={editIndex !== -1 && editIndex !== index}
                  onClick={handleClickOpen(each.memberId, index)}
                >
                  सच्चाउनुहोस
                </Button>
                {/* {editIndex === index && (
                  <Button color="primary" onClick={handleClose}>
                    cancel
                  </Button>
                )} */}
              </span>
            );
          }}
        />
      </Table>
      <ConfirmDialog
        open={open}
        onChange={onSave}
        onClose={handleClose}
        title="जन्म मिती"
        fullWidth={false}
      >
        <Typography variant="h5">Are you sure you want to</Typography>
        {/* <Input
          type="number"
          value={incorrectMale}
          onChange={({ target: { value } }) => setIncorrectMale(value)}
        />
        <Input
          type="number"
          value={incorrectFemale}
          onChange={({ target: { value } }) => setIncorrectFemale(value)}
        />
        <Input
          type="number"
          value={incorrectTotal}
          onChange={({ target: { value } }) => setIncorrectTotal(value)}
        /> */}
      </ConfirmDialog>
    </div>
  );
}
