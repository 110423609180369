import {
  Grid,
  IconButton,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import GPS from "@material-ui/icons/GpsFixed";
import GPSNOT from "@material-ui/icons/GpsNotFixed";
import MultipleSelect from "../../../common/frequent/MultipleSelect";
import Selection from "../../../common/frequent/Selection";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { geoCode, selectedLanguage } from "../../../../variable/global";
import {
  answererName,
  answererRelation,
  answererRelationQuestion,
  detectCurrentLocation,
  gharNumber,
  housePhoto,
  latitude,
  longitude,
  sabikGabisa,
  sabikGabisaQuestion,
  sabikWard,
  tole,
  toleNumber,
  wardNumber,
  wardQuestion,
  migrationList,
  migrationQuestion
} from "../../../../variable/houseSurvey";
import BoxBorder from "../../../common/frequent/BoxBorder";
// import Upload from "../../../common/frequent/imageUpload/Upload";
import ImagesUploader from "react-images-uploader";
// import Uploads from "../../../common/frequent/imageUpload/Uploads";
import LeafletMap from "../../../common/frequent/map/LeafletMap";
import RadioButton from "../../../common/frequent/RadioButton";
import TextField from "../../../common/frequent/TextField";
// import Expand from "@material-ui/icons/KeyboardArrowLeft";
// import Collapse from "@material-ui/icons/KeyboardArrowRight";
import { Upload, Icon, Modal, Button, message } from "antd";
import Axios from "axios";
import MTextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { editHouseMigration } from "../../../../actions/houseSurveyAction";
var geolocation = require("geolocation");

class PrimaryHouse extends Component {
  state = {
    expand: false,
    previewVisible: false,
    previewImage: "",
    // fileList: [],
    loaded: 0,
    openMigrateDialog: false,
    migrate: {}
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handleMigrateChange = (name, value) => {
    this.setState(state => ({
      ...state,
      migrate: { ...state.migrate, [name]: value }
    }));
  };

  handleMigrationSubmit = event => {
    event.preventDefault();

    this.props.editHouseMigration(this.state.migrate);
    this.handleMigrateClose();
  };

  handleMigrateOpen = id => () => {
    this.setState({
      openMigrateDialog: true,
      migrate: { houseId: id }
    });
  };

  handleMigrateClose = () => {
    this.setState({ openMigrateDialog: false });
  };

  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true
    });
  };

  handleChange = (name, value) => {
    this.props.handleChange(`primaryHouse.${name}`, value);
  };

  toogleMap = () => {
    this.setState(({ expand }) => ({ expand: !expand }));
  };

  getLocation = async () => {
    const resetMap = this.props.resetMap;
    geolocation.getCurrentPosition(function(err, position) {
      if (err) return resetMap(geoCode);
      const { coords } = position;
      console.log("position", position);
      resetMap({ set: true, lat: coords.latitude, lng: coords.longitude });
    });
  };

  render() {
    const {
      primaryHouse,
      zoom,
      position,
      lat,
      lng,
      gpsFetched,
      isEdit
    } = this.props;
    const { expand, migrate } = this.state;
    const errors = this.props.errors && this.props.errors.errors;

    const { previewVisible, previewImage } = this.state;
    const fileList = this.props.fileList;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    // console.log("fileList", this.state.fileList);

    return (
      <Grid container direction="column">
        {this.props.isEdit && (
          <Grid container style={{ display: "flex" }}>
            <Button
              type="primary"
              style={{ marginLeft: "auto" }}
              onClick={this.handleMigrateOpen(
                this.props.houseId
                //   primaryHouse.sabikGabisa
              )}
            >
              Migrate House
            </Button>
          </Grid>
        )}
        <Grid container>
          <RadioButton
            error={errors && errors.wardNumber}
            handleChange={this.handleChange}
            isVisible
            question={wardQuestion}
            name="wardNumber"
            radioLists={wardNumber}
            value={primaryHouse.wardNumber}
          />
        </Grid>

        <Grid xs={6} style={{ marginBottom: 10 }}>
          <Grid item container>
            {gharNumber && (
              <Typography
                variant="body"
                style={{ fontWeight: "bold" }}
                // className={classes.question}
              >
                {gharNumber[selectedLanguage]}
              </Typography>
            )}
          </Grid>
          <MTextField
            disabled={isEdit}
            id="gharNumber"
            name="gharNumber"
            type="number"
            error={
              (errors && errors.gharNumber) || !this.props.isHouseNumberValid
            }
            helperText={errors || this.props.houseHelperText[selectedLanguage]}
            label="घर नं"
            style={{
              width: "100%",
              marginLeft: `${8 * 1}px`,
              marginTop: `${8 * 3}px`,
              marginBottom: `${8 * 3}px`
            }}
            // className={classes.component}
            value={primaryHouse.gharNumber}
            onChange={event => {
              this.handleChange(event.target.name, event.target.value);
              let houseHelperText = {};
              let isHouseNumberValid = false;
              if (event.target.value) {
                Axios.get(`/api/house/isHouseNumberExist/${event.target.value}`)
                  .then(result => {
                    if (result.data.success) {
                      isHouseNumberValid = false;
                      houseHelperText = {
                        english: "House number is already taken.",
                        nepali: "घर नम्बर पहिले नै लिइएको छ।"
                      };
                    } else {
                      isHouseNumberValid = true;
                      houseHelperText = {
                        english: "House number is available.",
                        nepali: "घर नम्बर उपलब्ध छ।"
                      };
                    }
                    this.props.setHouseNumberValid(
                      houseHelperText,
                      isHouseNumberValid
                    );
                  })
                  .catch(err => {});
              } else {
                houseHelperText = {
                  english: "please select unique house number in english",
                  nepali: "कृपया अंग्रेजीमा अद्वितीय घर नम्बर चयन गर्नुहोस्"
                };
                this.props.setHouseNumberValid(
                  houseHelperText,
                  isHouseNumberValid
                );
              }
            }}
            margin="normal"
            variant="outlined"
          />
        </Grid>
        {/* <Grid xs={6}>
					<TextField
						error={errors && errors.gharNumber}
						question={gharNumber}
						name="gharNumber"
						disabled={isEdit}
						require
						type="number"
						placeholder="घर नं "
						isVisible
						handleChange={this.handleChange}
					/>
				</Grid> */}

        <Grid container>
          <RadioButton
            error={errors && errors.sabikGabisa}
            handleChange={this.handleChange}
            isVisible
            question={sabikGabisaQuestion}
            name="sabikGabisa"
            radioLists={sabikGabisa}
            value={primaryHouse.sabikGabisa}
          />
        </Grid>

        <Grid xs={6}>
          <TextField
            error={errors && errors.sabikWard}
            type="number"
            question={sabikWard}
            name="sabikWard"
            require
            value={primaryHouse.sabikWard}
            isVisible
            handleChange={this.handleChange}
          />
        </Grid>

        <Grid xs={6}>
          <TextField
            error={errors && errors.tole}
            question={tole}
            name="tole"
            value={primaryHouse.tole}
            isVisible
            handleChange={this.handleChange}
          />
        </Grid>

        <Grid xs={6}>
          <TextField
            error={errors && errors.toleNumber}
            question={toleNumber}
            name="toleNumber"
            value={primaryHouse.toleNumber}
            isVisible
            handleChange={this.handleChange}
          />
        </Grid>

        <Grid xs={6}>
          <TextField
            error={errors && errors.answererName}
            question={answererName}
            name="answererName"
            require
            value={primaryHouse.answererName}
            isVisible
            handleChange={this.handleChange}
          />
        </Grid>

        <Grid container>
          <Selection
            error={errors && errors.answererRelation}
            question={answererRelationQuestion}
            name="answererRelation"
            selectList={answererRelation}
            value={primaryHouse.answererRelation}
            isVisible
            handleChange={this.handleChange}
          />
        </Grid>

        <Grid item>
          <div className="clearfix">
            <Typography variant="h6">घरको फोटो</Typography>

            {this.props.isHouseNumberValid ? (
              <Fragment>
                <Upload
                  name="file"
                  // beforeUpload={this.props.beforeUpload}
                  multiple={false}
                  // action="/api/imageUpload/upload"
                  accept="image/*"
                  listType="picture-card"
                  fileList={fileList.house && fileList.house.image}
                  onPreview={this.handlePreview}
                  onChange={fileList =>
                    this.props.handleChangeFile("house", fileList, "house")
                  }
                >
                  {fileList &&
                  fileList.house &&
                  fileList.house.image &&
                  fileList.house.image.length === 1
                    ? null
                    : uploadButton}
                </Upload>
                <Modal
                  visible={previewVisible}
                  footer={null}
                  onCancel={this.handleCancel}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>
              </Fragment>
            ) : (
              <h4 stlye={{ marginLeft: 5 }}>
                {
                  {
                    english: "please select unique house number in english",
                    nepali: "कृपया अंग्रेजीमा अद्वितीय घर नम्बर चयन गर्नुहोस्"
                  }[selectedLanguage]
                }
              </h4>
            )}
          </div>
        </Grid>

        {/* <Grid xs={6}>
					<Typography variant="body" />
					<ImagesUploader
						url="/api/imageUpload/house_image"
						optimisticPreviews
						multiple={false}
						onLoadEnd={err => {
							if (err) {
								console.error(err);
							}
						}}
						label={housePhoto[selectedLanguage]}
					/>
				</Grid> */}

        {/* maps */}
        <BoxBorder isVisible>
          <Grid container direction="row">
            {!expand && (
              <Grid item xs={4} container direction="column">
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                  spacing={16}
                >
                  <Grid item>
                    <Typography variant="caption">
                      {detectCurrentLocation[selectedLanguage]}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      className={style.gps}
                      onClick={() => {
                        this.getLocation();
                      }}
                      aria-label="gps"
                    >
                      {gpsFetched ? <GPS /> : <GPSNOT />}
                    </IconButton>
                  </Grid>
                </Grid>

                <Grid item style={{ marginRight: 10 }}>
                  <TextField
                    question={latitude}
                    name="lat"
                    require
                    type="number"
                    value={lat}
                    isVisible
                    handleChange={this.props.handleMap}
                  />
                </Grid>
                <Grid item style={{ marginRight: 10 }}>
                  <TextField
                    question={longitude}
                    name="lng"
                    require
                    type="number"
                    value={lng}
                    isVisible
                    handleChange={this.props.handleMap}
                  />
                </Grid>

                <Grid item>
                  <IconButton
                    onClick={() => {
                      this.props.resetMap(geoCode);
                    }}
                    aria-label="Delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            )}
            {/* Left ends */}

            <Grid item xs={12} sm>
              <LeafletMap
                zoom={zoom}
                position={position}
                lat={lat}
                lng={lng}
                addMarker={this.props.addMarker}
              >
                <p>
                  स्थान प्राप्त गर्न<em>मार्कर</em> पिन गर्नुहोस
                </p>
              </LeafletMap>
            </Grid>
          </Grid>
        </BoxBorder>
        {/* dialog start of dead member*/}
        <Dialog
          open={this.state.openMigrateDialog}
          onClose={this.handleMigrateClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Add Migration Info</DialogTitle>
          <DialogContent>
            <Grid container direction="column">
              <Grid container>
                <Selection
                  question={migrationQuestion}
                  name={`migration`}
                  value={migrate[`migration`]}
                  selectList={migrationList}
                  isVisible
                  handleChange={this.handleMigrateChange}
                  //   other={migrate["migrationOther"]}
                />
              </Grid>

              <Grid container>
                <RadioButton
                  handleChange={this.handleMigrateChange}
                  isVisible={migrate["migration"] === "same_palika"}
                  question={wardQuestion}
                  name="wardNumber"
                  radioLists={wardNumber}
                  value={migrate[`wardNumber`]}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleMigrateClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleMigrationSubmit} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        {/* dialog ends */}
      </Grid>
    );
  }
}

const style = {
  gps: {
    marginLeft: 20
  }
};
PrimaryHouse.propTypes = {
  classes: PropTypes.object.isRequired,
  editHouseMigration: PropTypes.func.isRequired
};

const mapStateToProps = state => ({});

PrimaryHouse.defaultProps = {
  coords: {}
};

export default connect(mapStateToProps, {
  editHouseMigration
})(PrimaryHouse);
