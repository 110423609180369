import Axios from "axios";
import { startSnackBar } from "../../../../../../actions/utilAction";

export const GET_MEMBERCOUNT_LIST = "GET_MEMBERCOUNT_LIST";
export const GET_GEOJSON_LIST = "GET_GEOJSON_LIST";
export const SET_MEMBERCOUNT_LOADING = "SET_MEMBERCOUNT_LOADING";
export const EDIT_ERROR_MEMBERCOUNT = "EDIT_ERROR_MEMBERCOUNT";

export const getErrorMemberCount = () => dispatch => {
  dispatch({
    type: SET_MEMBERCOUNT_LOADING,
    payload: true
  });

  Axios.get("/api/errorCorrection/dataEntryError/checkHouseMember").then(
    response => {
      return dispatch({
        type: GET_MEMBERCOUNT_LIST,
        payload: response.data
      });
    }
  );
};

export function editErrorMemberCount(data) {
  return dispatch =>
    Axios.post(`/api/errorCorrection/dataEntryError/houseMemberUpdate`, data)
      .then(response => {
        dispatch(getErrorMemberCount());

        dispatch(
          startSnackBar({
            open: true,
            variant: "success",
            // message: response.data.message
            message: "घरको जम्मा सदस्य संख्या सफलतापूर्वक अद्यावधिक गरियो"
          })
        );
        return dispatch({
          type: EDIT_ERROR_MEMBERCOUNT,
          payload: response.data
        });
      })
      .catch(err => console.log(err));
}
