import { combineReducers } from "redux";
import emptyFieldReducer from "../../emptyRequiredField/store/reducers/";
import dataEntryError from "../../dataEntryError/store/reducers/";
import logicalInvalid from "../../logicInvalid/store/reducers/";
import duplicacy from "../../duplicacy/store/reducers/";
const dataCorrectionReducer = combineReducers({
  emptyField: emptyFieldReducer,
  dataEntry: dataEntryError,
  logicalInvalid: logicalInvalid,
  duplicacy: duplicacy
});

export default dataCorrectionReducer;
