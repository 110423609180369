import React, { useEffect, useState } from "react";
import { Table, Select, Input, Form, Radio } from "antd";
import { Typography, Button, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmptyBidhuwa,
  editBidhurBidhuwa
} from "./store/actions/bidhurBidhuwa.actions";
import { sabikGabisa, marriage } from "../../../../variable/houseSurvey";
import { selectedLanguage } from "../../../../variable/global";
import ConfirmDialog from "../common/confirmDialog";

const { Column } = Table;
const { Option } = Select;

function mapValue(value, obj) {
  return obj[value] ? obj[value] : value;
}

export default function Bidhuwa() {
  const dispatch = useDispatch();
  const { bidhuwaList, loading } = useSelector(
    ({ dataCorrection }) => dataCorrection.logicalInvalid.bidhurBidhuwa
  );
  const [wardData, setWardData] = useState({});

  const [data, setData] = useState(bidhuwaList);
  const [open, setOpen] = React.useState(false);
  const [memberId, setMemberId] = useState([]);
  const [inputText, setText] = useState("");
  const [editIndex, setEditIndex] = useState(-1);
  const [bulkAction, setBulkAction] = useState(false);

  const handleClickOpen = (id, index) => () => {
    setMemberId(id);
    setEditIndex(index);
    // setOpen(true);
  };

  const handleClose = value => {
    setEditIndex(-1);
    setOpen(false);
  };

  useEffect(() => {
    const ward = parseInt(sessionStorage.getItem("ward"));
    var wardDatas = ward === 0 ? {} : { wardNumber: ward };
    setWardData(wardDatas);
    dispatch(getEmptyBidhuwa(wardData.wardNumber ? wardData.wardNumber : 0));
  }, [dispatch]);

  // const onChange = name => value => {
  //   setTotal({
  //     [name]: value
  //   });
  // };

  const handleChange = (name, value) => {
    setText({ [name]: value });
    setOpen(true);
  };

  const onSave = () => {
    let data = {
      changeTo: inputText,
      memberId: memberId
    };
    dispatch(editBidhurBidhuwa(data, "female"));
    handleClose();
  };

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    let data = [];

    selectedRows.map(each => data.push(each.key));
    setMemberId(data);
    // setMemberId([selectedRows[selectedRowKeys].key]);
    setBulkAction(true);
  };

  const rowSelection = {
    onChange: onSelectChange,
    hideDefaultSelections: true
  };

  let mapColumn = [
    // {
    //   title: "सर्वेक्षण काेड",
    //   dataIndex: "dataCollectionCode",
    //   key: "dataCollectionCode",
    //   width: 200
    // },
    {
      title: "घर नं",
      dataIndex: "houseNumber",
      key: "houseNumber",
      width: 150
    },
    {
      title: "वडा नं",
      dataIndex: "wardNumber",
      key: "wardNumber",
      width: 150
    },
    {
      title: "पूरा नाम",
      dataIndex: "fullname",
      key: "fullname",
      width: 200
    },
    {
      title: "लिंग",
      dataIndex: "gender",
      key: "gender",
      width: 200,
      render: gender => <p>{gender === "male" ? "पुरुष" : "महिला"}</p>
    },
    {
      title: "विवाह इसतिथि",
      dataIndex: "martialStatus",
      key: "martialStatus",
      width: 200,
      render: martialStatus => (
        <p>
          {mapValue(martialStatus, {
            unmarried: "अविवाहित",
            married: "विवाहित",
            multi_marriage: "बहु बिबाह",
            re_married: "पुन बिबाह",
            widow: "बिधुर",
            wido1: "बिधुवा",
            divorce: "सम्बन्ध बिच्छेद (विवाहित तर अलग बसेको)",
            under_age: "उमेर कम"
          })}
        </p>
      )
    }
    // {
    //   title: "विवाह इसतिथि",
    //   dataIndex: "martialStatus",
    //   key: "martialStatus",
    //   width: 200,
    //   render: (martialStatus, record, index) =>
    //     editIndex === index ? (
    //       <Select
    //         defaultValue={martialStatus}
    //         onChange={value => handleChange("martialStatus", value)}
    //       >
    //         <Option value="wido1">बिधुवा</Option>
    //         <Option value="widow">विधुर</Option>
    //       </Select>
    //     ) : (
    //       <p>{martialStatus}</p>
    //     )
    // }
  ];

  return (
    <div>
      <Typography variant="h4" component="h2" align="center">
        बिधुवा
      </Typography>
      {bulkAction && (
        <Grid>
          <Select
            defaultValue="---------"
            onChange={value =>
              value === "wido1"
                ? handleChange("martialStatus", value)
                : handleChange("gender", value)
            }
          >
            <Option value="wido1">बिधुवा</Option>
            <Option value="male">पुरुष</Option>
          </Select>
        </Grid>
      )}
      <Table
        style={{ width: "100%", margin: "0px 5px" }}
        dataSource={bidhuwaList}
        rowKey="uid"
        rowSelection={rowSelection}
      >
        <Column
          title={<span className="font-semibold">क्र.श</span>}
          dataIndex={"sn"}
          key={"sn"}
          render={(text, record, index) => <p>{index + 1}</p>}
        />

        {mapColumn.map(column => (
          <Column {...column} />
        ))}
        <Column
          title={<span className="font-semibold">क्रियाकलाप</span>}
          key="action"
          render={(each, record, index) => {
            return (
              <span>
                {editIndex === 0 && (
                  <Select
                    defaultValue="-------"
                    onChange={value =>
                      value === "wido1"
                        ? handleChange("martialStatus", value)
                        : handleChange("gender", value)
                    }
                  >
                    <Option value="wido1">बिधुवा</Option>
                    <Option value="male">पुरुष</Option>
                  </Select>
                )}
                <Button
                  color="primary"
                  disabled={editIndex !== -1 && editIndex !== index}
                  onClick={
                    editIndex === 0 ? onSave : handleClickOpen(each.key, index)
                  }
                >
                  {editIndex === 0 ? "" : "सच्चाउनुहोस"}
                </Button>
                {editIndex === index && (
                  <Button color="primary" onClick={handleClose}>
                    cancel
                  </Button>
                )}
              </span>
            );
          }}
        />
      </Table>
      <ConfirmDialog
        open={open}
        onChange={onSave}
        onClose={handleClose}
        title="परिवारको जम्मा सदस्य संख्या"
        fullWidth={false}
      >
        <Typography variant="h5">Are you sure you want to</Typography>
        {/* <Input
          type="number"
          value={incorrectMale}
          onChange={({ target: { value } }) => setIncorrectMale(value)}
        />
        <Input
          type="number"
          value={incorrectFemale}
          onChange={({ target: { value } }) => setIncorrectFemale(value)}
        />
        <Input
          type="number"
          value={incorrectTotal}
          onChange={({ target: { value } }) => setIncorrectTotal(value)}
        /> */}
      </ConfirmDialog>
    </div>
  );
}
