import { totalWard } from "../config.js";
import getNepaliNumber from "../utils/getNepaliNumber.js";

// ##############################
// global variable name
// #############################
export const geoCode = { lat: 27.132632571720116, lng: 87.78779983520508 };
export const gaupalikaNepali = "फिदिम";
export const gaupalikaEnglish = "Phidim";
export const ruralMunicipalNepali = "नगरपालिका";
export const ruralMunicipalEnglish = "Municipality";
export const gaupalika = { nepali: gaupalikaNepali, english: gaupalikaEnglish };

export const gaupalikaWard = {
  english: gaupalikaEnglish + " " + ruralMunicipalEnglish,
  nepali: gaupalikaNepali + " " + ruralMunicipalNepali
};
export const wadaBibaranName = {
  english: gaupalikaEnglish + " Ward Details",
  nepali: gaupalikaNepali + " वडा विवरण"
};
export const familyDetails = [
  {
    nepali: gaupalikaNepali + " पारिवारिक विवरण",
    english: gaupalikaEnglish + ""
  },
  ...[...Array(totalWard).keys()].map(each => ({
    nepali: `वडा ${getNepaliNumber(each + 1)} पारिवारिक विवरण`,
    english: `Family Details of ward ${each + 1}`
  }))
];

export const wardAnalysis = [
  {
    nepali: gaupalikaNepali,
    english: gaupalikaEnglish
  },

  ...[...Array(totalWard).keys()].map(each => ({
    nepali: `वडा ${getNepaliNumber(each + 1)} पारिवारिक विवरण`,
    english: `Family Details of ward ${each + 1}`
  }))
];
export const houseDetails = [
  { nepali: gaupalikaNepali + " घर धुरी विवरण", english: "" },
  ...[...Array(totalWard).keys()].map(each => ({
    nepali: `वडा ${getNepaliNumber(each + 1)} घर धुरी विवरण`,
    english: `House Details of ward ${each + 1}`
  }))
];

export const janasankhyaBibaranName = [
  { nepali: gaupalikaNepali + " जनसंख्या विवरण", english: "" },
  ...[...Array(totalWard).keys()].map(each => ({
    nepali: `वडा ${getNepaliNumber(each + 1)} जनसंख्या विवरण`,
    english: `Population Details of ward ${each + 1}`
  }))
];

export const statisticsAnalysisName = [
  { nepali: gaupalikaNepali + " तथ्यांक विवरण", english: "" },
  ...[...Array(totalWard).keys()].map(each => ({
    nepali: `वडा ${getNepaliNumber(each + 1)} पारितथ्यांकवारिक विवरण`,
    english: `Family Details of ward ${each + 1}`
  }))
];
export const sansthagatBibaranName = [
  { nepali: gaupalikaNepali + " संस्थागत विवरण", english: "" },
  ...[...Array(totalWard).keys()].map(each => ({
    nepali: `वडा ${getNepaliNumber(each + 1)} संस्थागत विवरण`,
    english: `Family Details of ward ${each + 1}`
  }))
];

export const janasankhyaBibaranListName = [
  { name: "जम्मा जनसंख्या", value: "totalMember", icon: "users" },
  { name: "महिला जनसंख्या", value: "femaleMember", icon: "female" },
  { name: "पुरुष जनसंख्या", value: "maleMember", icon: "male" },
  { name: "तेस्रो लिङ्गी", value: "thirdGenderNumber", icon: "user" },
  { name: "जम्मा घर संख्या", value: "totalHouse", icon: "home" }
];

export const ward = [
  {
    backgroundColor: "#f44336",
    key: 1,
    value: 1,
    ward: "वडा न. १",
    english: "Phidim(1)",
    nepali: "फिदिम(१)"
  },
  {
    key: 2,
    value: 2,
    backgroundColor: "#ff5722",
    ward: "वडा न. २ ",
    english: "Phidim(2)",
    nepali: "फिदिम(२)"
  },
  {
    key: 3,
    value: 3,
    backgroundColor: "#673ab7",
    ward: "वडा न. ३",
    english: "Phidim(3)",
    nepali: "फिदिम(३)"
  },
  {
    key: 4,
    value: 4,
    backgroundColor: "#e91e63",
    ward: "वडा न. ४",
    english: "Phidim(4,5)",
    nepali: "फिदिम(४,५)"
  },
  {
    key: 5,
    value: 5,
    backgroundColor: "#9c27b0",
    ward: "वडा न. ५",
    english: "Phidim(6-7)",
    nepali: "फिदिम(६-८)"
  },
  {
    key: 6,
    value: 6,
    backgroundColor: "#9c27b0",
    ward: "वडा न. ६",
    english: "Phidim(9-11)",
    nepali: "फिदिम(९-११)"
  },
  {
    key: 7,
    value: 7,
    backgroundColor: "#9c27b0",
    ward: "वडा न. ७",
    english: "Ranitar(1-4)",
    nepali: "रानीटार(१-४)"
  },
  {
    key: 8,
    value: 8,
    backgroundColor: "#9c27b0",
    ward: "वडा न. ८",
    english: "Ranitar(5-8)",
    nepali: "रानीटार(५-८)"
  },
  {
    key: 9,
    value: 9,
    backgroundColor: "#9c27b0",
    ward: "वडा न. ९",
    english: "lumfabhung(1-9)",
    nepali: "लुम्फावुङ(१-९)"
  },
  {
    key: 10,
    value: 10,
    backgroundColor: "#9c27b0",
    ward: "वडा न. १०",
    english: "Yangnam(1-5)",
    nepali: "याङनाम(१-५)"
  },
  {
    key: 11,
    value: 11,
    backgroundColor: "#9c27b0",
    ward: "वडा न. ११",
    english: "Nagin(1-5)",
    nepali: "नागिन(१-५)"
  },
  {
    key: 12,
    value: 12,
    backgroundColor: "#9c27b0",
    ward: "वडा न. १२",
    english: "Nagin(6-9)",
    nepali: "नागिन(६-९)"
  },
  {
    key: 13,
    value: 13,
    backgroundColor: "#9c27b0",
    ward: "वडा न. १३",
    english: "Lungrupa(1-3,7-9)",
    nepali: "लुगंरुपा(१-३,७-९)"
  },
  {
    key: 14,
    value: 14,
    backgroundColor: "#9c27b0",
    ward: "वडा न. १४",
    english: "Ranitar(9) and Lungarupa(4,5,6)",
    nepali: "रानीटार (९) र लुंगरुपा(४,५,६)"
  }
];

export const infastructure = [
  {
    value: "road",
    english: "Road",
    nepali: "बाटो"
  },
  {
    value: "river",
    english: "River",
    nepali: "नदि"
  },
  {
    value: "forest",
    english: "Forest",
    nepali: "जङ्गल"
  },
  {
    value: "hydropower",
    english: "Hydropower",
    nepali: "हाइड्रोपावर"
  },
  {
    value: "waterpipeline",
    english: "Water Pipeline",
    nepali: "खानेपानी पाइपलाइन"
  },
  {
    value: "busstation",
    english: "Bus Station",
    nepali: "बसपार्क"
  },
  {
    value: "bridge",
    english: "Bridge",
    nepali: "पुल"
  },
  {
    value: "house",
    english: "House",
    nepali: "घर"
  }
];

export const disaster = [
  {
    value: "landslide",
    english: "Landslide",
    nepali: "पहिरो"
  },
  {
    value: "flood",
    english: "Flood",
    nepali: "बाढी"
  },
  {
    value: "earthquake",
    english: "Earthquake",
    nepali: "भूकंप"
  }
];

export const sansthagatBibaranListName = [
  { name: "जम्मा बिद्यालय", value: "2" },
  { name: "जम्मा संघ संस्था", value: "3" },
  { name: "जम्मा INGO", value: "1" },
  { name: "जम्मा बैंक", value: "2" },
  { name: "जम्मा संघ संस्था", value: "4" },
  { name: "जम्मा बिद्यालय", value: "5" }
];
export const deathTableHeader = [
  "क्र.स",
  "वडा न.",
  "घर न.",
  "मृत्यु हुनेको नाम थर",
  "लिङ्ग",
  "उमेर ",
  "मृत्युको कारण "
];

export const searchByNameTableHeader = [
  { english: "S.N", nepali: "क्र.स" },
  { english: "", nepali: "वडा नं" },
  { english: "", nepali: "घर नं" },
  { english: "", nepali: " नाम थर" },
  { english: "", nepali: "लिङ्ग" },
  { english: "", nepali: "उमेर" },
  { english: "", nepali: "घरमुलीसंगको नाता" },
  { english: "", nepali: "फोन नम्बर" },
];

export const advanceHouseSearchTableHeader = [
  { english: "S.N", nepali: "क्र.स" },
  { english: "", nepali: "वडा नं" },
  { english: "", nepali: "घर नं" },
  { english: "", nepali: " जम्मा सदस्य" },
  { english: "", nepali: "बसोबास स्थिति" },
  { english: "", nepali: "बसोबास गरेको समय ( वर्ष )" }
];
export const deathReasonLists = [
  { key: 1, name: "माहामारी", value: "epidimics" },
  { key: 2, name: "सर्नेरोग ( टीबी, जण्डिस, आदि)", value: "tranferable" },
  { key: 3, name: "काल गति", value: "aged" },
  { key: 4, name: "HIVAIDS", value: "aids" },
  { key: 5, name: "क्यान्सर", value: "cancer" },
  { key: 6, name: "मधुमेह", value: "diabetes" },
  { key: 7, name: "दम", value: "asthma" },
  { key: 8, name: "रक्तचाप", value: "blood_pressure" },
  { key: 9, name: "दुर्घटना", value: "accident" },
  { key: 10, name: "आत्महत्या", value: "suicide" },
  { key: 11, name: "अन्य", value: "death_other" }
];

export const genderList = [
  { key: 1, value: "महिला" },
  { key: 2, value: "पुरुष" },
  { key: 3, value: "तेस्रो लिङ्गी" }
];

export const gender = [
  { nepali: "महिला", english: "", value: "" },
  { nepali: "पुरुष", english: "", value: "" },
  { nepali: "तेस्रो लिङ्गी", english: "", value: "" }
];

export const yes = {
  nepali: "छ",
  english: "yes"
};

export const no = {
  nepali: "छैन",
  english: "no"
};

// yes[selectedLanguage]

export const languages = ["नेपाली", "english"];
export const selectedLanguage = "nepali";

export const casteList = [
  { key: 1, value: "ब्राहमण/क्षेत्रि" },
  { key: 2, value: "जनजाती आदिवासी" },
  { key: 3, value: "पहाडी अन्य जाति" },
  { key: 4, value: "मधेसी जनजाति तथा आदिबासी" },
  { key: 5, value: "मधेसी ब्राम्हण तथा राजपुत" },
  { key: 6, value: "मधेसी दलित" },
  { key: 7, value: "परियार (दमाई,दर्जी ,सूचिका)" },
  { key: 8, value: "विश्वकर्मा (कामि,लोहार,ओड,दर्जी चुनरा,पार्की )" },
  { key: 9, value: "सार्की (भिजार,चर्माकार मूल )" },
  { key: 10, value: "अल्पशंख्यक जाति (हायु ,बोटे)" },
  { key: 11, value: "ठकुरी" },
  { key: 12, value: "माझी" },
  { key: 13, value: "मुस्लिम" },
  { key: 14, value: "अन्य" }
];

export const religionList = [
  { key: 1, value: "हिन्दु" },
  { key: 2, value: "बुद्ध" },
  { key: 3, value: "इस्लाम (मुस्लिम)" },
  { key: 2, value: "इसाई (क्रिश्चियन)" },
  { key: 2, value: "किंरात" },
  { key: 2, value: "अन्य" }
];

export const required = {
  english: "Required *",
  nepali: "अनिवार्य  *"
};
export const otherQuestion = {
  english: "",
  nepali: "अन्य भए उल्लेख गर्नुहोस |"
};
export const privilege = [
  {
    nepali: "गाउँपलिका प्रमुख",
    english: "",
    value: 0
  },
  {
    nepali: "सूचना प्रविधि अधिकृत",
    english: "information",
    value: 1
  },
  {
    nepali: "गाउँपलिका उपप्रमुख",
    english: "villageSubHead",
    value: 2
  },
  {
    nepali: "प्रशासकिय प्रमुख",
    english: "adminHead",
    value: 3
  },

  {
    nepali: "शाखा अधिकृत",
    english: "it",
    value: 4
  },
  {
    nepali: "वडा अध्यक्ष",
    english: "wardHead",
    value: 5
  },
  {
    nepali: "वडा सचिव",
    english: "wardSubHead",
    value: 6
  }
];

export const whichWard = { nepali: "कुन वार्ड ?", english: "Which ward?" };

export const username = {
  nepali: "प्रयोगकर्ता नाम",
  english: "username",
  value: ""
};

export const password = { nepali: "पासवर्ड", english: "password", value: "" };
export const email = { nepali: "ईमेल", english: "email", value: "" };

export const firstname = { nepali: "नाम", english: "first name", value: "" };

export const lastname = { nepali: "थर", english: "last name", value: "" };

export const genderText = { nepali: "लिङ्ग", english: "gender" };
export const searchOptionTab = [
  { english: "", nepali: "घर न. द्वारा खोज्नुहोस" },
  { english: "", nepali: "नामद्वारा खोज्नुहोस" },
  { english: "", nepali: "फोन न.द्वारा खोज्नुहोस" },
  { english: "", nepali: "वृस्तित खोजी" }
];
export const gharNumber = {
  nepali: "घर नं.",
  english: "House No."
};
export const name = {
  english: " FullName",
  nepali: "नाम"
};

export const phoneNumber = {
  english: "Phone No.",
  nepali: "फोन नं. "
};

export const baseMap = [
  {
    mapName: "OpenStreetMap.Mapnik",
    mapAttribution:
      '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    mapUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    mapApiKey: ""
  },
  {
    mapName: "OpenStreetMap.BlackAndWhite",
    mapAttribution:
      '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    mapUrl: "https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png",
    mapApiKey: ""
  }
];

export const house = { nepali: "घर", english: "House" };
export const family = { nepali: "जना", english: "people" };
export const houseNotFound = {
  nepali: " नम्बर घर भेटिएन",
  english: "House Not Found"
};
export const male = { nepali: "पुरुष", english: "male" };
export const female = { nepali: "महिला", english: "female" };
export const thirdgender = { nepali: "तेस्रो लिङ्गी", english: "third gender" };
export const total = { nepali: "जम्मा", english: "total" };
export const ageDiff = { nepali: "उमेर भिन्नता", english: "age difference" };

export const ageCategory = [
  {
    english: "0-4",
    nepali: "०-४"
  },
  {
    english: "5-9",
    nepali: "५-९"
  },
  {
    english: "10-14",
    nepali: "१०-१४"
  },
  {
    english: "15-19",
    nepali: "१५-१९"
  },
  {
    english: "20-24",
    nepali: "२०-२४"
  },
  {
    english: "25-29",
    nepali: "२५-२९"
  },
  {
    english: "30-34",
    nepali: "३०-३४"
  },
  {
    english: "35-39",
    nepali: "३५-३९"
  },
  {
    english: "40-44",
    nepali: "४०-४४"
  },
  {
    english: "45-49",
    nepali: "४५-४९"
  },
  {
    english: "50-54",
    nepali: "५०-५४"
  },
  {
    english: "55-59",
    nepali: "५५-५९"
  },
  {
    english: "60-64",
    nepali: "६०-६४"
  },
  {
    english: "65-69",
    nepali: "६५-६९"
  },
  {
    english: "70-74",
    nepali: "७०-७४"
  },
  {
    english: "75+",
    nepali: "७५+"
  }
];

export const oldPassword = {
  nepali: "पुरानो पासवर्ड",
  english: "Old Password"
};
export const currentPassword = {
  nepali: "नयाँ पासवर्ड",
  english: "New Password"
};
export const passwordChangeHeader = {
  nepali: "पासवर्ड परिवर्तन",
  english: "Password change"
};

export const searchByName = {
  nepali: "नामबाट खोजी ",
  english: "Search By Name"
};
export const searchByPhone = {
  nepali: "फोन नंबाट खोजी",
  english: "Search By Phone Numnber"
};
