import { Grid, IconButton, Modal } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  Button,
  Radio,
  Form,
  Icon,
  Input,
  message,
  Select,
  Typography,
  Upload,
  Divider,
  Table
} from "antd";
import Axios from "axios";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addInstitutionSurvey } from "../../../actions/instituteAction";
import isEmpty from "../../../utils/validation/is-empty";
import { no, selectedLanguage, ward, yes } from "../../../variable/global";
import getNepaliNumber from "../../../utils/getNepaliNumber";
import {
  add,
  wardQuestion,
  photo,
  address,
  riskArea,
  dead,
  injured,
  effectFromDisasterQn,
  effectFromDisasters,
  effectofDeath,
  effectofInjured,
  remove
} from "../../../variable/institute";
import { getNaturalDisaster } from "./api";

const { TextArea } = Input;
const RadioGroup = Radio.Group;

const { Option } = Select;

class NaturalDisaster extends Component {
  random = (low, high) => {
    return Math.random() * (high - low) + low;
  };
  state = {
    // image upload state
    uniqueIdentifier:
      this.props.match.params.category +
      "_" +
      this.random(10000, 99999) +
      this.random(1000, 9999),
    previewVisible: false,
    previewImage: "",
    fileList: {
      disasterImage: []
    },
    loaded: 0,
    // other
    photo: "",
    address: "",
    riskArea: "",
    effectFromDisaster: [],
    maxRiskIndex: 1
  };

  // image functions uplocad
  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true
    });
  };

  // imageUpload
  handleChangeFile = (name, value, imageName) => {
    //name ma house and value ma list of filelist
    console.log("CF", value);
    this.setState(
      prevState => ({
        ...prevState,
        fileList: {
          ...prevState.fileList,
          [name]: {
            image: value.fileList,
            fileName: prevState.uniqueIdentifier + "_" + imageName + ".jpg"
          }
        }
      }),
      () => {
        if (value.fileList.length > 0) this.handleUpload(name, imageName);
        if (value.fileList.length === 0) this.removeUpload(name, imageName);
      }
    );
  };

  // upload image
  handleUpload = (name, imageName) => {
    const fileList = this.state.fileList[name];
    const data = new FormData();
    const imageObject = fileList.image[0].originFileObj;

    let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";
    data.append("file", imageObject, fileName);

    Axios.post("/api/imageUpload/upload", data, {
      onUploadProgress: ProgressEvent => {
        this.setState({
          loaded: Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)
        });
      }
    })
      .then(res => {
        // then print response status
        message.success("upload successfully.");
        console.log(res.statusText);
      })
      .catch(err => message.error("upload failed."));
  };

  // remove uploaded image
  removeUpload = imageName => {
    let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";

    Axios.get(`/api/imageUpload/delete/${fileName}`)
      .then(res => {
        // then print response status
        message.success("deleted successfully.");
        console.log(res.statusText);
      })
      .catch(err => message.error("upload failed."));
  };

  componentDidMount() {
    let naturalId = this.props.match.params.id;
    if (naturalId) {
      getNaturalDisaster(naturalId).then(res =>
        this.setState({
          effectFromDisaster: res.data.effectFromDisaster,
          address: res.data.address,
          riskArea: res.data.riskArea,
          maxRiskIndex:
            res.data.effectFromDisaster && res.data.effectFromDisaster.length,
          // image
          fileList: {
            tourism: {
              fileName: res.data.uniqueIdentifier + "_institute.jpg",
              image: [
                {
                  uid: "-1",
                  name: `${res.data.uniqueIdentifier}_institute.jpg`,
                  status: "done",
                  url: `/api/imageUpload/image/${res.data.uniqueIdentifier}_institute.jpg`
                }
              ]
            }
          }
        })
      );
    }
  }

  onSubmit = event => {
    event.preventDefault();
    const newInstitute = {
      uniqueIdentifier: this.state.uniqueIdentifier,
      effectFromDisaster: this.state.effectFromDisaster,
      address: this.state.address,
      riskArea: this.state.riskArea
    };

    if (this.props.isUpdate) {
      newInstitute.id = this.props.match.params.id;
      this.props.addInstitutionSurvey(
        "naturalDisaster",
        newInstitute,
        this.props.history,
        this.props.match.params.category
      );
    } else
      this.props.addInstitutionSurvey(
        "naturalDisaster",
        newInstitute,
        this.props.history,
        this.props.match.params.category
      );
    // this.props.history.push(
    //   `/survey-by-institution/show/${this.props.match.params.category}`
    // );
  };
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };
  handleSelectChange = (name, value) => {
    console.log("handle ", name, value);
    this.setState({
      [name]: value
    });
  };

  addDhikka = () => {
    this.setState(prevState => ({
      maxRiskIndex: prevState.maxRiskIndex + 1
    }));
  };

  removeRow = index => () => {
    this.setState(prevState => {
      const effectFromDisaster = [...prevState.effectFromDisaster];
      effectFromDisaster.splice(index, 1);
      return { effectFromDisaster, maxRiskIndex: prevState.maxRiskIndex - 1 };
    });
  };

  handleDisasterChange = index => event => {
    // arr.splice(2, 1, "Lene");
    // event.persist();

    const disaster = event.target.value;
    this.setState(prevState => {
      const effectFromDisaster = [...prevState.effectFromDisaster];

      const data = {};
      data.disaster = disaster;
      data.dead = isEmpty(effectFromDisaster[index])
        ? ""
        : effectFromDisaster[index].dead;
      data.injured = isEmpty(effectFromDisaster[index])
        ? ""
        : effectFromDisaster[index].injured;

      // data.effectOption = isEmpty(effectFromDisaster[index])
      //   ? ""
      //   : effectFromDisaster[index].effectOption;
      effectFromDisaster.splice(index, 1, data);
      return { effectFromDisaster };
    });
  };

  handleDeathChange = index => event => {
    const dead = event.target.value;
    this.setState(prevState => {
      const effectFromDisaster = [...prevState.effectFromDisaster];

      const data = {};
      data.dead = dead;
      data.disaster = isEmpty(effectFromDisaster[index])
        ? ""
        : effectFromDisaster[index].disaster;
      data.injured = isEmpty(effectFromDisaster[index])
        ? ""
        : effectFromDisaster[index].injured;

      effectFromDisaster.splice(index, 1, data);
      return { effectFromDisaster };
    });
  };

  handleInjuredChange = index => event => {
    const injured = event.target.value;
    this.setState(prevState => {
      const effectFromDisaster = [...prevState.effectFromDisaster];

      const data = {};
      data.injured = injured;
      data.disaster = isEmpty(effectFromDisaster[index])
        ? ""
        : effectFromDisaster[index].disaster;
      data.dead = isEmpty(effectFromDisaster[index])
        ? ""
        : effectFromDisaster[index].dead;

      effectFromDisaster.splice(index, 1, data);
      return { effectFromDisaster };
    });
  };

  handleOptionChange = index => event => {
    console.log(event);
    const effectOption = event.target.value;
    this.setState(prevState => {
      const effectFromDisaster = [...prevState.effectFromDisaster];

      const data = {};
      data.effectOption = effectOption;
      data.disaster = isEmpty(effectFromDisaster[index])
        ? ""
        : effectFromDisaster[index].disaster;
      effectFromDisaster.splice(index, 1, data);
      return { effectFromDisaster };
    });
  };

  getRiskUI = () => {
    const { maxRiskIndex, effectFromDisaster } = this.state;
    const columns = [
      {
        title: "S.N",
        dataIndex: "sn",
        key: "sn",
        render: index => <p>{getNepaliNumber(index + 1)}</p>
      },
      {
        title: "Disaster",
        dataIndex: "sn",
        key: "disaster",
        render: each => (
          <Select
            placeholder="------------"
            name="disaster"
            value={
              effectFromDisaster &&
              effectFromDisaster[each] &&
              effectFromDisaster[each].disaster
            }
            onChange={value =>
              this.handleDisasterChange(each)({
                target: { name: "disaster", value }
              })
            }
            style={{ minWidth: 100 }}
          >
            {effectFromDisasters.map(level => (
              <Option value={level.value}>{level[selectedLanguage]}</Option>
            ))}
          </Select>
        )
      },
      {
        title: "Dead",
        dataIndex: "sn",
        key: "dead",
        render: each => (
          <Form.Item label={dead[selectedLanguage]}>
            <Input
              type="number"
              name="dead"
              onChange={this.handleDeathChange(each)}
              value={
                this.state.effectFromDisaster &&
                this.state.effectFromDisaster[each] &&
                this.state.effectFromDisaster[each].dead
              }
            />
          </Form.Item>
        )
      },
      {
        title: "Injured",
        dataIndex: "sn",
        key: "injured",
        render: each => (
          <Form.Item label={injured[selectedLanguage]}>
            <Input
              type="number"
              name="injured"
              onChange={this.handleInjuredChange(each)}
              value={
                this.state.effectFromDisaster &&
                this.state.effectFromDisaster[each] &&
                this.state.effectFromDisaster[each].injured
              }
            />
          </Form.Item>
        )
      },
      {
        title: "Action",
        key: "action",
        dataIndex: "sn",
        render: index =>
          parseInt(index) === maxRiskIndex - 1 ? (
            <Button type="dashed" onClick={this.addDhikka}>
              <Icon type="plus" />
              {add[selectedLanguage]}
            </Button>
          ) : (
            <Button type="dashed" onClick={this.removeRow(index)}>
              <Icon style={{ color: "red" }} type="minus" />
              {remove[selectedLanguage]}
            </Button>
          )
      }
    ];

    const dataSource = [...Array(maxRiskIndex).keys()].map(sn => ({
      sn,
      level: ""
    }));
    return (
      <Grid item xs={12}>
        <Table dataSource={dataSource} columns={columns} pagination={false} />
      </Grid>
    );
  };

  render() {
    const { classes, title } = this.props;
    const { previewVisible, previewImage } = this.state;
    const fileList = this.state.fileList;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <Paper className={classes.root} elevation={1}>
        <Typography variant="h5">{title}</Typography>
        <Form onSubmit={this.onSubmit}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item xs={6}>
              <Form.Item label={riskArea[selectedLanguage]}>
                <Input
                  onChange={this.handleChange("riskArea")}
                  value={this.state.riskArea}
                />
              </Form.Item>
            </Grid>

            <Grid item xs={6}>
              <Form.Item label={address[selectedLanguage]}>
                <Input
                  onChange={this.handleChange("address")}
                  value={this.state.address}
                />
              </Form.Item>
            </Grid>

            <Grid item xs={6}>
              <Form.Item label={photo[selectedLanguage]}>
                <Fragment>
                  {!this.state.isView ? (
                    <Upload
                      name="file"
                      // beforeUpload={this.props.beforeUpload}
                      multiple={false}
                      // action="/api/imageUpload/upload"
                      accept="image/*"
                      listType="picture-card"
                      fileList={
                        fileList.instituteImage && fileList.instituteImage.image
                      }
                      onPreview={this.handlePreview}
                      onChange={fileList =>
                        this.handleChangeFile(
                          "instituteImage",
                          fileList,
                          "institute"
                        )
                      }
                      disabled={this.state.isView}
                    >
                      {fileList &&
                      fileList.instituteImage &&
                      fileList.instituteImage.image &&
                      fileList.instituteImage.image.length === 1
                        ? null
                        : uploadButton}
                    </Upload>
                  ) : (
                    <Modal
                      visible={previewVisible}
                      footer={null}
                      onCancel={this.handleCancel}
                    >
                      <img
                        alt="example"
                        style={{ width: "100%" }}
                        src={previewImage}
                      />
                    </Modal>
                  )}
                </Fragment>
              </Form.Item>
            </Grid>

            <Divider orientation="left">
              {effectFromDisasterQn[selectedLanguage]}
            </Divider>
            <Grid item xs={12}>
              {this.getRiskUI()}
            </Grid>

            <Grid item xs={12}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={this.state.isView}
              >
                {add[selectedLanguage]}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Paper>
    );
  }
}

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  }
});

const mapStateToProps = state => ({});

export default withStyles(styles)(
  connect(mapStateToProps, { addInstitutionSurvey })(
    withRouter(NaturalDisaster)
  )
);
