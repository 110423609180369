import React from "react";
import Bidhur from "./Bidhur";
import Bidhuwa from "./Bidhuwa";

export default function index() {
  return (
    <div>
      <Bidhur />
      <Bidhuwa />
    </div>
  );
}
