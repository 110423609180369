import Axios from "axios";
import { startSnackBar } from "../../../../../../actions/utilAction";

export const GET_HOUSENUMBERDUPLI_LIST = "GET_HOUSENUMBERDUPLI_LIST";
export const SET_HOUSENUMBERDUPLI_LOADING = "SET_HOUSENUMBERDUPLI_LOADING";
export const EDIT_ERROR_HOUSENUMBERDUPLI = "EDIT_ERROR_HOUSENUMBERDUPLI";

export const getEmptyHouseNumberDuplicate = () => dispatch => {
  dispatch({
    type: SET_HOUSENUMBERDUPLI_LOADING,
    payload: true
  });

  Axios.get("/api/errorCorrection/duplicacy/houseNumber").then(response => {
    return dispatch({
      type: GET_HOUSENUMBERDUPLI_LIST,
      payload: response.data
    });
  });
};

export function editHouseNumberDuplicate(data) {
  return dispatch =>
    Axios.post("/api/errorCorrection/duplicacy/houseNumberUpdate", data)
      .then(response => {
        dispatch(getEmptyHouseNumberDuplicate());

        dispatch(
          startSnackBar({
            open: true,
            variant: "success",
            // message: response.data.message
            message: "घर नं सफलतापूर्वक अद्यावधिक गरियो"
          })
        );
        return dispatch({
          type: EDIT_ERROR_HOUSENUMBERDUPLI,
          payload: response.data
        });
      })
      .catch(err => console.log(err));
}
