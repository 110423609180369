import Axios from "axios";
import { startSnackBar } from "../../../../../../actions/utilAction";

export const GET_BIDHUR_LIST = "GET_BIDHUR_LIST";
export const GET_BIDHUWA_LIST = "GET_BIDHUWA_LIST";
export const SET_BIDHURBIDHUWA_LOADING = "SET_BIDHURBIDHUWA_LOADING";
export const EDIT_ERROR_BIDHURBIDHUWA = "EDIT_ERROR_BIDHURBIDHUWA";

export const getEmptyBidhur = ward => dispatch => {
  dispatch({
    type: SET_BIDHURBIDHUWA_LOADING,
    payload: true
  });

  Axios.get(
    `/api/errorCorrection/logicallyInvalid/martialStatusMale/${ward}`
  ).then(response => {
    return dispatch({
      type: GET_BIDHUR_LIST,
      payload: response.data
    });
  });
};

export const getEmptyBidhuwa = ward => dispatch => {
  dispatch({
    type: SET_BIDHURBIDHUWA_LOADING,
    payload: true
  });

  Axios.get(
    `/api/errorCorrection/logicallyInvalid/martialStatusFemale/${ward}`
  ).then(response => {
    return dispatch({
      type: GET_BIDHUWA_LIST,
      payload: response.data
    });
  });
};

export function editBidhurBidhuwa(data, gender) {
  return dispatch =>
    Axios.post(
      "/api/errorCorrection/logicallyInvalid/martialStatusUpdate",
      data
    )
      .then(response => {
        if (gender === "male") {
          dispatch(getEmptyBidhur());
          dispatch(
            startSnackBar({
              open: true,
              variant: "success",
              // message: response.data.message
              message: "विधुर लिङ्ग सफलतापूर्वक अद्यावधिक गरियो"
            })
          );
        }
        if (gender === "female") {
          dispatch(getEmptyBidhuwa());
          dispatch(
            startSnackBar({
              open: true,
              variant: "success",
              // message: response.data.message
              message: "विधुवा सफलतापूर्वक अद्यावधिक गरियो"
            })
          );
        }

        return dispatch({
          type: EDIT_ERROR_BIDHURBIDHUWA,
          payload: response.data
        });
      })
      .catch(err => console.log(err));
}
