import Axios from "axios";
import { startSnackBar } from "../../../../../../actions/utilAction";

export const GET_DATEOFBIRTH_LIST = "GET_DATEOFBIRTH_LIST";
export const SET_DATEOFBIRTH_LOADING = "SET_DATEOFBIRTH_LOADING";
export const EDIT_ERROR_DATEOFBIRTH = "EDIT_ERROR_DATEOFBIRTH";

export const getEmptyDateOfBirth = () => dispatch => {
  dispatch({
    type: SET_DATEOFBIRTH_LOADING,
    payload: true
  });

  Axios.get("/api/errorCorrection/emptyRequiredField/dateOfBirth").then(
    response => {
      return dispatch({
        type: GET_DATEOFBIRTH_LIST,
        payload: response.data
      });
    }
  );
};

export function editDateOfBirth(data) {
  console.log("data", data);
  return dispatch =>
    Axios.post(
      "/api/errorCorrection/emptyRequiredField/dateOfBirthUpdate",
      data
    )
      .then(response => {
        dispatch(getEmptyDateOfBirth());

        dispatch(
          startSnackBar({
            open: true,
            variant: "success",
            // message: response.data.message
            message: "जन्म मिती/उमेर सफलतापूर्वक अद्यावधिक गरियो"
          })
        );
        return dispatch({
          type: EDIT_ERROR_DATEOFBIRTH,
          payload: response.data
        });
      })
      .catch(err => console.log(err));
}
