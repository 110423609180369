import * as Actions from "../actions/answererRelation.actions";

const initialState = {
  list: []
};

const relationRelationReducer = function(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_ANSWERERRELATION_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.GET_ANSWERERRELATION_LIST:
      return {
        ...state,
        list: action.payload,
        loading: false
      };
    // case Actions.EDIT_HOUSENUMBER_EMPTY:
    //   return {
    //     ...state,
    //     newData: {
    //       ...state.newData,
    //       [action.name]: action.value
    //     }
    //   };
    default:
      return state;
  }
};

export default relationRelationReducer;
