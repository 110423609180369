import Axios from "axios";
import { startSnackBar } from "../../../../../../actions/utilAction";

export const GET_HOUSELOCATION_LIST = "GET_HOUSELOCATION_LIST";
export const GET_GEOJSON_LIST = "GET_GEOJSON_LIST";
export const SET_HOUSELOCATION_LOADING = "SET_HOUSELOCATION_LOADING";
export const EDIT_ERROR_HOUSELOCATION = "EDIT_ERROR_HOUSELOCATION";
export const EDIT_ERROR_LOCATIONPLOT = "EDIT_ERROR_LOCATIONPLOT";

export const getEmptyHouseLocation = () => dispatch => {
  dispatch({
    type: SET_HOUSELOCATION_LOADING,
    payload: true
  });

  Axios.get("/api/errorCorrection/dataEntryError/houseLocation").then(
    response => {
      return dispatch({
        type: GET_HOUSELOCATION_LIST,
        payload: response.data
      });
    }
  );
};

export function checkWithinPolygon(lat, lng, ward) {
  return dispatch =>
    Axios.get(
      `/api/errorCorrection/dataEntryError/checkWithinPolygon/${lat}/${lng}/${ward}`
    )
      .then(response => {
        // if (response === false) {
        //   dispatch(
        //     startSnackBar({
        //       open: true,
        //       variant: "success",
        //       // message: response.data.message
        //       message: "Please Drag the marker inside the border"
        //     })
        //   );
        // }

        return dispatch({
          type: EDIT_ERROR_LOCATIONPLOT,
          payload: response.data
        });
      })
      .catch(err => console.log(err));
}

export function editHouseLocation(lat, lng, houseId) {
  let data = {
    lat: lat,
    lng: lng,
    houseId: houseId
  };
  return dispatch =>
    Axios.post(`/api/errorCorrection/dataEntryError/locationUpdate`, data)
      .then(response => {
        dispatch(getEmptyHouseLocation());

        dispatch(
          startSnackBar({
            open: true,
            variant: "success",
            // message: response.data.message
            message: "घरको जियो कोड सफलतापूर्वक अद्यावधिक गरियो"
          })
        );
        return dispatch({
          type: EDIT_ERROR_HOUSELOCATION,
          payload: response.data
        });
      })
      .catch(err => console.log(err));
}

export const getWardGeoJson = ward => dispatch => {
  Axios.get(`/api/errorCorrection/dataEntryError/getWardGeojson/${ward}`).then(
    response =>
      dispatch({
        type: GET_GEOJSON_LIST,
        ward: ward,
        payload: response.data
      })
  );
};
