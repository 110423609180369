// @material-ui/icons
import HomeIcon from "@material-ui/icons/Home";
import PersonIcon from "@material-ui/icons/Person";
import MapIcon from "@material-ui/icons/Map";
import LogIcon from "@material-ui/icons/Description";
import { gaupalikaWard, selectedLanguage } from "../../variable/global";
import Home from "../dashboard/home/Home";
import UserProfile from "../dashboard/user";
import Log from "../dashboard/log";
import Report from "../dashboard/report";
import VillageProfile from "../dashboard/villageProfile/";
import DataCorrection from "../dashboard/dataCorrection/";
import SearchIcon from "@material-ui/icons/Search";
import HouseSurveyIcon from "@material-ui/icons/Receipt";
import ErrorIcon from "@material-ui/icons/Error";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import InstitutionSurveyIcon from "@material-ui/icons/AccountBalance";
import HouseSurvey from "../dashboard/houseSurvey/";
import InstitutionSurvey from "../dashboard/institutionSurvey/";
const dashboardRoutes = [
	{
		path: "/home",
		exact: true,
		hasChild: false,
		sidebarName: "गृह पृष्ठ",
		navbarName: gaupalikaWard[selectedLanguage],
		icon: HomeIcon,
		component: Home,
	},

	{
		sidebarName: "विवरण",
		icon: HomeIcon,
		hasChild: true,
		child: [
			{
				path: "/analysis-by-house",
				sidebarName: "घरधुरी विवरण",
				navbarName: "घरधुरी विवरण",
				icon: HomeIcon,
				component: Home,
			},
			{
				path: "/analysis-by-family",
				sidebarName: "पारिवारिक विवरण",
				navbarName: "पारिवारिक विवरण",
				icon: PersonIcon,
				component: Home,
			},
			{
				path: "/analysis-by-institution",
				sidebarName: "संस्थागत विवरण",
				navbarName: "संस्थागत विवरण",
				icon: PersonIcon,
				component: Home,
			},
		],
	},
	{
		sidebarName: "खोज्नुहोस",
		icon: SearchIcon,
		hasChild: true,
		child: [
			{
				path: "/advance-search-house",
				sidebarName: "घर विस्तृत खोजी",
				navbarName: "घर विस्तृत खोजी",
				icon: PersonIcon,
				component: UserProfile,
			},
			// {
			// 	path: "/house-number-search",
			// 	sidebarName: "घर नम्बर बाट खोजी",
			// 	navbarName: "घर नम्बर बाट खोजी",
			// 	icon: PersonIcon,
			// 	component: Home
			// },
			{
				path: "/search-by-death",
				sidebarName: "मृतकको खोजी",
				navbarName: "मृतकको खोजी",
				icon: PersonIcon,
				component: Home,
			},
			// ,
			// {
			// 	path: "/advance-search-institution",
			// 	sidebarName: "संस्थागत विस्तृत खोजी",
			// 	navbarName: "संस्थागत विस्तृत खोजी",
			// 	icon: PersonIcon,
			// 	component: Home
			// }
		],
	},

	{
		path: "/survey-by-house",
		hasChild: false,
		sidebarName: "घर सर्वेक्षण",
		navbarName: "घर सर्वेक्षण",
		icon: HouseSurveyIcon,
		component: HouseSurvey,
	},
	{
		path: "/survey-by-institution",
		sidebarName: "संस्था सर्वेक्षण",
		navbarName: "संस्था सर्वेक्षण",
		icon: InstitutionSurveyIcon,
		hasChild: false,
	},
	{
		sidebarName: "SMS",
		icon: AnnouncementIcon,
		hasChild: true,
		child: [
			{
				path: "/sms/home",
				exact: true,
				sidebarName: "Home Page",
				navbarName: "Smart SMS",
				icon: HomeIcon,
			},
			{
				path: "/sms/message",
				sidebarName: "Message",
				navbarName: "Message",
				icon: PersonIcon,
				exact: true,
			},
			{
				path: "/sms/desgination",
				sidebarName: "Designation",
				navbarName: "Designation",
				icon: PersonIcon,
				exact: true,
			},
			{
				path: "/sms/people",
				sidebarName: "People",
				navbarName: "People",
				icon: PersonIcon,
				exact: true,
			},
			{
				path: "/sms/group",
				sidebarName: "Group",
				navbarName: "Group",
				icon: PersonIcon,
				exact: true,
			},
		],
	},
	{
		path: "/users",
		hasChild: false,
		sidebarName: "प्रयोगकर्ता",
		navbarName: "प्रयोगकर्ता",
		icon: HomeIcon,
		component: UserProfile,
	},
	{
		path: "/log",
		hasChild: false,
		sidebarName: "लग",
		navbarName: "लग",
		icon: HomeIcon,
		component: Log,
	},
	// {
	// 	path: "/error",
	// 	hasChild: false,
	// 	sidebarName: "कोबोमा त्रुटि",
	// 	navbarName: "कोबोमा त्रुटि",
	// 	icon: ErrorIcon,
	// 	component: Log
	// },
	{
		path: "/report",
		sidebarName: "रिपोर्ट",
		navbarName: "रिपोर्ट",
		icon: PersonIcon,
		component: Report,
	},
	{
		path: "/e-profile-book",
		sidebarName: "e-प्रोफाइल किताब",
		navbarName: "e-प्रोफाइल किताब",
		icon: PersonIcon,
		component: VillageProfile,
	},
	{
		path: "/map",
		sidebarName: "नक्शा",
		navbarName: "नक्शा",
		icon: MapIcon,
		component: Map,
	},
	{
		path: "/data-correction",
		sidebarName: "डाटा सच्चाउने",
		navbarName: "डाटा सच्चाउने",
		icon: PersonIcon,
		component: DataCorrection,
	},

	// { redirect: true, path: "/", to: "/dashboard", navbarName: "Redirect" }
];

export default dashboardRoutes;
