import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getDeathSearchResult } from "../../../../actions/searchAction";
import { deathTableHeader } from "../../../../variable/global";
import DeathPagination from "./DeathPagination";
import SearchOption from "./SearchOption";
import getNepaliNumber from "../../../../utils/getNepaliNumber";
import ReactToPrint from "../../../common/frequent/print/ReactToPrint";
import PrintIcon from "@material-ui/icons/Print";
import Fab from "@material-ui/core/Fab";

class Index extends Component {
  state = {
    deathResult: [],
    page: 0,
    rowsPerPage: 10,
    ageRange: [0, 120],
    gender: 0,
    ward: 0,
    deathReason: [],
    temp: [],
    loading: true,
    csvData: []
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value }, () => this.filterData());
  };

  filterData = () => {
    const { temp, ageRange, gender, ward, deathReason } = this.state;
    const deathWardFilter =
      ward === 0 ? temp : temp.filter(({ wardNumber }) => wardNumber === ward);
    var deathGenderFilter = [];
    if (gender == 0) deathGenderFilter = deathWardFilter;
    else if (gender == 1)
      deathGenderFilter = deathWardFilter.filter(
        death => death.gender === "death_female"
      );
    else if (gender == 2)
      deathGenderFilter = deathWardFilter.filter(
        death => death.gender === "dearh_male"
      );
    else if (gender == 3)
      deathGenderFilter = deathWardFilter.filter(
        death => death.gender === "third_gender"
      );
    var deathReasonFilter = [];
    if (deathReason.length === 0) deathReasonFilter = deathGenderFilter;
    else {
      deathReasonFilter = deathGenderFilter.filter(({ reason }) => {
        const body = deathReason.map(eachR => {
          if (reason.indexOf(eachR) !== -1) return 1;
          return 2;
        });
        if (body.indexOf(1) !== -1) return true;
        else return false;
      });
    }
    var deathAgeFilter = [];
    deathAgeFilter = deathReasonFilter.filter(
      ({ age }) => age >= ageRange[0] && age <= ageRange[1]
    );
    const csvData = this.getCSVData(deathAgeFilter);
    this.setState({ deathResult: deathAgeFilter, csvData });
  };

  getGender = gender => {
    if (gender === "death_female") return "महिला";
    else if (gender === "dearh_male") return "पुरुष";
    else return "अन्य";
  };

  getDeathReson = reason => {
    const data = reason.map(each => {
      if (each === "epidimics") return "माहामारी";
      if (each === "tranferable") return "सर्नेरोग ( टीबी  जण्डिसआदि)";
      if (each === "aged") return "काल गति";
      if (each === "aids") return "HIVAIDS";
      if (each === "cancer") return "क्यान्सर";
      if (each === "diabetes") return "मधुमेह";
      if (each === "asthma") return "दम";
      if (each === "blood_pressure") return "रक्तचाप";
      if (each === "accident") return "दुर्घटना";
      if (each === "suicide") return "आत्महत्या";
      else return "अन्य";
    });
    return data.join(", ");
  };

  getCSVData = data => {
    let tile = [];
    let header = [
      "क्र. स.",
      "वडा नम्बर",
      "घर नम्बर",
      "मृत्यु हुनेको नाम थर",
      "लिङ्ग",
      "उमेर",
      "मृत्युको कारण"
    ];
    tile.push(header);
    data.forEach((each, i) => {
      let row = [
        getNepaliNumber(i + 1),
        getNepaliNumber(each.wardNumber) || "",
        getNepaliNumber(each.houseNumber) || "",
        each.name || "",
        this.getGender(each.gender) || "",
        getNepaliNumber(each.age) || "",
        this.getDeathReson(each.reason)
      ];

      tile.push(row);
    });
    return tile;
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };

  componentDidMount() {
    const wardNumber = parseInt(sessionStorage.getItem("ward"));
    const wardSearch = {
      house: {
        wardNumber
      }
    };

    this.props.getDeathSearchResult(wardNumber == 0 ? {} : wardSearch);
  }
  componentDidUpdate(prevProps) {
    if (this.props.death !== prevProps.death) {
      const csvData = this.getCSVData(this.props.death);
      this.setState({
        temp: this.props.death && this.props.death,
        deathResult: this.props.death && this.props.death,
        csvData,
        loading: false
      });
    }
  }

  clearFilter = () => {
    this.setState({
      deathResult: [],
      ageRange: [],
      gender: 0,
      ward: 0,
      deathReason: []
    });
  };

  handleSliderChange = val => {
    this.setState(
      {
        ageRange: val
      },
      () => this.filterData()
    );
  };

  render() {
    const {
      deathResult,
      rowsPerPage,
      page,
      ward,
      gender,
      deathReason,
      ageRange
    } = this.state;

    return (
      <div>
        <SearchOption
          wardNumber={ward}
          gender={gender}
          ageRange={ageRange}
          deathReason={deathReason}
          handleChange={this.handleChange}
          handleSliderChange={this.handleSliderChange}
        />

        <DeathPagination
          rows={deathResult}
          rowsPerPage={rowsPerPage}
          page={page}
          title="मृतकको विवरण"
          subtitle="वडा तथा घर न. को आधरमा"
          tableHeader={deathTableHeader}
          gender={gender}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleChangePage={this.handleChangePage}
          loading={this.state.loading}
          csvData={this.state.csvData}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ search }) => ({
  death: search.death
});

const styles = theme => ({});

export default withStyles(styles)(
  connect(mapStateToProps, { getDeathSearchResult })(Index)
);
