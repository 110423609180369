import React from "react";
import CenteredTabs from "./CenteredTabs";

export default function index() {
  return (
    <div>
      <CenteredTabs />
    </div>
  );
}
