import * as Actions from "../actions/memberCount.actions";

const initialState = {
  list: []
};

const memberCountReducer = function(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_MEMBERCOUNT_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.GET_MEMBERCOUNT_LIST:
      return {
        ...state,
        list: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export default memberCountReducer;
