import React, { useEffect, useState } from "react";
import { Table, InputNumber, Input, Form, Radio } from "antd";
import { Typography, Button, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getErrorMemberCount,
  editErrorMemberCount
} from "./store/actions/memberCount.actions";
import {
  sabikGabisa,
  sabikGabisaQuestion
} from "../../../../variable/houseSurvey";
import { selectedLanguage } from "../../../../variable/global";
import ConfirmDialog from "../common/confirmDialog";

const { Column } = Table;

export default function IncorrectMemberCount() {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(
    ({ dataCorrection }) => dataCorrection.dataEntry.memberCount
  );
  const [data, setData] = useState(list);
  const [open, setOpen] = React.useState(false);
  const [houseId, setHouseId] = useState("");
  const [incorrectMale, setIncorrectMale] = useState(-1);
  const [incorrectFemale, setIncorrectFemale] = useState(-1);
  const [incorrectTotal, setIncorrectTotal] = useState(0);
  const [editIndex, setEditIndex] = useState(-1);

  const handleClickOpen = (id, index) => () => {
    setIncorrectMale(list[index].maleNumber);
    setIncorrectFemale(list[index].femaleNumber);
    setHouseId(id);
    setEditIndex(index);
    // setOpen(true);
  };

  const handleClose = value => {
    setIncorrectMale(-1);
    setIncorrectFemale(-1);
    // setIncorrectTotal(0);
    setEditIndex(-1);
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getErrorMemberCount());
    setData(list);
  }, [dispatch]);

  // const onChange = name => value => {
  //   setTotal({
  //     [name]: value
  //   });
  // };

  const onSave = () => {
    let data = {
      maleNumber: incorrectMale,
      femaleNumber: incorrectFemale,
      // totalFamilyMember: incorrectTotal,
      houseId: houseId
    };
    dispatch(editErrorMemberCount(data));
    handleClose();
  };

  let mapColumn = [
    // {
    //   title: "सर्वेक्षण काेड",
    //   dataIndex: "dataCollectionCode",
    //   key: "dataCollectionCode",
    //   width: 200
    // },
    {
      title: "घर नं",
      dataIndex: "houseNumber",
      key: "houseNumber",
      width: 150
    },
    {
      title: "वडा नं",
      dataIndex: "wardNumber",
      key: "wardNumber",
      width: 150
    },
    {
      title: "Correct Count",
      children: [
        {
          title: "Correct Male Count",
          dataIndex: "familyMaleMembers",
          key: "familyMaleMembers",
          width: 200
        },
        {
          title: "Correct Female Count",
          dataIndex: "familyFemaleMembers",
          key: "familyFemaleMembers",
          width: 200
        }
        // {
        //   title: "Correct Total Count",
        //   dataIndex: "familyTotalMembers",
        //   key: "familyTotalMembers",
        //   width: 200
        // }
      ]
    },
    {
      title: "Difference",
      dataIndex: "difference",
      key: "difference",
      width: 200
    },
    {
      title: "Incorrect Count",
      children: [
        {
          title: "Incorrect Male Count",
          dataIndex: "maleNumber",
          key: "maleNumber",
          width: 200,
          render: (item, record, index) =>
            editIndex === index ? (
              <InputNumber
                defaultValue={item}
                onChange={value => setIncorrectMale(value)}
              />
            ) : (
              <p>{item}</p>
            )
        },
        {
          title: "Incorrect Female Count",
          dataIndex: "femaleNumber",
          key: "femaleNumber",
          width: 200,
          render: (item, record, index) =>
            editIndex === index ? (
              <InputNumber
                defaultValue={item}
                onChange={value => setIncorrectFemale(value)}
              />
            ) : (
              <p>{item}</p>
            )
        }
      ]
    }
  ];

  return (
    <div>
      <Typography variant="h4" component="h2" align="center">
        परिवारको जम्मा सदस्य संख्या
      </Typography>
      <Table
        style={{ width: "100%", margin: "0px 5px" }}
        dataSource={list}
        rowKey="uid"
      >
        <Column
          title={<span className="font-semibold">क्र.श</span>}
          dataIndex={"sn"}
          key={"sn"}
          render={(text, record, index) => <p>{index + 1}</p>}
        />

        {mapColumn.map(column => (
          <Column {...column} />
        ))}
        <Column
          title={<span className="font-semibold">क्रियाकलाप</span>}
          key="action"
          render={(each, record, index) => {
            return (
              <span>
                <Button
                  color="primary"
                  disabled={editIndex !== -1 && editIndex !== index}
                  onClick={
                    editIndex === 0 ? onSave : handleClickOpen(each.key, index)
                  }
                >
                  {editIndex === 0 ? "सच्चाउनुहोस" : "edit"}
                </Button>
                {editIndex === index && (
                  <Button color="primary" onClick={handleClose}>
                    cancel
                  </Button>
                )}
              </span>
            );
          }}
        />
      </Table>
      <ConfirmDialog
        open={open}
        onChange={onSave}
        onClose={handleClose}
        title="परिवारको जम्मा सदस्य संख्या"
        fullWidth={false}
      >
        <Typography variant="h5">Are you sure you want to</Typography>
        {/* <Input
          type="number"
          value={incorrectMale}
          onChange={({ target: { value } }) => setIncorrectMale(value)}
        />
        <Input
          type="number"
          value={incorrectFemale}
          onChange={({ target: { value } }) => setIncorrectFemale(value)}
        />
        <Input
          type="number"
          value={incorrectTotal}
          onChange={({ target: { value } }) => setIncorrectTotal(value)}
        /> */}
      </ConfirmDialog>
    </div>
  );
}
