import React, { useEffect, useState } from "react";
import { Table, Spin, Skeleton, Input } from "antd";
import { Typography, Button, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmptyHouseLocation,
  getWardGeoJson,
  checkWithinPolygon,
  editHouseLocation
} from "./store/actions/houseLocation.actions";
import GeoJsonLayer from "./map/GeoJSONWithLayer";
import ConfirmDialog from "../common/confirmDialog";
import { Map, TileLayer, LayersControl } from "react-leaflet";
import { geoCode } from "../../../../variable/global";
// import { SnackBar } from "../../../common/Snackbar/Snackbar";
import ClipLoader from "react-spinners/ClipLoader";

const { BaseLayer } = LayersControl;
const { Column } = Table;

export default function HouseLocation(props) {
  const dispatch = useDispatch();
  const { locations, loading, geoJson, polygonCheck } = useSelector(
    ({ dataCorrection }) => dataCorrection.dataEntry.houseLocation
  );
  const [open, setOpen] = useState(false);
  const [latlngOpen, setLatlngOpen] = useState(false);
  const [houseId, setHouseId] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [ward, setWard] = useState(0);
  const [mapEdit, setMapEdit] = useState(false);
  const [isPolygon, setIsPolygon] = useState(false);

  const handleClickOpen = (ward, id, lat, lng) => async () => {
    setMapEdit(false);
    if (ward && !geoJson[`ward${ward}`]) {
      await dispatch(getWardGeoJson(ward));
    }
    setWard(ward);
    setHouseId(id);
    lat && setLatitude(parseFloat(lat));
    lng && setLongitude(parseFloat(lng));
    setOpen(true);
  };

  const handleInputOpen = id => () => {
    setHouseId(id);
    setLatlngOpen(true);
  };

  const handleOnSet = () => {
    setIsPolygon(true);
    setMapEdit(false);
  };

  const addMarker = e => {
    if (mapEdit) {
      setLatitude(e.latlng.lat);
      setLongitude(e.latlng.lng);
    }
  };

  const handleMapChange = () => {
    setMapEdit(true);
  };

  const handleClose = value => {
    setWard(0);
    setOpen(false);
  };

  const handleLatLngClose = value => {
    setLatitude("");
    setLongitude("");
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getEmptyHouseLocation());
  }, [dispatch]);

  const onSave = () => {
    dispatch(checkWithinPolygon(latitude, longitude, ward));
    if (isPolygon) dispatch(editHouseLocation(latitude, longitude, houseId));
    handleClose();
  };

  let mapColumn = [
    {
      title: "सर्वेक्षण काेड",
      dataIndex: "dataCollectionCode",
      key: "dataCollectionCode",
      width: 200
    },
    {
      title: "घर नं",
      dataIndex: "houseNumber",
      key: "houseNumber",
      width: 200
    },
    {
      title: " वडा नं",
      dataIndex: "wardNumber",
      key: "wardNumber",
      width: 200
    },
    {
      title: "अक्षांश",
      dataIndex: "latitude",
      key: "latitude",
      width: 200
    },
    {
      title: "देशान्तर",
      dataIndex: "longitude",
      key: "longitude",
      width: 200
    }
  ];

  let plotGeoJson = ward == 0 ? false : geoJson[`ward${ward}`];

  return (
    <div>
      <Typography variant="h4" component="h2" align="center">
        घरको जियो कोड
      </Typography>
      <Grid container spacing={24}>
        {loading ? (
          // getLoading(5)
          <div style={{ display: "block", margin: "0 auto" }}>
            <Spin size="large" />
          </div>
        ) : (
          <Table
            style={{ width: "100%", margin: "0px 5px" }}
            dataSource={locations}
            rowKey="uid"
          >
            <Column
              title={<span className="font-semibold">क्र.श</span>}
              dataIndex={"sn"}
              key={"sn"}
              render={(text, record, index) => <p>{index + 1}</p>}
            />

            {mapColumn.map(column => (
              <Column {...column} />
            ))}
            <Column
              title={<span className="font-semibold">क्रियाकलाप</span>}
              key="action"
              render={each => {
                return (
                  <span>
                    <Button
                      color="primary"
                      onClick={handleClickOpen(
                        each.wardNumber,
                        each.key,
                        each.latitude,
                        each.longitude
                      )}
                    >
                      सच्चाउनुहोस
                    </Button>
                  </span>
                );
              }}
            />
          </Table>
        )}
      </Grid>

      <ConfirmDialog
        open={latlngOpen}
        onChange={onSave}
        onClose={handleLatLngClose}
        title="घर नं"
        flag={false}
        fullWidth={false}
      >
        <Input
          type="text"
          value={latitude}
          onChange={({ target: { value } }) => setLatitude(value)}
        />
        <Input
          type="text"
          value={longitude}
          onChange={({ target: { value } }) => setLongitude(value)}
        />
      </ConfirmDialog>

      <ConfirmDialog
        open={open}
        // onChange={onSave}
        onClose={handleClose}
        title="घरको जियो कोड"
        flag={false}
        fullWidth={true}
      >
        <Grid>
          <span>
            <Button color="primary" onClick={handleMapChange}>
              सच्चाउनुहोस
            </Button>
            <Button color="primary" onClick={handleOnSet} disabled={!mapEdit}>
              set
            </Button>
          </span>

          {mapEdit === true && (
            <Typography variant="h6">
              * Please Drag your Marker inside map
            </Typography>
          )}
        </Grid>

        <div className="map" style={{ width: "100%" }}>
          <Map
            id="map"
            className="map"
            center={[latitude || geoCode.lat, longitude || geoCode.lng]}
            zoom={16}
            onClick={addMarker}
            scrollWheelZoom={false}
          >
            <LayersControl position="bottomright">
              <BaseLayer checked name="OpenStreetMap.Mapnik">
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
              </BaseLayer>
              <BaseLayer name="OpenStreetMap.BlackAndWhite">
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                />
              </BaseLayer>
            </LayersControl>

            {/* {plotGeoJson && (/ */}
            <GeoJsonLayer
              // type={plotGeoJson.type}
              // key={plotGeoJson.name}
              // style={plotGeoJson.style}
              data={plotGeoJson}
              geo={[latitude, longitude]}
            />
            {/* )} */}
          </Map>
        </div>

        {isPolygon && (
          <Button onClick={onSave} color="primary" autoFocus>
            बुझाउनुहोस्
          </Button>
        )}
      </ConfirmDialog>
    </div>
  );
}
