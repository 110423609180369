import * as Actions from "../actions/houseGeneric.actions";

const initialState = {
  list: []
};

const houseGenericReducer = function(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_HOUSEGENERIC_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.GET_HOUSEGENERIC_LIST:
      return {
        ...state,
        list: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export default houseGenericReducer;
