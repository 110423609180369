import { combineReducers } from "redux";
import houseLocation from "./houseLocation.reducer";
import memberCount from "./memberCount.reducer";

const dataEntryErrorReducer = combineReducers({
  houseLocation,
  memberCount
});

export default dataEntryErrorReducer;
