import { combineReducers } from "redux";
import houseNumber from "./houseNumber.reducer";
import oldVdc from "./houseOldVdc.reducer";
import answerer from "./answerer.reducer";
import memberName from "./memberName.reducer";
import answererRelation from "./answererRelation.reducer";
import houseOwner from "./houseOwner.reducer";
import dateOfBirth from "./dateOfBirth.reducer";
import familyGeneric from "./familyGeneric.reducer";
import houseGeneric from "./houseGeneric.reducer";

const emptyFieldReducer = combineReducers({
  houseNumber,
  oldVdc,
  answerer,
  memberName,
  answererRelation,
  houseOwner,
  dateOfBirth,
  familyGeneric,
  houseGeneric
});

export default emptyFieldReducer;
