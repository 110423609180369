import Axios from "axios";
import { startSnackBar } from "../../../../../../actions/utilAction";

export const GET_HOUSEOWNER_LIST = "GET_HOUSEOWNER_LIST";
export const SET_HOUSEOWNER_LOADING = "SET_HOUSEOWNER_LOADING";
export const EDIT_ERROR_HOUSEOWNER = "EDIT_ERROR_HOUSEOWNER";

export const getEmptyHouseOwner = () => dispatch => {
  dispatch({
    type: SET_HOUSEOWNER_LOADING,
    payload: true
  });

  Axios.get("/api/errorCorrection/emptyRequiredField/houseOwner").then(
    response => {
      return dispatch({
        type: GET_HOUSEOWNER_LIST,
        payload: response.data
      });
    }
  );
};

export function editHouseOwner(data) {
  return dispatch =>
    Axios.post("/api/errorCorrection/emptyRequiredField/houseOwnerUpdate", data)
      .then(response => {
        dispatch(getEmptyHouseOwner());

        dispatch(
          startSnackBar({
            open: true,
            variant: "success",
            // message: response.data.message
            message: "घरमुलीको नाता आफै नभएको सफलतापूर्वक अद्यावधिक गरियो"
          })
        );
        return dispatch({
          type: EDIT_ERROR_HOUSEOWNER,
          payload: response.data
        });
      })
      .catch(err => console.log(err));
}
