import React, { useEffect, useState } from "react";
import { Table, Select, Input, Form, Radio } from "antd";
import { Typography, Button, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmptyOldVdc,
  editOldVdc
} from "./store/actions/houseOldVdc.actions";
import {
  sabikGabisa,
  sabikGabisaQuestion
} from "../../../../variable/houseSurvey";
import { selectedLanguage } from "../../../../variable/global";
import ConfirmDialog from "../common/confirmDialog";

const { Column } = Table;

export default function HouseOldVdc() {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(
    ({ dataCorrection }) => dataCorrection.emptyField.oldVdc
  );
  const [data, setData] = useState(list);
  const [open, setOpen] = React.useState(false);
  const [oldVdc, setOldVdc] = useState("");
  const [houseId, setHouseId] = useState("");

  const handleClickOpen = id => () => {
    setHouseId(id);
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getEmptyOldVdc());
  }, [dispatch]);

  useEffect(() => {
    setData(list);
  });

  const onSave = () => {
    let data = {
      oldVDC: oldVdc,
      houseid: houseId
    };
    dispatch(editOldVdc(data));
    handleClose();
  };

  let mapColumn = [
    {
      title: "सर्वेक्षण काेड",
      dataIndex: "dataCollectionCode",
      key: "dataCollectionCode",
      width: 200
    },
    {
      title: "वडा नं",
      dataIndex: "wardNumber",
      key: "wardNumber",
      width: 200
    },
    {
      title: "उत्तरदाताको नाम",
      dataIndex: "answererName",
      key: "answererName",
      width: 200
    },
    {
      title: "घरमुली संगको नाता",
      dataIndex: "answererRelation",
      key: "answererRelation",
      width: 200
    }
  ];

  return (
    <div>
      <Typography variant="h4" component="h2" align="center">
        साविक गा.वि.स
      </Typography>
      <Table
        style={{ width: "100%", margin: "0px 5px" }}
        dataSource={data.data}
        rowKey="uid"
      >
        <Column
          title={<span className="font-semibold">क्र.श</span>}
          dataIndex={"sn"}
          key={"sn"}
          render={(text, record, index) => <p>{index + 1}</p>}
        />

        {mapColumn.map(column => (
          <Column {...column} />
        ))}
        <Column
          title={<span className="font-semibold">क्रियाकलाप</span>}
          key="action"
          render={each => {
            return (
              <span>
                <Button color="primary" onClick={handleClickOpen(each.key)}>
                  सच्चाउनुहोस
                </Button>
              </span>
            );
          }}
        />
      </Table>
      <ConfirmDialog
        open={open}
        onChange={onSave}
        onClose={handleClose}
        title="साविक गा.वि.स"
      >
        <Radio.Group
          onChange={({ target: { value } }) => setOldVdc(value)}
          value={oldVdc}
        >
          {sabikGabisa.map(each => (
            <Radio value={each.value}>{each.nepali}</Radio>
          ))}
        </Radio.Group>
      </ConfirmDialog>
    </div>
  );
}
