import React, { useEffect, useState } from "react";
import { Table, Select, Input } from "antd";
import { Typography, Button, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmptyAnswerer,
  getAnswererList,
  editAnswerer
} from "./store/actions/answerer.actions";
import ConfirmDialog from "../common/confirmDialog";
// import { SnackBar } from "../../../common/Snackbar/Snackbar";
import ClipLoader from "react-spinners/ClipLoader";

const { Column } = Table;
const { Option } = Select;

export default function AnswererName(props) {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(
    ({ dataCorrection }) => dataCorrection.emptyField.answerer
  );
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [answerer, setAnswerer] = useState("");
  const [houseId, setHouseId] = useState("");

  const handleClickOpen = id => () => {
    setHouseId(id);
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getEmptyAnswerer());
    // answererLists();
  }, [dispatch]);

  useEffect(() => {
    setData(list.mistakeHouse);
  });

  const handleChange = value => {
    setAnswerer(value);
  };

  const onSave = () => {
    let data = {
      answererName: answerer,
      houseId: houseId
    };
    dispatch(editAnswerer(data));
    handleClose();
  };

  let mapColumn = [
    {
      title: "सर्वेक्षण काेड",
      dataIndex: "dataCollectionCode",
      key: "dataCollectionCode",
      width: 200
    },
    {
      title: "वडा नं",
      dataIndex: "wardNumber",
      key: "wardNumber",
      width: 200
    },
    {
      title: "घर नं",
      dataIndex: "houseNumber",
      key: "houseNumber",
      width: 200
    },
    {
      title: "नामहरु",
      dataIndex: "houseMembers",
      key: "houseMembers",
      width: 200,
      render: relation => (
        <span>
          <Select
            defaultValue="-------"
            style={{ width: 120 }}
            onChange={handleChange}
          >
            {relation.map(each => (
              <Option value={each.fullname}>{each.fullname}</Option>
            ))}
          </Select>
        </span>
      )
    }
  ];
  return (
    <div>
      <Typography variant="h4" component="h2" align="center">
        उत्तरदाताको नाम
      </Typography>
      {/* <Grid container spacing={24}>
        {loading ? (
          <Grid
            style={{ marginTop: "40vh" }}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <ClipLoader
                sizeUnit={"px"}
                size={84}
                color={"#00ACC1"}
                loading={loading}
              />
            </Grid>
          </Grid>
        ) : ( */}
      <Table
        style={{ width: "100%", margin: "0px 5px" }}
        dataSource={data}
        rowKey="uid"
        pagination={{
          defaultPageSize: 5,
          showSizeChanger: true
          //   pageSizeOptions: ["10", "20", "30"]
        }}
      >
        <Column
          title={<span className="font-semibold">क्र.श</span>}
          dataIndex={"sn"}
          key={"sn"}
          render={(text, record, index) => <p>{index + 1}</p>}
        />

        {mapColumn.map(column => (
          <Column {...column} />
        ))}
        <Column
          title={<span className="font-semibold">क्रियाकलाप</span>}
          key="action"
          render={each => {
            return (
              <span>
                <Button color="primary" onClick={handleClickOpen(each.key)}>
                  सच्चाउनुहोस
                </Button>
              </span>
            );
          }}
        />
      </Table>
      {/* //   )}
      // </Grid> */}
      <ConfirmDialog
        open={open}
        onChange={onSave}
        onClose={handleClose}
        title="उत्तरदाताको नाम"
      >
        <p>डाटा अद्यावधिक गर्नु हुन्छ / हुँदैन</p>
      </ConfirmDialog>
    </div>
  );
}
