import React, { Component, useState } from "react";
import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Avatar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField
} from "@material-ui/core";
import FolderIcon from "@material-ui/icons/Folder";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import DownloadIcon from "@material-ui/icons/SaveAlt";
import PreviewIcon from "@material-ui/icons/Visibility";
import AttachmentIcon from "@material-ui/icons/Attachment";
import withStyles from "@material-ui/core/styles/withStyles";
import Axios from "axios";
import AlertDialog from "../../common/frequent/dialog/AlertDialog";
import { connect } from "react-redux";
import { getProfileReportList } from "../../../actions/profileReportAction";
import { Skeleton, Switch, Button } from "antd";
class FileList extends Component {
  state = {
    dense: false,
    deleteDialog: false,
    filename: ""
  };

  componentDidMount() {
    this.props.getProfileReportList();
  }

  handleDeleteOpen = filenameId => event => {
    this.setState({ deleteDialog: true, currentFileId: filenameId });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleClose = () => {
    this.setState({ deleteDialog: false });
  };

  removeFilename = () => {
    const fileId = this.state.currentFileId;

    Axios.delete(`/api/villageReport/delete/${fileId}`)
      .then(res => {
        if (res.data.success) {
          this.handleClose();
          this.props.getProfileReportList();
        }
      })
      .catch(err => {});
  };

  getLoading = count => {
    return (
      <Grid item>
        {[...Array(count).keys()].map(() => (
          <Skeleton avatar paragraph={{ rows: 0 }} />
        ))}
      </Grid>
    );
  };

  render() {
    const { classes, reportList, loading } = this.props;
    const { dense } = this.state;
    return (
      <Grid item xs={12} md={12}>
        <Typography variant="h6" className={classes.title}>
          List of FileName
        </Typography>
        {loading ? (
          this.getLoading(3)
        ) : (
          <div className={classes.demo}>
            {reportList &&
              reportList.map(each => (
                <List dense={dense}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <AttachmentIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={each.name}
                      secondary={each.createdAt}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="preview">
                        <PreviewIcon />
                      </IconButton>
                      <IconButton edge="end" aria-label="download">
                        <a
                          className={classes.anchor}
                          href={`/api/villageReport/download/${each._id}`}
                        >
                          <DownloadIcon />
                        </a>
                      </IconButton>

                      <IconButton edge="end" aria-label="delete">
                        <DeleteIcon onClick={this.handleDeleteOpen(each._id)} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              ))}
          </div>
        )}
        <Dialog
          disableBackdropClick
          minWidth="sm"
          open={this.state.editDialogOpen}
          onClose={this.editClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"title"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <TextField
                onFocus={this.resetError}
                id="outlined-filename"
                label="File Name"
                error={this.state.nameError}
                helperText={this.state.nameError}
                // className={classes.textField}
                value={this.state.filename}
                onChange={this.handleChange("filename")}
                margin="normal"
                variant="outlined"
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.editClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={this.onSubmit}
              color="primary"
              autoFocus
              disabled={!this.state.filename}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <AlertDialog
          open={this.state.deleteDialog}
          handleClose={this.handleClose}
          negButtonClick={this.handleClose}
          posButtonClick={this.removeFilename}
          title="Are you sure you want to delete the user ?"
          posButton="agree"
          negButton="disagree"
        />
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 752
  },
  demo: {
    backgroundColor: theme.palette.background.paper
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  },
  anchor: {
    color: "#000",
    textDecoration: "none"
  }
});

const mapStateToProps = ({ profileReport: { reportList, loading } }) => ({
  reportList,
  loading
});

export default withStyles(styles)(
  connect(mapStateToProps, { getProfileReportList })(FileList)
);
