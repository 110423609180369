const reportList = [
	{
		nepali: "वार्ड न. बाट उमेर समुहको विवरण",
		english: "",
		value: "ageGroupWard",
		title: "family",
	},
	{
		nepali: "घरमुली जनसंख्या तथा घरधुरी विवरण",
		english: "",
		value: "houseOwnerGender",
		title: "family",
	},
	{
		nepali: "अध्ययन गरेको शैक्षिक तहअनुसार जनसंख्या स्थिति",
		english: "Education detail",
		value: "education",
		title: "family",
	},
	{
		nepali: "साक्षरता स्थिति",
		english: "",
		value: "literacy",
		title: "family",
	},
	{
		nepali: "बैवाहिक अवस्था सम्बन्धी विवरण",
		english: "Martial detail",
		value: "martialStatus",
		title: "family",
	},
	{
		nepali: "मातृभाषा / बोलिने भाषा सम्बन्धी विवरण",
		english: "Mothertongue",
		value: "motherTongue",
		title: "family",
	},
	{
		nepali: "धर्म सम्बन्धी विवरण",
		english: "Religion detail",
		value: "religionReport",
		title: "family",
	},
	{
		nepali: "जातजाती सम्बन्धी विवरण",
		english: "Caste detail",
		value: "ethnics",
		title: "family",
	},
	{
		nepali: "पेशा सम्बन्धी विवरण",
		english: "Occupation detail",
		value: "occupation",
		title: "family",
	},
	{
		nepali: "अपाङ्गता सम्बन्धी विवरण र जनसंख्या",
		english: "Disable",
		value: "disable",
		title: "family",
	},
	{
		nepali: "उमेर समुहको विवरण",
		english: "",
		value: "ageGroup",
		title: "family",
	},
	{
		nepali: "व्यवासायिक सिप तालिम विवरण",
		english: "",
		value: "skill",
		title: "family",
	},
	{
		nepali: "पशु पालन सम्बन्धी विवरण",
		english: "",
		value: "animal",
		title: "house",
	},
	{
		nepali: "पन्छी पालन सम्बन्धी विवरण",
		english: "",
		value: "bird",
		title: "house",
	},
	{
		nepali: "कृषि उत्पादन सम्बन्धी विवरण",
		english: "",
		value: "agricultureProduction",
		title: "house",
	},
	{
		nepali: "अन्न वालि विवरण",
		english: "",
		value: "crops",
		title: "house",
	},
	{
		nepali: "दलहन विवरण",
		english: "",
		value: "daal",
		title: "house",
	},
	{
		nepali: "तरकारी विवरण",
		english: "",
		value: "vegetable",
		title: "house",
	},
	{
		nepali: "नगदेवाली विवरण",
		english: "",
		value: "cashCrops",
		title: "house",
	},
	{
		nepali: "फलफुलखेती विवरण",
		english: "",
		value: "fruits",
		title: "house",
	},
	{
		nepali: "तेलहन विवरण",
		english: "",
		value: "oilSeed",
		title: "house",
	},
	// {
	//   nepali: "कृषि उत्पादन सम्बन्धि",
	//   english: "Agriculture",
	//   value: "agriculture",
	//   title: "family",
	// },
	// {
	//   nepali: "बसोवास स्थान सम्बन्धी विवरण",
	//   english: "Living place",
	//   value: "livingtype",
	//   title: "family"
	// },
	// {
	//   nepali: "मतदाता परिचय पत्र",
	//   english: "Voter card",
	//   value: "voterCard",
	//   title: "family"
	// },
	// {
	//   nepali: "विदेश गएको विवरण",
	//   english: "Aboard",
	//   value: "abroad",
	//   title: "family"
	// },
	// {
	//   nepali: "उमेर अनुसारको विवरण",
	//   english: "Age",
	//   value: "age",
	//   title: "family"
	// },
	// {
	//   nepali: "रगत समुहको विवरण",
	//   english: "Blood Group",
	//   value: "bloodGroup",
	//   title: "family"
	// },
	// {
	//   nepali: "मृत्यु हुनेको उमेर अनुसारको विवरण",
	//   english: "Death Age",
	//   value: "deathAge",
	//   title: "family"
	// },
	// {
	//   nepali: "मृत्यु हुनेको कारण अनुसारको विवरण",
	//   english: "Death Reason",
	//   value: "deathDisease",
	//   title: "family"
	// },
	// {
	//   nepali: "घरमा शौचालय छ/छैन",
	//   english: "Toilet",
	//   value: "isToilet",
	//   title: "house"
	// },
	// {
	//   nepali: "शौचालयको किसिम विवरण",
	//   english: "Toilet",
	//   value: "toilet",
	//   title: "house"
	// },
	// {
	//   nepali: "शौचालय नभएको विवरण",
	//   english: "Toilet",
	//   value: "noToilet",
	//   title: "house"
	// },
	// {
	//   nepali: "खानेपानीको मुख्य श्रोत विवरण",
	//   english: "Water resource",
	//   value: "waterresource",
	//   title: "house"
	// },
	// {
	//   nepali: "वालवालिकामा पोषण सम्बन्धी विवरण",
	//   english: "Child nitrution",
	//   value: "childNutrition",
	//   title: "house"
	// },
	// {
	//   nepali: "परिवारमा सामाजिक सुरक्षा भत्ता विवरण",
	//   english: "Social security",
	//   value: "bhatta",
	//   title: "house"
	// },
	// {
	//   nepali: "परिवारलाई कति महिना खान पुग्छ विवरण",
	//   english: "Food",
	//   value: "foodformonth",
	//   title: "house"
	// },
	// {
	//   nepali: "सिंचाईको माध्यम विवरण",
	//   english: "Irriagtion detail",
	//   value: "irrigation",
	//   title: "house"
	// },
	// {
	//   nepali: "बीमा विवरण",
	//   english: "Insurance detail",
	//   value: "insurance",
	//   title: "house"
	// },
	// {
	//   nepali: "घरको छानाको बनौट विवरण",
	//   english: "Roof type",
	//   value: "rooftype",
	//   title: "house"
	// },
	// {
	//   nepali: "साबुनपानीले हात धुने सम्बन्धी विवरण",
	//   english: "Soap HandWash",
	//   value: "soapHandWash",
	//   title: "house"
	// },
	// {
	//   nepali: "घरको स्वामित्वको विवरण",
	//   english: "House Used Living",
	//   value: "houseUsedLiving",
	//   title: "house"
	// },
	// {
	//   nepali: "घरको सुविधाहरुको विवरण",
	//   english: "House Facility",
	//   value: "houseFacility",
	//   title: "house"
	// },
	// {
	//   nepali: "घर संख्याको विवरण",
	//   english: "House Count",
	//   value: "houseCount",
	//   title: "house"
	// },
	// {
	//   nepali: "पशु पालन सम्बन्धी विवरण",
	//   english: "Animal Livestock",
	//   value: "animalCount",
	//   title: "house"
	// },
	// {
	//   nepali: "पन्छी पालन सम्बन्धी विवरण",
	//   english: "Bird Livestock",
	//   value: "birdCount",
	//   title: "house"
	// }
];

module.exports = {
	reportList,
};
