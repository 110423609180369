import React, { useEffect, useState, useRef } from "react";
import { Table, Spin, Input, Select, InputNumber } from "antd";
import { Typography, Button, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmptyHouseGeneric,
  editHouseGeneric
} from "./store/actions/houseGeneric.actions";
import ConfirmDialog from "../common/confirmDialog";
// import { SnackBar } from "../../../common/Snackbar/Snackbar";
import ClipLoader from "react-spinners/ClipLoader";
import { selectedLanguage } from "../../../../variable/global";
import getNepaliNumber from "../../../../utils/getNepaliNumber";
import OptionSelectionHouse from "./OptionSelectionHouse";

const { Column } = Table;
const { Option } = Select;

export default function HouseGeneric(props) {
  const config = [
    "waterSource",
    "distanceToWaterSource",
    "cookingSource",
    "electricSource",
    "wasteDisposal",
    "toilet",
    "toiletYes",
    "toiletNo",
    "road",
    "bankAccount",
    "numberOfBankAccount",
    "saltUsed",
    "houseCount",
    "familyMemberAnotherHouse",
    "familyMemberAnotherHouseLocation",
    "totalRoom",
    "totalStorey",
    // "houseAge",
    "HouseUsedLiving",
    "landUsedLiving",
    "houseType",
    "roofType",
    "houseType",
    "roofType",
    "landDocument",
    "doHouseCriteriaFullfill",
    "isHouseArchiving",
    "hasHouseMapPass",
    "insuranceinsuranceType",
    "insurancecount",
    "insurancename",
    "deathName",
    "deathReason",
    "deathCertificate",
    "deathAge",
    "deathGender",
    "production",
    "sale",
    "area",
    "irrigation",
    "seedNeed",
    "homeBusinessName",
    // "homeBusinessIncome",  // not in phidim
    // "herbBusinessName" // not in phidim
    "alcoholDeathCount",
    "childDeathCount",
    "welfareTitle",
    "maleFemaleCount"
  ];
  const dispatch = useDispatch();
  const { list, loading } = useSelector(
    ({ dataCorrection }) => dataCorrection.emptyField.houseGeneric
  );
  const [open, setOpen] = React.useState(false);
  const [houseId, setHouseId] = useState("");
  const [inputText, setText] = useState({});
  const [detailId, setDetailId] = useState("");
  const [errorType, setErrorType] = useState("");
  const [editIndex, setEditIndex] = useState(-1);
  const [ropaniInput, setRopani] = useState(0);
  const [aanaInput, setAana] = useState(0);
  const [paisaInput, setPaisa] = useState(0);
  const prevText = usePrevious(inputText);

  const handleClickOpen = (id, index, detailId, errorType) => () => {
    if (detailId) setDetailId(detailId);
    if (errorType) setErrorType(errorType);
    setEditIndex(index);
    setHouseId(id);
    // setOpen(true);
  };

  const handleClose = value => {
    setEditIndex(-1);
    setHouseId("");
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getEmptyHouseGeneric());
  }, [dispatch]);

  //   useEffect(() => {
  //     setData(list);
  //   });

  const handleChange = (name, value) => {
    setText({ [name]: value });
    console.log("text", inputText);
  };

  const handleUnitChange = value => {
    handleChange(value, Object.values(prevText)[0]);
  };

  const handleAreaChange = (name, value) => {
    if (name === "ropani") setRopani(value);
    if (name === "aana") setAana(value);
    if (name === "paisa") setPaisa(value);
  };

  const onSave = () => {
    let data = {
      inputText,
      id: houseId,
      detailId: detailId,
      errorType: errorType,
      area: {
        ropani: ropaniInput || 0,
        aana: aanaInput || 0,
        paisa: paisaInput || 0
      }
    };
    dispatch(editHouseGeneric(data));
    handleClose();
  };

  let mapColumn = [
    {
      title: "सर्वेक्षण काेड",
      dataIndex: "dataCollectionCode",
      key: "dataCollectionCode",
      width: 200
    },
    {
      title: "घर नं",
      dataIndex: "houseNumber",
      key: "houseNumber",
      width: 150,
      render: getNepaliNumber,
      sorter: (a, b) => a.houseNumber - b.houseNumber
    },
    {
      title: "वडा नं",
      dataIndex: "wardNumber",
      key: "wardNumber",
      render: getNepaliNumber,
      width: 150
    },
    {
      title: "खाली छोडनुभएको",
      dataIndex: "errorType",
      key: "errorType",
      filters: config.map(each => ({ text: each, value: each })),
      onFilter: (value, record) => record.errorType.indexOf(value) === 0,
      width: 250
    },
    {
      title: "घरमुली संगको नाता",
      dataIndex: "key",
      key: "key",
      width: 250,
      render: (relation, record, index) =>
        editIndex === index ? (
          <OptionSelectionHouse
            handleChange={handleChange}
            options={record.errorType}
            handleUnitChange={handleUnitChange}
            handleAreaChange={handleAreaChange}
          />
        ) : (
          ""
        )
    }
  ];

  return (
    <div>
      <Typography variant="h4" component="h2" align="center">
        घर सर्वेक्षणमा खाली रहेको डाटा
      </Typography>
      <Grid container spacing={24}>
        {loading ? (
          // getLoading(5)
          <div style={{ display: "block", margin: "0 auto" }}>
            <Spin size="large" />
          </div>
        ) : (
          <Table
            style={{ width: "100%", margin: "0px 5px" }}
            dataSource={list}
            rowKey="uid"
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: true
              //   pageSizeOptions: ["10", "20", "30"]
            }}
          >
            <Column
              title={<span className="font-semibold">क्र.श</span>}
              dataIndex={"sn"}
              key={"sn"}
              render={(text, record, index) => <p>{index + 1}</p>}
            />

            {mapColumn.map(column => (
              <Column {...column} />
            ))}
            <Column
              title={<span className="font-semibold">क्रियाकलाप</span>}
              key="action"
              render={(each, record, index) => {
                return (
                  <span>
                    <Button
                      color="primary"
                      //   onClick={handleClickOpen(each.key, each.errorType)}
                      disabled={editIndex !== -1 && editIndex !== index}
                      onClick={
                        editIndex === 0
                          ? onSave
                          : handleClickOpen(
                              each.key,
                              index,
                              each.detailId,
                              each.errorType
                            )
                      }
                    >
                      {editIndex === 0 ? "सच्चाउनुहोस" : "edit"}
                    </Button>
                    {editIndex === index && (
                      <Button color="primary" onClick={handleClose}>
                        रद्द गर्नुहोस्
                      </Button>
                    )}
                  </span>
                );
              }}
            />
          </Table>
        )}
      </Grid>
      <ConfirmDialog
        open={open}
        onChange={onSave}
        onClose={handleClose}
        title="घरमुली संगको नाता"
        fullWidth={false}
      >
        <Typography variant="h5">Are you sure you want to edit</Typography>
      </ConfirmDialog>
    </div>
  );
}

function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
