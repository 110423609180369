import React, { Fragment } from "react";
import { GeoJSON, Marker, Popup } from "react-leaflet";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import L, { geoJSON } from "leaflet";
import { Grid } from "@material-ui/core/es";

import iconUrl from "../../../../../image/pin24.png";

var myIcon = L.icon({
  iconUrl,
  // iconSize: [25, 41],
  iconAnchor: [12, 24],
  iconSize: new L.Point(16, 16)
  // popupAnchor: [-10, -90]
});

export default function GeoJSONWithLayer(props) {
  console.log("props", props);
  // if (
  //   props.data &&
  //   props.data.features &&
  //   props.data.features.findIndex(each => each.geometry.type === "Point") !== -1
  // ) {
  //   return props.data.features.map(eachFeature => {
  //     // euta euta points aaune eta bata ho;
  //     const properties = eachFeature.properties;
  //     const eachCoordinates = eachFeature.geometry.coordinates;
  //     return (
  //       <Marker
  //         position={[props.geo.latitude, props.geo.longitude]}
  //         icon={myIcon}
  //       >
  //         <Popup>
  //           <p>heelo</p>
  //         </Popup>
  //       </Marker>
  //     );
  //   });
  // }
  console.log("geo", props.data);
  return (
    <Fragment>
      {props.data && <GeoJSON data={props.data.geoJson} />}
      {props.geo && (
        <Marker position={props.geo} icon={myIcon}>
          {/* <Popup>
          <p>heelo</p>
        </Popup> */}
        </Marker>
      )}
    </Fragment>
  );
}
