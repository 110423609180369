import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { selectedLanguage, yes, no } from "../../../../../variable/global";
import {
	QuestionAnswer,
	Title
} from "../../../../common/frequent/QuestionAnswer";
import getNepalNumber from "../../../../../utils/getNepaliNumber";
import {
	agricultureTitle,
	irrigation,
	mal,
	malType,
	waliType,
	wali,
	area,
	productionShow,
	sales,
	production,
	expense,
	agriInfo,
	yearlyInfo
} from "../../../../../variable/houseSurvey";

function mapValue(value, obj) {
	return obj[value] ? obj[value] : value;
}

class SearchByHouseNumber extends Component {
	getYesNo = data => (data ? yes[selectedLanguage] : no[selectedLanguage]);
	multimapValue = (name, object) =>
		name && name.map(each => object[each]).join(",");
	render() {
		const agriIncome = [
			"cropIncome",
			"daalIncome",
			"oilIncome",
			"vegitableIncome",
			"fruitIncome",
			"cashCropIncome",
			"flowerIncome"
		];
		const { classes, agriculture } = this.props;
		return (
			<Fragment>
				{agriculture && (
					<Grid item container direction="column">
						<Title title={agricultureTitle} />
						<Grid item container direction="row">
							<Grid item container xs={12}>
								<Grid
									item
									container
									direction="row"
									className={classes.eachQnAns}
								>
									<Grid item xs={8}>
										<QuestionAnswer
											question={agricultureTitle[selectedLanguage]}
											answer={this.getYesNo(
												agriculture.agricultureProductSales
											)}
										/>
									</Grid>
									{agriculture.irrigation && (
										<Grid item xs={4}>
											<QuestionAnswer
												question={irrigation[selectedLanguage]}
												answer={mapValue(agriculture.irrigation, {
													no_irrigation: "सिंचाई छैन",
													kulo: "कुलो",
													nahar: "नहर",
													boring: "बोरिंग",
													tubewell: "ट्युबवेल",
													lift_badh: "लिफ्टिंङ बाँध",
													pokhari: "पोखरी",
													tap_pipe: "धारा/पाईपबाट",
													rain_water: "वर्षाको पानि"
												})}
											/>
										</Grid>
									)}
								</Grid>
								<Grid
									item
									container
									direction="row"
									className={classes.eachQnAns}
								>
									<Grid item xs={8}>
										<QuestionAnswer
											question={mal[selectedLanguage]}
											answer={this.getYesNo(agriculture.insecticides)}
										/>
									</Grid>
									{agriculture && agriculture.insecticides && (
										<Grid item xs={4}>
											<QuestionAnswer
												question={malType[selectedLanguage]}
												answer={mapValue(agriculture.chemicalUsed, {
													organic: "स्थानीय अर्गानिक",
													chemical: "रासायनिक किट नासक औषधि",
													both_org_chem: "दुवै"
												})}
											/>
										</Grid>
									)}
								</Grid>
								<Grid item container>
									{agriculture.agricultureProductSales && (
										<Table className={classes.table}>
											<TableHead>
												<TableRow>
													<TableCell>{waliType[selectedLanguage]}</TableCell>
													<TableCell align="right">
														{wali[selectedLanguage]}
													</TableCell>
													
													<TableCell align="right">
														{productionShow[selectedLanguage]}
													</TableCell>
													<TableCell align="right">
														{sales[selectedLanguage]}
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{agriculture.agricultureDetail.map((row, key) => (
													<TableRow key={key}>
														<TableCell component="th" scope="row">
															{mapValue(row.agricultureProductionSalesType, {
																crops: "अन्न वालि",
																daal: "दाल वालि",
																oilseeds: "तेलहन उत्पादन",
																vegetable: "तरकारी उत्पादन",
																fruit: "फलफुल",
																cash_crops: "नगदेवाली",
																flower: "फुलखेती"
															})}
														</TableCell>
														<TableCell align="right">
															{mapValue(row.title, {
																crops: "धान",
																maize: "मकै",
																kodo: "कोदो",
																fapar: "फापर",
																ghau: "गहू/ जौ",
																black_daal: "कालो दाल",
																simi: "सिमि",
																musuri: "राजमा",
																chana: "मटर /केराउ",
																bhatmas: "भट्मास",
																tori: "तोरी/सर्स्यु",
																aalash: "आलस/तिल",
																filunge: "फिलुंगे",
																sunflower: "सिलाम",
																mango: "आप/लिची",
																banana: "केरा",
																orange: "सुन्तला/जुनार /कागती (सुन्तला जात )",
																apple: "स्याउ",
																kiwi: "किवी",
																avogado: "अभोगाड़ो",
																alaichi: "अलैची",
																rudraksh: "रुद्राक्ष(दाना)",
																amriso: "अम्रिसो",
																coffee: "कफी",
																tea: "चिया",
																ginger: "अदुवा/बेसार",
																garlic: "लसुन/प्याज",
																mushroom: "च्याउ",
																sugarcane: "उखु",
																nut: "बदाम",
																chilly: "खुर्सानी",
																potato: "आलु",
																cabbage: "बन्दा/काउली /बोडी",
																tomato: "गोलभेडा",
																cucumber: "काक्रो/लौका /मुला",
																bittergroud: "करेला/घिरौला/फर्सी",
																vejetable_raay: "रायो (साग सब्जी )",
															})}
														</TableCell>
														
														<TableCell align="right">
															{row &&
																row.production &&
																getNepalNumber(row.production.kg)}{" "}
															के.जि 
														</TableCell>
														<TableCell align="right">
															{row &&
																row.sale &&
																getNepalNumber(row.sale.kg)}{" "}
															के.जि
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									)}
								</Grid>
								<Grid item container>
									{agriculture.agricultureProductSales && (
										<Table className={classes.table}>
											<TableHead>
												<TableRow>
													<TableCell align="right">
														{waliType[selectedLanguage]}
													</TableCell>
													<TableCell align="right">
														{area[selectedLanguage]}
													</TableCell>
													<TableCell align="right">
														{production[selectedLanguage]}
													</TableCell>
													<TableCell align="right">
														{expense[selectedLanguage]}
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
											{agriculture.agricultureParent.map((row,key) => (
												<TableRow key={key}>
												<TableCell align="right">
													{mapValue(row.title, {
														crops: "अन्न वालि",
														daal: "दाल वालि",
														oilseeds: "तेलहन उत्पादन",
														vegitable:"तरकारी उत्पादन",
														fruit: "फलफुल",
														cash_crops: "नगदेवाली",
														flower: "फुलखेती"
													})}
												</TableCell>
												<TableCell align="right">
											{row &&
												row.area &&
												getNepalNumber(row.area.ropani)}{" "}
											रोपनी &nbsp;
											{row &&
												row.area &&
												getNepalNumber(row.area.aana)}{" "}
											आना &nbsp;
											{row &&
												row.area &&
												getNepalNumber(row.area.paisa)}{" "}
											पैसा
										</TableCell>
												<TableCell align="right">
													{row &&
														getNepalNumber(row.income)}
												</TableCell>
												<TableCell align="right">
													{row &&
														getNepalNumber(row.expense)}
												</TableCell>
											</TableRow>
											))}
											</TableBody>
										</Table>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				)}
			</Fragment>
		);
	}
}

const styles = theme => ({
	root: {
		width: "100%",
		marginTop: theme.spacing.unit * 3,
		overflowX: "auto"
	},
	table: {
		width: "auto"
	},
	container: {
		marginTop: theme.spacing.unit * 3,
		marginBottom: theme.spacing.unit * 2
	},
	eachQnAns: {
		marginTop: theme.spacing.unit * 1
	}
});

SearchByHouseNumber.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SearchByHouseNumber);
