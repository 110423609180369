import Axios from "axios";
import { startSnackBar } from "../../../../../../actions/utilAction";

export const GET_MEMBERNAME_LIST = "GET_MEMBERNAME_LIST";
export const SET_MEMBERNAME_LOADING = "SET_MEMBERNAME_LOADING";
export const EDIT_ERROR_MEMBERNAME = "EDIT_ERROR_MEMBERNAME";

export const getEmptyMemberName = () => dispatch => {
  dispatch({
    type: SET_MEMBERNAME_LOADING,
    payload: true
  });

  Axios.get("/api/errorCorrection/emptyRequiredField/memberName").then(
    response => {
      return dispatch({
        type: GET_MEMBERNAME_LIST,
        payload: response.data
      });
    }
  );
};

export function editMemberName(data) {
  return dispatch =>
    Axios.post("/api/errorCorrection/emptyRequiredField/memberNameUpdate", data)
      .then(response => {
        dispatch(getEmptyMemberName());

        dispatch(
          startSnackBar({
            open: true,
            variant: "success",
            // message: response.data.message
            message: "परिवार सदस्यको नाम/थर अद्यावधिक गरियो"
          })
        );
        return dispatch({
          type: EDIT_ERROR_MEMBERNAME,
          payload: response.data
        });
      })
      .catch(err => console.log(err));
}
