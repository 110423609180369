import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";
import ClipLoader from "react-spinners/ClipLoader";
// import FileViewer from "react-file-viewer";
// import { CustomErrorComponent } from "custom-error";
import Card from "../../common/Card/Card.jsx";
import CardBody from "../../common/Card/CardBody.jsx";
import CardHeader from "../../common/Card/CardHeader";
import GridContainer from "../../common/Grid/GridContainer.jsx";
import GridItem from "../../common/Grid/GridItem.jsx";
import FileList from "./FileList";
import withStyles from "@material-ui/core/styles/withStyles";
import FilePreview from "./FilePreview";
import Axios from "axios";
import PDFViewer from "./src";
import { Button } from "antd";
class Home extends Component {
  state = {
    preview: "",
    loading: true,
    files: "",
    reload: true
  };

  componentDidMount() {
    var preview = (
      <PDFViewer
        document={{
          url: "/api/villageReportTest/report"
        }}
      />
    );
    if (preview) this.setState({ preview: preview, loading: false });
  }

  render() {
    const { classes } = this.props;
    const { loading, preview } = this.state;
    return (
      <Grid container>
        <GridItem xs={12} sm={12} md={12}>
          {loading ? (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <ClipLoader
                  sizeUnit={"px"}
                  size={84}
                  color={"#00ACC1"}
                  loading={loading}
                />
              </Grid>
            </Grid>
          ) : (
            <FileList />
          )}
        </GridItem>
        <Grid item xs={12}>
          <FilePreview />
        </Grid>
        <Grid item xs={12} style={{ marginTop: 15 }}>
          <Button
            onClick={() => {
              this.setState({ reload: false });
              setTimeout(() => {
                this.setState({ reload: true });
              }, 100);
            }}
            type="primary"
            size={"default"}
            style={{ marginRight: 10 }}
          >
            Reload document
          </Button>
          {this.state.reload && preview}
        </Grid>
      </Grid>
    );
  }
  onError(e) {
    console.log(e, "error in file-viewer");
  }
}

const styles = theme => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3
  },
  table: {
    minWidth: 500
  },
  tableWrapper: {
    overflowX: "auto"
  },
  fab: {
    // float: "right"
  }
});

export default withStyles(styles)(Home);
