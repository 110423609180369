import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  birthCertificate,
  birthCertificateQuestion,
  childLaborQuestion,
  childLabour,
  childLabourQuestion,
  contraceptiveUse,
  contraceptiveUseQuestion,
  deliveryFromDoctor,
  deliveryFromDoctorQuestion,
  earlyMarriage,
  earlyMarriageQuestion,
  govHospitalName,
  govHospitalNameQuestion,
  illTreament,
  illTreamentQuestion,
  pregnanctCheck,
  pregnanctCheckQuestion,
  childDeath,
  childDeathQuestion,
  pregnantTetanus,
  pregnantTetanusQuestion,
  discriminationQuestion,
  discrimination,
  handWash,
  handWashOption,
  alcoholUsage,
  alcoholUsageOption,
  alcoholUsageCount,
  alcoholUsageCard,
  alcoholUsageCardOption,
  effectiveFromCard,
  effectiveFromCardOption,
  alcoholUsageExpense,
  problemFromAlcohol,
  problemFromAlcoholOption,
  deathFromAlcohol,
  deathFromAlcoholOption,
  deathCount,
  returnFromAbroad,
  returnFromAbroadOption,
  stayedYearAbroad
} from "../../../../variable/houseSurvey";
import TextField from "../../../common/frequent/TextField";
import RadioButton from "../../../common/frequent/RadioButton";
import BoxBorder from "../../../common/frequent/BoxBorder";
import Selection from "../../../common/frequent/Selection";
import CheckBox from "../../../common/frequent/CheckBox";
import MultipleSelect from "../../../common/frequent/MultipleSelect";

class WomenChild extends Component {
  handleChange = (name, value) => {
    console.log("WC", name, value);
    this.props.handleChange(`womenChild.${name}`, value);
  };
  render() {
    const { womenChild } = this.props;
    const errors = this.props.errors && this.props.errors.errors;
    return (
      <Grid container>
        <Grid container>
          <RadioButton
            handleChange={this.handleChange}
            isVisible
            question={birthCertificateQuestion}
            name="birthCertificate"
            radioLists={birthCertificate}
            value={womenChild.birthCertificate}
          />
        </Grid>
        <Grid container>
          <RadioButton
            handleChange={this.handleChange}
            isVisible
            question={contraceptiveUseQuestion}
            name="contraceptiveUse"
            radioLists={contraceptiveUse}
            value={womenChild.contraceptiveUse}
          />
        </Grid>
        <Grid container>
          <RadioButton
            handleChange={this.handleChange}
            isVisible
            question={deliveryFromDoctorQuestion}
            name="deliveryFromDoctor"
            radioLists={deliveryFromDoctor}
            value={womenChild.deliveryFromDoctor}
          />
        </Grid>
        <Grid container>
          <RadioButton
            handleChange={this.handleChange}
            isVisible
            question={childDeathQuestion}
            name="isChildDeathFromDisease"
            radioLists={childDeath}
            value={womenChild.isChildDeathFromDisease}
          />
        </Grid>
        <BoxBorder
          isVisible={
            womenChild.isChildDeathFromDisease &&
            womenChild.isChildDeathFromDisease === true
          }
        >
          <Grid container>
            <TextField
              error={errors && errors.childDeathCount}
              question={alcoholUsageCount}
              name="childDeathCount"
              type="number"
              placeholder=""
              value={womenChild.childDeathCount}
              isVisible
              handleChange={this.handleChange}
            />
          </Grid>
        </BoxBorder>
        <Grid container>
          <RadioButton
            handleChange={this.handleChange}
            isVisible
            question={pregnanctCheckQuestion}
            name="pregnanctCheck"
            radioLists={pregnanctCheck}
            value={womenChild.pregnanctCheck}
          />
        </Grid>
        <Grid container>
          <RadioButton
            handleChange={this.handleChange}
            isVisible
            question={childLaborQuestion}
            name="childLabor"
            radioLists={childLabour}
            value={womenChild.childLabor}
          />
        </Grid>{" "}
        <Grid container>
          <RadioButton
            handleChange={this.handleChange}
            isVisible
            question={childLabourQuestion}
            name="childLabour"
            radioLists={childLabour}
            value={womenChild.childLabour}
          />
        </Grid>
        <Grid container>
          <RadioButton
            handleChange={this.handleChange}
            isVisible
            question={earlyMarriageQuestion}
            name="earlyMarriage"
            radioLists={earlyMarriage}
            value={womenChild.earlyMarriage}
          />
        </Grid>
        <Grid container>
          <RadioButton
            handleChange={this.handleChange}
            isVisible
            question={pregnantTetanusQuestion}
            name="pregnantTetanus"
            radioLists={pregnantTetanus}
            value={womenChild.pregnantTetanus}
          />
        </Grid>
        <Grid container>
          <Selection
            question={illTreamentQuestion}
            name="illTreament"
            selectList={illTreament}
            value={womenChild.illTreament}
            isVisible
            handleChange={this.handleChange}
          />
        </Grid>
        <Grid container>
          <RadioButton
            handleChange={this.handleChange}
            isVisible={womenChild.illTreament === "public_hospita"}
            question={govHospitalNameQuestion}
            name="govHospitalName"
            radioLists={govHospitalName}
            value={womenChild.govHospitalName}
            other={womenChild.govHospitalNameOther}
          />
        </Grid>
        <Grid container>
          <RadioButton
            handleChange={this.handleChange}
            isVisible
            question={discriminationQuestion}
            name="discrimination"
            radioLists={discrimination}
            value={womenChild.discrimination}
          />
        </Grid>
        <Grid container>
          <RadioButton
            handleChange={this.handleChange}
            isVisible
            question={handWash}
            name="soapHandwash"
            radioLists={handWashOption}
            value={womenChild.soapHandwash}
          />
        </Grid>
        <Grid container>
          <RadioButton
            handleChange={this.handleChange}
            isVisible
            question={returnFromAbroad}
            name="returnFromAbroad"
            radioLists={returnFromAbroadOption}
            value={womenChild.returnFromAbroad}
          />
        </Grid>
        <Grid container>
          <TextField
            // error={errors && errors.houseCount}
            question={stayedYearAbroad}
            name="abroadReturnYear"
            type="number"
            placeholder=""
            value={womenChild.abroadReturnYear}
            isVisible={
              womenChild.returnFromAbroad &&
              womenChild.returnFromAbroad == "foreign_3_yes"
            }
            handleChange={this.handleChange}
          />
        </Grid>
      </Grid>
    );
  }
}
WomenChild.propTypes = {
  classes: PropTypes.object.isRequired
};

export default WomenChild;
