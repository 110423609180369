import Axios from "axios";
import { startSnackBar } from "../../../../../../actions/utilAction";

export const GET_ANSWERER_LIST = "GET_ANSWERER_LIST";
export const GET_ANSWERERNAME_LIST = "GET_ANSWERERNAME_LIST";
export const SET_ANSWERER_LOADING = "SET_ANSWERER_LOADING";
export const EDIT_ERROR_ANSWERER = "EDIT_ERROR_ANSWERER";

export const getEmptyAnswerer = () => dispatch => {
  dispatch({
    type: SET_ANSWERER_LOADING,
    payload: true
  });

  Axios.get("/api/errorCorrection/emptyRequiredField/answerer").then(
    response => {
      return dispatch({
        type: GET_ANSWERER_LIST,
        payload: response.data
      });
    }
  );
};

export function editAnswerer(data) {
  return dispatch => {
    Axios.post("/api/errorCorrection/emptyRequiredField/answererUpdate", data)
      .then(response => {
        dispatch(getEmptyAnswerer());

        dispatch(
          startSnackBar({
            open: true,
            variant: "success",
            // message: response.data.message
            message: "उत्तरदाताको नाम सफलतापूर्वक अद्यावधिक गरियो"
          })
        );
        return dispatch({
          type: EDIT_ERROR_ANSWERER,
          payload: response.data
        });
      })
      .catch(err => console.log(err));
  };
}

export const getAnswererList = houseId => dispatch => {
  // dispatch({
  //   type: SET_ANSWERER_LOADING,
  //   payload: true
  // });

  Axios.get(
    `/api/errorCorrection/emptyRequiredField/familyMember/${houseId}`
  ).then(response => {
    return dispatch({
      type: GET_ANSWERERNAME_LIST,
      payload: response.data
    });
  });
};
