import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "./TabPanel";
import EmptyRequiredField from "./emptyRequiredField/";
import DataEntryError from "./dataEntryError/";
import LogicalInvalid from "./logicInvalid/";
import Duplicacy from "./duplicacy/";

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
});

export default function CenteredTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Duplicacy" />
        <Tab label="Empty Required Field" />
        <Tab label="Logically Invalid" />
        <Tab label="Data Entry Error" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Duplicacy />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EmptyRequiredField />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <LogicalInvalid />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <DataEntryError />
      </TabPanel>
    </Paper>
  );
}
