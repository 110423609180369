import Club from "./Club";
import Institute from "./Institute";
import School from "./School";
import TourismPlace from "./TourismPlace";
import ProdEducation from "./ProdEducation";
import College from "./College";
import Health from "./Health";
import HealthPost from "./HealthPost";
import GrazingField from "./GrazingField";
import Forest from "./Forest";
import Nikunja from "./Nikunja";
import AnimalFarm from "./AnimalFarm";
import Animal from "./Animal";
import Police from "./Police";
import Temple from "./Temple";
import Bank from "./Bank";
import CommunityBuilding from "./CommunityBuilding";
import Jadibuti from "./Jadibuti";
import RoadNetwork from "./RoadNetwork";
import CableCar from "./CableCar";
import Park from "./Park";
import Tuin from "./Tuin";
import Bridge from "./Bridge";
import MainRiver from "./MainRiver";
import Electricity from "./Electricity";
import Tower from "./Tower";
import PublicToilet from "./PublicToilet";
import FreeWifi from "./FreeWifi";
import Mines from "./Mines";
import TransmissionLine from "./TransmissionLine";
import Quarter from "./Quarter";
import FmStation from "./FmStation";
import Newspaper from "./Newspaper";
import CableNetwork from "./CableNetwork";
import BusTerminal from "./BusTerminal";
import ServiceCenter from "./ServiceCenter";
import Industry from "./Industry";
import MarketPlace from "./MarketPlace";
import Ground from "./Ground";
import NaturalDisaster from "./NaturalDisaster";
import DisasterInformation from "./DisasterInformation";
import Hotels from "./Hotels";
import Festival from "./Festival";
import VillageCouncilFamily from "./VillageCouncilFamily";
import VillageCouncilStaff from "./VillageCouncilStaff";
import TechnicalEducation from "./TechnicalEducation";

import Business from "@material-ui/icons/Business";
import Group from "@material-ui/icons/Group";
import SchoolIcon from "@material-ui/icons/School";
import Place from "@material-ui/icons/Place";
import Star from "@material-ui/icons/Star";

// import Item from "./Item";
// import Project from "./Project";

export const ngo = {
  nepali: "सरकारी संस्था / गैर सरकारी संस्था",
  english: "",
  route: "institution",
  Component: Institute,
  count: 2
};
export const police = {
  nepali: "प्रहरी चौकी",
  english: "",
  route: "police",
  count: 2,
  Component: Police
};
export const bank = {
  nepali: "बैंक र वित्तीय संस्था",
  english: "",
  route: "bank",
  count: 2,
  Component: Bank
};
export const communityHall = {
  nepali: "सामुदायिक भवन",
  english: "",
  route: "communityBuilding",
  count: 2,
  Component: CommunityBuilding
};
export const quarter = {
  nepali: "सरकारी कार्यालय तथा कर्मचारीहरुको सरकारी बासस्थान",
  english: "",
  route: "quarter",
  count: 2,
  Component: Quarter
};
export const club = {
  nepali: "क्लबहरु",
  english: "",
  route: "club",
  count: 2,
  Component: Club
};

export const school = {
  nepali: "स्कुल",
  english: "",
  route: "school",
  count: 2,
  Component: School
};
export const college = {
  nepali: "कलेज",
  english: "",
  route: "college",
  count: 2,
  Component: College
};
export const technicalEducation = {
  nepali: "प्राविधिक शिक्षा",
  english: "",
  route: "technicalEducation",
  count: 2,
  Component: TechnicalEducation
};

// export const trainningInstitute = {
// 	nepali: "तालिम केन्द्र",
// english: "",
// 	route: "trainningInstitute",
// 	count: 2,
// 	Component: TrainningInstitute
// };

// export const prodEducation = {
// 	nepali: "प्रोड्शिक्षा",
// english: "",
// 	route: "prodEducation",
// 	count: 2,
// 	Component: ProdEducation
// };

export const touristPlace = {
  nepali: "पर्यटकीय क्षेत्र",
  english: "",
  route: "tourismPlace",
  count: 2,
  Component: TourismPlace
};

export const park = {
  nepali: "पार्क",
  english: "",
  route: "park",
  count: 2,
  Component: Park
};

export const hotel = {
  nepali: "होटल लज",
  english: "",
  route: "hotel",
  count: 2,
  Component: Hotels
};

export const mine = {
  nepali: "खानी",
  english: "",
  route: "mine",
  count: 2,
  Component: Mines
};
export const forest = {
  nepali: "वनक्षेत्र",
  english: "",
  route: "forest",
  count: 2,
  Component: Forest
};
export const nikunja = {
  nepali: "राष्ट्रिय निकुञ्ज",
  english: "",
  route: "nikunja",
  count: 2,
  Component: Nikunja
};
export const bridge = {
  nepali: "पुल",
  english: "",
  route: "bridge",
  count: 2,
  Component: Bridge
};
export const ground = {
  nepali: "मैदान",
  english: "",
  route: "ground",
  count: 2,
  Component: Ground
};
export const market = {
  nepali: "हाट बजार मार्केट",
  english: "",
  route: "market",
  count: 2,
  Component: MarketPlace
};
export const flora = {
  nepali: "जडीबुटी तथा वनस्पती",
  english: "",
  route: "herbs",
  count: 2,
  Component: Jadibuti
};
export const cableCar = {
  nepali: "केबलकार",
  english: "",
  route: "cableCar",
  count: 2,
  Component: CableCar
};
export const tuin = {
  nepali: "तुइन भएको खोला/नदी",
  english: "",
  route: "tuin",
  count: 2,
  Component: Tuin
};
export const mainRiver = {
  nepali: "प्रमुख खोला/नदी",
  english: "",
  route: "mainRiver",
  count: 2,
  Component: MainRiver
};

export const communication = {
  nepali: "संचार टावर",
  english: "",
  route: "tower",
  count: 2,
  Component: Tower
};

export const fm = {
  nepali: "एफ.एम",
  english: "",
  route: "fmStation",
  count: 2,
  Component: FmStation
};

export const newspaper = {
  nepali: "पत्र पत्रिका",
  english: "",
  route: "newspaper",
  count: 2,
  Component: Newspaper
};
export const cableNetwork = {
  nepali: "केवल नेटवर्क",
  english: "",
  route: "cableNetwork",
  count: 2,
  Component: CableNetwork
};
export const temple = {
  nepali: "मठ मन्दिर",
  english: "",
  route: "temple",
  count: 2,
  Component: Temple
};
export const mainBusiness = {
  nepali: "उद्योग तथा कलकारखाना",
  english: "",
  route: "mainBusiness",
  count: 2,
  Component: Industry
};
export const festival = {
  nepali: "प्रमुख मेला, चाडपर्व तथा जात्राहरु",
  english: "",
  route: "festival",
  count: 2,
  Component: Festival
};

export const busPark = {
  nepali: "मुख्य बस टर्मिनल",
  english: "",
  route: "busPark",
  count: 2,
  Component: BusTerminal
};
export const publicToilet = {
  nepali: "सार्वजनिक शौचालय",
  english: "",
  route: "publicToilet",
  count: 2,
  Component: PublicToilet
};
export const roadNetwork = {
  nepali: "सडक सञ्जाल",
  english: "",
  route: "roadNetwork",
  count: 2,
  Component: RoadNetwork
};
export const freeWifi = {
  nepali: "निशुल्क वाइफाइ उपलब्ध भएका स्थान",
  english: "",
  route: "freeWifi",
  count: 2,
  Component: FreeWifi
};

export const hospital = {
  nepali: "अस्पताल",
  english: "",
  route: "hospital",
  count: 2,
  Component: Health
};
export const healthPost = {
  nepali: "स्वास्थ्य चौकी",
  english: "",
  route: "healthPost",
  count: 2,
  Component: HealthPost
};
export const animalVeterinary = {
  nepali: "पशु (भेटेनरी)/उपचार केन्द्र",
  english: "",
  route: "animalVeterinary",
  count: 2,
  Component: Health
};
export const disasterInformation = {
  nepali: "विपद घटनाहरुमा क्षति (विगत ३ वर्ष)",
  english: "",
  route: "disasterInformation",
  count: 2,
  Component: DisasterInformation
};
export const naturalDisaster = {
  nepali: "वाढी, पहिरोको जोखिम क्षेत्र",
  english: "",
  route: "naturalDisaster",
  count: 2,
  Component: NaturalDisaster
};
export const animal = {
  nepali: "पशुपन्छी",
  english: "",
  route: "animal",
  count: 2,
  Component: Animal
};
export const grazingField = {
  nepali: "चरण क्षेत्र",
  english: "",
  route: "grazingField",
  count: 2,
  Component: GrazingField
};
export const animalFarm = {
  nepali: "कृषि तथा पशु फार्म",
  english: "",
  route: "animalFarm",
  count: 2,
  Component: AnimalFarm
};
export const serviceCenter = {
  nepali: "मुख्य मेकानिकल वर्कसप",
  english: "",
  route: "serviceCenter",
  count: 2,
  Component: ServiceCenter
};
export const villageCouncilFamily = {
  nepali: "परिवारको विवरण",
  english: "",
  route: "rmFamily",
  count: 2,
  Component: VillageCouncilFamily
};
export const villageCounilStaff = {
  nepali: "कर्मचारीहरुको विवरण",
  english: "",
  route: "rmStaff",
  count: 2,
  Component: VillageCouncilStaff
};
export const powerHouse = {
  nepali: "जलविद्युत योजना",
  english: "",
  route: "electricity",
  count: 2,
  Component: Electricity
};
export const transmissionLine = {
  nepali: "विद्युतीय ट्रान्समिसन लाईन",
  english: "",
  route: "transmissionLine",
  count: 2,
  Component: TransmissionLine
};

export const instituteRoute = [
  {
    title: {
      english: "Institute",
      nepali: "संस्था"
    },
    child: [ngo, police, bank, communityHall, quarter],
    Icon: Business
  },
  {
    title: { english: "Club", nepali: "क्लब समूह" },
    child: [club],
    Icon: Group
  },
  {
    title: { english: "Education", nepali: "शिक्षा" },
    child: [
      school,
      college,
      technicalEducation
      // trainningInstitute,
      // prodEducation
    ],
    Icon: SchoolIcon
  },
  {
    title: { english: "Tourism Place", nepali: "पर्यटन सम्बन्धी" },
    child: [touristPlace, park, hotel],
    Icon: Place
  },
  {
    title: { english: "Resources", nepali: "स्रोत" },
    child: [
      mine,
      forest,
      nikunja,
      bridge,
      ground,
      market,
      flora,
      cableCar,
      tuin,
      mainRiver
    ],
    Icon: Star
  },
  {
    title: { english: "Item", nepali: "संचार" },
    child: [communication, fm, newspaper, cableNetwork],
    Icon: Star
  },
  {
    title: { english: "Religious Place", nepali: "धार्मिक स्थल" },
    child: [temple],
    Icon: Star
  },
  {
    title: { english: "Business", nepali: "व्यापार तथा व्यवसाय सम्बन्धी" },
    child: [mainBusiness],
    Icon: Star
  },
  {
    title: { english: "Festival", nepali: "चाड पर्व" },
    child: [festival],
    Icon: Star
  },
  {
    title: { english: "Public Place", nepali: "सार्वजनिक स्थल" },
    child: [busPark, publicToilet, roadNetwork, freeWifi],
    Icon: Star
  },
  {
    title: { english: "Health Related", nepali: "स्वास्थ्य सम्बन्धी" },
    child: [hospital, healthPost, animalVeterinary],
    Icon: Star
  },
  {
    title: { english: "Natural Disaster", nepali: "प्राकृतिक प्रकोप" },
    child: [disasterInformation, naturalDisaster],
    Icon: Star
  },
  {
    title: { english: "Animal Livestock", nepali: "पशुपन्छी तथा चरण क्षेत्र" },
    child: [animal, grazingField, animalFarm],
    Icon: Star
  },
  {
    title: { english: "Others", nepali: "अन्य" },
    child: [serviceCenter],
    Icon: Star
  },
  {
    title: {
      english: "Project",
      nepali: "गाँउपालिका/नगरपालिका परिवार तथा कर्मचारीहरुको विवरण"
    },
    child: [villageCouncilFamily, villageCounilStaff],
    Icon: Star
  },
  {
    title: { english: "Electicity", nepali: "विधुत" },
    child: [powerHouse, transmissionLine],
    Icon: Star
  }
]; //survey ko card display garauda
