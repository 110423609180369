import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from "@material-ui/core";
import PropTypes from "prop-types";

export default function confirmDialog({
  onClose,
  open,
  children,
  title = "Edit Data",
  onChange,
  flag, // for not using button from this function
  fullWidth
}) {
  const handleClose = () => {
    onClose();
  };

  const handleChange = () => {
    onChange();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={fullWidth}
    >
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      {flag && (
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            रद्द गर्नुहोस्
          </Button>
          <Button onClick={handleChange} color="primary" autoFocus>
            बुझाउनुहोस्
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

confirmDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

confirmDialog.defaultProps = {
  flag: true
};
