import Axios from "axios";
import { startSnackBar } from "../../../../../../actions/utilAction";

export const GET_ANSWERERRELATION_LIST = "GET_ANSWERERRELATION_LIST";
export const SET_ANSWERERRELATION_LOADING = "SET_ANSWERERRELATION_LOADING";
export const EDIT_ERROR_RELATION = "EDIT_ERROR_RELATION";

export const getEmptyAnswererRelation = () => dispatch => {
  dispatch({
    type: SET_ANSWERERRELATION_LOADING,
    payload: true
  });

  Axios.get("/api/errorCorrection/emptyRequiredField/answererRelation").then(
    response => {
      return dispatch({
        type: GET_ANSWERERRELATION_LIST,
        payload: response.data
      });
    }
  );
};

export function editAnswererRelation(data) {
  return dispatch =>
    Axios.post(
      "/api/errorCorrection/emptyRequiredField/answererRelationUpdate",
      data
    )
      .then(response => {
        dispatch(getEmptyAnswererRelation());

        dispatch(
          startSnackBar({
            open: true,
            variant: "success",
            // message: response.data.message
            message: "घरमुली संगको नाता सफलतापूर्वक अद्यावधिक गरियो"
          })
        );
        return dispatch({
          type: EDIT_ERROR_RELATION,
          payload: response.data
        });
      })
      .catch(err => console.log(err));
}
