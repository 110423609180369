import React, { Component } from "react";
import L from "leaflet";
import {
  Map,
  TileLayer,
  Marker,
  Popup,
  LayersControl,
  GeoJSON
} from "react-leaflet";
import GeoJsonLayer from "./GeoJSONWithLayer";
import iconUrl from "../../../image/pin24.png";
import { getHouseByHouseNumber } from "../../../actions/searchAction";
import GetHouseDetail from "../search/advancedHouseSearch/houseNumber/GetHouseDetail";
import { connect } from "react-redux";
import FullScreenDialog from "../../common/frequent/dialog/FullScreen";
import { Grid } from "@material-ui/core";
import ClipLoader from "react-spinners/ClipLoader";

const { BaseLayer, Overlay } = LayersControl;

var myIcon = L.icon({
  iconUrl,
  iconAnchor: [22, 94],
  popupAnchor: [-10, -90]
});

class Maps extends Component {
  state = {
    location: {
      lat: 27.132632571720116,
      lng: 87.78779983520508
    },
    haveUserLocation: false,
    zoom: 12,
    baseLayers: {
      "No Basemap": "Empty",
      Streets: "streets",
      Satellite: "satellite",
      Grayscale: "grayscale"
    },
    houseOpen: false,
    house: {}
  };

  componentDidUpdate(prevProps) {
    if (prevProps.house !== this.props.house) {
      this.setState({
        house: this.props.house
      });
    }
  }

  handleCloseHouse = name => {
    this.setState({ [name]: false, house: {} });
  };

  MouseOverFeature = (feature, layer, name) => {
    layer.bindPopup(name);
  };

  MouseOutFeature = (feature, layer) => {
    layer.unbindPopup();
  };

  onEachFeature = name => (feature, layer) => {
    return layer.on({
      mouseover: e => this.MouseOverFeature(feature, layer, name),
      mouseout: e => this.MouseOutFeature(feature, layer)
    });
    return layer.bindPopup(name);
    if (feature.properties && feature.properties.name) {
      layer.bindPopup(feature.properties.name);
    } else layer.bindPopup(name);
  };

  onEachFeatures = (feature, map) => {
    return map.on({
      mouseover: e =>
        window.setTimeout(
          map.scrollWheelZoom.enable.bind(map.scrollWheelZoom),
          2000
        ),
      mouseout: e => map.scrollWheelZoom.disable.bind(map.scrollWheelZoom)
    });
  };

  onHouseNumberClick = houseNumber => {
    console.log("houseNo", houseNumber);
    this.setState({ houseOpen: true, houseNumber }, () => {
      this.props.getHouseByHouseNumber(houseNumber);
    });
  };

  render() {
    // const position = [this.state.location.lat, this.state.location.lng];
    const {
      geoJsonDataWard,
      geoJsonDataInfastructure,
      position,
      showGeoJSON
    } = this.props;
    return (
      <div className="map" style={{ width: "100%" }}>
        <Map
          id="map"
          className="map"
          center={position}
          zoom={this.state.zoom}
          // zoomControl={false}
          // onEachFeature={this.onEachFeatures}
          scrollWheelZoom={false}
        >
          <LayersControl position="bottomright">
            <BaseLayer checked name="OpenStreetMap.Mapnik">
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </BaseLayer>
            <BaseLayer name="OpenStreetMap.BlackAndWhite">
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
              />
            </BaseLayer>
          </LayersControl>

          {showGeoJSON.length > 0 &&
            showGeoJSON.map(each => {
              return (
                each.length > 0 &&
                each.map(eachGeoJSON => {
                  console.log("eachGeoJSON", eachGeoJSON);
                  // if(eachGeoJSON.type == 'house'){
                  // 	const maps = L.map("map", {
                  // 		center: [27.0937722, 86.6203392],
                  // 		zoom: 6
                  // 	  });
                  // 	const houses = eachGeoJSON.geoJson.features;
                  // 	houses.map(each =>{
                  // 		L.geoJSON(each, {
                  // 			pointToLayer: (feature, latlng) => {
                  // 			  return L.marker(latlng, { icon: iconUrl });
                  // 			}
                  // 		  }).addTo(maps);
                  // 	})
                  // }
                  if (eachGeoJSON)
                    return (
                      <GeoJsonLayer
                        type={eachGeoJSON.type}
                        key={eachGeoJSON.name}
                        style={eachGeoJSON.style}
                        data={eachGeoJSON.geoJson}
                        popupContent={eachGeoJSON.name}
                        houseClick={this.onHouseNumberClick}
                      />
                    );
                })
              );
            })}
          {geoJsonDataInfastructure.length > 0 &&
            geoJsonDataInfastructure.map(each => {
              return (
                <GeoJsonLayer
                  type={each.type}
                  key={each.name}
                  style={each.style}
                  data={each.geoJson}
                  popupContent={each.name}
                />
              );
            })}
          {/* <Overlay name="Marker with popup">
					<Marker position={position} icon={myIcon}>
						<Popup>
							A pretty CSS3 popup. <br /> Easily customizable.
						</Popup>
					</Marker>
					</Overlay> */}
        </Map>
        {this.state.houseOpen && (
          <FullScreenDialog
            name="houseOpen"
            open={this.state.houseOpen}
            title={`House Detail `}
            handleClose={this.handleCloseHouse}
          >
            <Grid container spacing={24}>
              {this.props.load ? (
                <Grid
                  style={{ marginTop: "40vh" }}
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <ClipLoader
                      sizeUnit={"px"}
                      size={84}
                      color={"#00ACC1"}
                      loading={this.props.load}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-start"
                >
                  {this.state.house && (
                    <Grid item xs={9}>
                      <GetHouseDetail
                        hideImage={true}
                        houseNumber={
                          this.state.house &&
                          this.state.house.houseDetails &&
                          this.state.house.houseDetails.houseNumber
                        }
                        house={this.state.house}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </FullScreenDialog>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ search: { house, load } }) => ({
  house,
  load
});
export default connect(mapStateToProps, { getHouseByHouseNumber })(Maps);
// export default Maps;
