import Axios from "axios";
import { startSnackBar } from "../../../../../../actions/utilAction";

export const GET_OLDVDC_LIST = "GET_OLDVDC_LIST";
export const SET_OLDVDC_LOADING = "SET_OLDVDC_LOADING";
export const EDIT_ERROR_OLDVDC = "EDIT_ERROR_OLDVDC";

export const getEmptyOldVdc = () => dispatch => {
  dispatch({
    type: SET_OLDVDC_LOADING,
    payload: true
  });

  Axios.get("/api/errorCorrection/emptyRequiredField/houseOldVdc").then(
    response =>
      dispatch({
        type: GET_OLDVDC_LIST,
        payload: response.data
      })
  );
};

export function editOldVdc(data) {
  return dispatch => {
    Axios.post(
      "/api/errorCorrection/emptyRequiredField/houseOldVdcUpdate",
      data
    )
      .then(response => {
        dispatch(getEmptyOldVdc());

        dispatch(
          startSnackBar({
            open: true,
            variant: "success",
            // message: response.data.message
            message: "साविक गा.वि.स सफलतापूर्वक अद्यावधिक गरियो"
          })
        );
        dispatch({
          type: EDIT_ERROR_OLDVDC,
          payload: response.data
        });
      })
      .catch(err => console.log(err));
  };
}
