import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  building,
  buildingQuestion,
  doHouseCriteriaFullfill,
  doHouseCriteriaFullfillQuestion,
  familyAnotherHouseQuestion,
  familyMemberAnotherHouse,
  familyMemberAnotherHouseLocation,
  hasHouseMapPass,
  hasHouseMapPassQuestion,
  hasLandDocument,
  hasLandDocumentQuestion,
  houseAge,
  houseCount,
  houseType,
  houseTypeQuestion,
  isHouseEarthquakeResistance,
  isHouseEarthquakeResistanceQuestion,
  isHouseOnRent,
  isHouseOnRentQuestion,
  landUsedLiving,
  landUsedLivingQuestion,
  matanCount,
  rentGivenTo,
  rentGivenToQuestion,
  rentType,
  rentTypeQuestion,
  roofType,
  roofTypeQuestion,
  room,
  storey,
  totalRoom,
  totalStorey,
  houseUsedLiving,
  houseUsedLivingOption
} from "../../../../variable/houseSurvey";
import Box from "../../../common/frequent/Box";
import BoxBorder from "../../../common/frequent/BoxBorder";
import CheckBox from "../../../common/frequent/CheckBox";
import MultipleSelect from "../../../common/frequent/MultipleSelect";
import RadioButton from "../../../common/frequent/RadioButton";
import TextField from "../../../common/frequent/TextField";

class HouseDetail extends Component {
  handleChange = (name, value) => {
    this.props.handleChange(`houseDetail.${name}`, value);
  };
  render() {
    const { houseDetail } = this.props;
    const errors = this.props.errors && this.props.errors.errors;
    console.log("errors houseDetail", errors);
    return (
      <Grid container direction="column">
        {/* <Grid container>
					<CheckBox
						question={buildingQuestion}
						isVisible
						checkList={building}
						value={houseDetail.building}
						name="building"
						handleCompChange={this.handleChange}
					/>
				</Grid> */}

        <Grid container>
          <TextField
            error={errors && errors.houseCount}
            question={houseCount}
            name="houseCount"
            type="number"
            placeholder="कति वटा घर"
            value={houseDetail.houseCount}
            isVisible
            handleChange={this.handleChange}
          />
        </Grid>

        {/* <BoxBorder
					isVisible={
						houseDetail.building &&
						(houseDetail.building[0] == "ghar" ||
							houseDetail.building[1] == "ghar")
					}
				> */}
        <Grid container>
          <RadioButton
            error={errors && errors.familyMemberAnotherHouse}
            handleChange={this.handleChange}
            isVisible
            question={familyAnotherHouseQuestion}
            name="familyMemberAnotherHouse"
            radioLists={familyMemberAnotherHouse}
            value={houseDetail.familyMemberAnotherHouse}
          />
        </Grid>

        <Grid container>
          <TextField
            question={familyMemberAnotherHouseLocation}
            name="familyMemberAnotherHouseLocation"
            placeholder="छ भने कहाँ"
            value={houseDetail.familyMemberAnotherHouseLocation}
            isVisible={houseDetail.familyMemberAnotherHouse == "house_yes"}
            handleChange={this.handleChange}
          />
        </Grid>

        <Grid container>
          <TextField
            error={errors && errors.totalRoom}
            question={totalRoom}
            name="totalRoom"
            placeholder="घरमा कति वटा कोठा छ"
            type="number"
            value={houseDetail.totalRoom}
            isVisible
            handleChange={this.handleChange}
          />
        </Grid>

        <Grid container>
          <TextField
            error={errors && errors.totalStorey}
            question={totalStorey}
            name="totalStorey"
            type="number"
            placeholder="घरमा कति वटा तल्लाको"
            value={houseDetail.totalStorey}
            isVisible
            handleChange={this.handleChange}
          />
        </Grid>

        <Grid container>
          <RadioButton
            handleChange={this.handleChange}
            isVisible
            question={isHouseOnRentQuestion}
            name="isHouseOnRent"
            radioLists={isHouseOnRent}
            value={houseDetail.isHouseOnRent}
          />
        </Grid>

        <Grid container>
          <MultipleSelect
            question={rentGivenToQuestion}
            name="rentType"
            value={houseDetail.rentType}
            tagLists={rentGivenTo}
            isVisible={houseDetail.isHouseOnRent == "rent"}
            handleChange={this.handleChange}
            other={houseDetail.rentTypeOther}
          />
        </Grid>

        <Grid container>
          <RadioButton
            handleChange={this.handleChange}
            isVisible
            question={houseUsedLiving}
            name="HouseUsedLiving"
            radioLists={houseUsedLivingOption}
            value={houseDetail.HouseUsedLiving}
            other={houseDetail.HouseUsedLivingOther}
          />
        </Grid>

        {/* <Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={landUsedLivingQuestion}
						name="landUsedLiving"
						radioLists={landUsedLiving}
						value={houseDetail.landUsedLiving}
						other={houseDetail.landUsedLivingOther}
					/>
				</Grid> */}

        <Grid container>
          <RadioButton
            handleChange={this.handleChange}
            isVisible
            question={houseTypeQuestion}
            name="houseType"
            radioLists={houseType}
            value={houseDetail.houseType}
            other={houseDetail.houseTypeOther}
          />
        </Grid>

        <Grid container>
          <RadioButton
            handleChange={this.handleChange}
            isVisible
            question={roofTypeQuestion}
            name="roofType"
            radioLists={roofType}
            value={houseDetail.roofType}
            other={houseDetail.roofTypeOther}
          />
        </Grid>

        <Grid container>
          <RadioButton
            handleChange={this.handleChange}
            isVisible
            question={hasLandDocumentQuestion}
            name="hasLandDocument"
            radioLists={hasLandDocument}
            value={houseDetail.hasLandDocument}
          />
        </Grid>

        <Grid container>
          <RadioButton
            handleChange={this.handleChange}
            isVisible
            question={doHouseCriteriaFullfillQuestion}
            name="doHouseCriteriaFullfill"
            radioLists={doHouseCriteriaFullfill}
            value={houseDetail.doHouseCriteriaFullfill}
          />
        </Grid>

        <Grid container>
          <RadioButton
            handleChange={this.handleChange}
            isVisible
            question={isHouseEarthquakeResistanceQuestion}
            name="isHouseEarthquakeResistance"
            radioLists={isHouseEarthquakeResistance}
            value={houseDetail.isHouseEarthquakeResistance}
          />
        </Grid>

        <Grid container>
          <RadioButton
            handleChange={this.handleChange}
            isVisible
            question={hasHouseMapPassQuestion}
            name="hasHouseMapPass"
            radioLists={hasHouseMapPass}
            value={houseDetail.hasHouseMapPass}
          />
        </Grid>
        {/* </BoxBorder> */}

        {/* <Box
					isVisible={
						houseDetail.building &&
						(houseDetail.building[1] == "matan" ||
							houseDetail.building[0] == "matan")
					}
				>
					<Grid container>
						<TextField
							error={errors && errors.matanCount}
							question={matanCount}
							name="matanCount"
							type="number"
							isVisible
							value={houseDetail.matanCount}
							handleChange={this.handleChange}
						/>
					</Grid>
				</Box> */}
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing.unit * 3
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
    display: "flex"
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing.unit / 4
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3
  },
  flexColumn: {
    flexDirection: "column"
  }
});

HouseDetail.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(HouseDetail);
