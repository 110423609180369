import React from "react";
import DateOfBirth from "./DateOfBirth";
import HouseNumber from "./HouseNumber";
import HouseOldVdc from "./HouseOldVdc";
import AnswererName from "./AnswererName";
import MemberName from "./MemberName";
import AnswererRelation from "./AnswererRelation";
import HouseOwner from "./HouseOwner";
import FamilyGeneric from "./FamilyGeneric";
import HouseGeneric from "./HouseGeneric";

export default function index() {
  return (
    <div>
      <FamilyGeneric />
      <HouseGeneric />
      <AnswererName />
      <HouseOldVdc />
      <DateOfBirth />
      <HouseNumber />
      <MemberName />
      <AnswererRelation />
      <HouseOwner />
    </div>
  );
}
