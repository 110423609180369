import React, { useEffect, useState } from "react";
import { Table, Spin, Input, Select } from "antd";
import { Typography, Button, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmptyAnswererRelation,
  editAnswererRelation
} from "./store/actions/answererRelation.actions";
import ConfirmDialog from "../common/confirmDialog";
// import { SnackBar } from "../../../common/Snackbar/Snackbar";
import Axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { selectedLanguage } from "../../../../variable/global";
import { answererRelation } from "../../../../variable/houseSurvey";

const { Column } = Table;
const { Option } = Select;

export default function AnswererRelation(props) {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(
    ({ dataCorrection }) => dataCorrection.emptyField.answererRelation
  );
  const [data, setData] = useState(list);
  const [open, setOpen] = React.useState(false);
  const [relation, setAnswererRelation] = useState("");
  const [memberId, setMemberId] = useState("");

  const handleClickOpen = id => () => {
    setMemberId(id);
    setOpen(true);
  };

  const handleClose = value => {
    setAnswererRelation("");
    setMemberId("");
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getEmptyAnswererRelation());
  }, [dispatch]);

  useEffect(() => {
    setData(list);
  });

  const handleChange = value => {
    setAnswererRelation(value);
  };

  const onSave = () => {
    let data = {
      answererRelation: relation,
      memberId: memberId
    };
    dispatch(editAnswererRelation(data));
    handleClose();
  };

  let mapColumn = [
    {
      title: "सर्वेक्षण काेड",
      dataIndex: "dataCollectionCode",
      key: "dataCollectionCode",
      width: 200
    },
    {
      title: "घर नं",
      dataIndex: "houseNumber",
      key: "houseNumber",
      width: 200
    },
    {
      title: "वडा नं",
      dataIndex: "wardNumber",
      key: "wardNumber",
      width: 200
    },
    {
      title: "नाम/थर",
      dataIndex: "fullName",
      key: "fullName",
      width: 200
    },
    {
      title: "लिङ्ग",
      dataIndex: "gender",
      key: "gender",
      width: 200
    },
    {
      title: "घरमुली संगको नाता",
      dataIndex: "answererRelation",
      key: "answererRelation",
      width: 200,
      render: relation => (
        <Select defaultValue="-------" onChange={handleChange}>
          {answererRelation.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      )
    }
  ];

  return (
    <div>
      <Typography variant="h4" component="h2" align="center">
        घरमुली संगको नाता
      </Typography>
      <Grid container spacing={24}>
        {loading ? (
          // getLoading(5)
          <div style={{ display: "block", margin: "0 auto" }}>
            <Spin size="large" />
          </div>
        ) : (
          <Table
            style={{ width: "100%", margin: "0px 5px" }}
            dataSource={data.data}
            rowKey="uid"
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: true
              //   pageSizeOptions: ["10", "20", "30"]
            }}
          >
            <Column
              title={<span className="font-semibold">क्र.श</span>}
              dataIndex={"sn"}
              key={"sn"}
              render={(text, record, index) => <p>{index + 1}</p>}
            />

            {mapColumn.map(column => (
              <Column {...column} />
            ))}
            <Column
              title={<span className="font-semibold">क्रियाकलाप</span>}
              key="action"
              render={each => {
                return (
                  <span>
                    <Button color="primary" onClick={handleClickOpen(each.key)}>
                      सच्चाउनुहोस
                    </Button>
                  </span>
                );
              }}
            />
          </Table>
        )}
      </Grid>
      <ConfirmDialog
        open={open}
        onChange={onSave}
        onClose={handleClose}
        title="घरमुली संगको नाता"
        fullWidth={false}
      >
        <Typography variant="h5">Are you sure you want to edit</Typography>
      </ConfirmDialog>
    </div>
  );
}
