import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import PrintIcon from "@material-ui/icons/Print";
import getNepaliNumber from "get-nepali-number";
import React, { Component } from "react";
import { totalWard } from "../../../../config";
import { gaupalika, selectedLanguage } from "../../../../variable/global";
// import Table from "../../../common/Table/Table.jsx";
import Card from "../../../common/Card/Card.jsx";
import CardBody from "../../../common/Card/CardBody.jsx";
import CardHeader from "../../../common/Card/CardHeader.jsx";
// import ReactToPrint from "react-to-print";
import ReactToPrint from "../../../common/frequent/print/ReactToPrint";
import GridContainer from "../../../common/Grid/GridContainer.jsx";
import GridItem from "../../../common/Grid/GridItem.jsx";
import HouseScroll from "../../../common/frequent/dialog/HouseScroll";

class DetailAnalysisTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			beforePrint: false,
			component: null,
			argumentTobePassed: [],
			open: false,
		};
		this.myRef = React.createRef();
	}

	handleOpen = (argumentTobePassed) => (event) => {
		this.setState({
			argumentTobePassed,
			open: true,
		});
	};

	handleClose = () => {
		this.setState({
			argumentTobePassed: [],
			open: false,
		});
	};

	handleAfterPrint = () => {
		this.setState({ beforePrint: false });
	};

	handleBeforePrint = () => {
		this.setState({ beforePrint: true });
	};

	getContent = () => {
		return this.setState({ beforePrint: true, component: this.myRef }, () => {
			return this.state.component;
		});
	};
	render() {
		const {
			classes,
			title,
			subtitle,
			tableHeader,
			ward,
			chartData,
			total,
		} = this.props;

		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card style={{ width: "auto" }}>
						<CardHeader color="primary">
							<Grid container justify="space-between" alignItems="flex-start">
								<Grid item>
									<Typography variant="h6" className={classes.cardTitleWhite}>
										{chartData.title}
									</Typography>
									<Typography
										variant="body2"
										className={classes.cardCategoryWhite}
									>
										{subtitle}
									</Typography>
								</Grid>
								{chartData.functions !== "null" && (
									<HouseScroll
										handleClose={this.handleClose}
										open={this.state.open}
										args={this.state.argumentTobePassed}
										functions={chartData.functions}
									/>
								)}
								<Grid item>
									<ReactToPrint
										style={{ marginTop: 200 }}
										onBeforePrint={this.handleBeforePrint}
										onAfterPrint={this.handleAfterPrint}
										trigger={() => (
											<Fab
												variant="extended"
												aria-label="Delete"
												className={classes.fab}
											>
												<PrintIcon className={classes.printIcon} />
											</Fab>
										)}
										data={this.state.component}
										content={this.getContent}
									/>
								</Grid>
							</Grid>
						</CardHeader>
						<CardBody>
							<div ref={this.myRef}>
								{/* Table here */}
								{this.state.beforePrint && (
									<Typography variant="h6" className={classes.title}>
										{chartData.title}
									</Typography>
								)}
								<Table className={classes.table}>
									<TableHead>
										<TableRow>
											<TableCell>{chartData.reportTitle[0]}</TableCell>

											{[...Array(totalWard).keys()].map(
												(eachWard) =>
													(ward === 0 || ward === eachWard + 1) && (
														<TableCell>
															{chartData.reportTitle[eachWard + 1]}
														</TableCell>
													)
											)}
											{ward === 0 && <TableCell>फिदिम</TableCell>}
										</TableRow>
									</TableHead>
									<TableBody>
										{chartData &&
											chartData.ward[0].data.map((w1, id) => (
												<TableRow key={id}>
													<TableCell component="th" scope="row">
														{w1.label}
													</TableCell>
													{chartData.ward.map((eachWard, wardIndex) => {
														if (wardIndex + 1 === ward || ward === 0)
															return (
																<TableCell
																	style={{
																		color: "blue",
																		textDecoration: "underline",
																	}}
																	align="left"
																	onClick={this.handleOpen(
																		eachWard.data[id].args
																	)}
																>
																	{getNepaliNumber(eachWard.data[id].value)}
																</TableCell>
															);
													})}
													{/* {[...Array(totalWard + 1).keys()].map(eachWard => {

													if (eachWard + 1 === ward || ward === 0)
														return (
															<TableCell
																style={{
																	color: "blue",
																	textDecoration: "underline"
																}}
																align="left"
																onClick={this.handleOpen(
																	chartData.ward[eachWard].data[id].args
																)}
															>
																{getNepaliNumber(
																	chartData.ward[eachWard].data[id].value
																)}
															</TableCell>
														);

													return false;
												})} */}
												</TableRow>
											))}

										<TableRow>
											<TableCell component="th" scope="row">
												जम्मा
											</TableCell>

											{chartData.total.map((eachWardTotal, index) => {
												if (index + 1 === ward || ward === 0)
													return (
														<TableCell align="left">
															{getNepaliNumber(eachWardTotal)}
														</TableCell>
													);
											})}
										</TableRow>
										<TableRow />
									</TableBody>

									{/* <TableBody>
									{chartData &&
										chartData.ward0.map((w1, id) => (
											<TableRow key={id}>
												<TableCell component="th" scope="row">
													{w1.label}
												</TableCell>
												{[...Array(totalWard).keys()].map(eachWard => {
													if (eachWard + 1 === ward || ward === 0)
														return (
															<TableCell
																style={
																	chartData.functions !== "null"
																		? {
																				color: "blue",
																				textDecoration: "underline"
																		  }
																		: {}
																}
																align="left"
																onClick={
																	chartData.functions !== "null" &&
																	this.handleOpen(
																		chartData[`ward${eachWard + 1}`][id].args
																	)
																}
															>
																{getNepaliNumber(
																	chartData[`ward${eachWard + 1}`][id].value
																)}
															</TableCell>
														);
												})}
												<TableCell align="left">
													{getNepaliNumber(chartData[`ward${0}`][id].value)}
												</TableCell>
											</TableRow>
										))}

									<TableRow>
										<TableCell component="th" scope="row">
											जम्मा
										</TableCell>

										{chartData.total.map(eachWardTotal => (
											<TableCell align="left">
												{getNepaliNumber(eachWardTotal)}
											</TableCell>
										))}
									</TableRow>
									<TableRow />
								</TableBody> */}
								</Table>
							</div>{" "}
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

const styles = (theme) => ({
	cardCategoryWhite: {
		"&,& a,& a:hover,& a:focus": {
			color: "rgba(255,255,255,.62)",
			margin: "0",
			fontSize: "14px",
			marginTop: "0",
			marginBottom: "0",
		},
		"& a,& a:hover,& a:focus": {
			color: "#FFFFFF",
		},
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none",
		"& small": {
			color: "#777",
			fontSize: "65%",
			fontWeight: "400",
			lineHeight: "1",
		},
	},
	root: {
		width: "auto",
		marginTop: theme.spacing.unit * 3,
	},
	table: {
		width: "auto",
	},
	tableWrapper: {
		overflowX: "auto",
	},
	title: {
		textAlign: "center",
	},
});

export default withStyles(styles)(DetailAnalysisTable);
