import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import getNepalNumber from "../../../../../utils/getNepaliNumber";
import { no, selectedLanguage, yes } from "../../../../../variable/global";
import {
  familyTitle,
  waterSource,
  cookingSource,
  electricSource,
  toiletType,
  toiletQuestion,
  toiletTypeNo,
  anyBankAccount,
  numberofBankAccount,
  homeAge,
  saltUsed,
  hasinsur,
  insuranceDetail
} from "../../../../../variable/houseSurvey";
import {
  QuestionAnswer,
  Title
} from "../../../../common/frequent/QuestionAnswer";

const NoImageFound = require("../../../../../image/NoImageFound.jpg");

function mapValue(value, obj) {
  return obj[value];
}

class HouseIntro extends Component {
  getYesNo = data => (data ? yes[selectedLanguage] : no[selectedLanguage]);

  render() {
    const { classes, houseNumber, secondaryHouseDetails } = this.props;
    return (
      <Grid item container direction="column" className={classes.container}>
        <Title title={familyTitle} />
        <Grid item container direction="column">
          {/* first */}
          <Grid
            item
            container
            direction="row"
            className={classes.eachQnAns}
            spacing={24}
          >
            <Grid item xs={4}>
              <QuestionAnswer
                question={waterSource[selectedLanguage]}
                answer={mapValue(secondaryHouseDetails.waterSource, {
                  pipe_in_home: "पाइप धारा घरमा",
                  public_pipe: "पाइप धारा सार्वजनिक",
                  hand_tube_pump: "ट्युबवेल/हेन्डपम्प",
                  well: "ढाकिएको इनार / कुवा",
                  mulko_pani: "नढाकिएको इनार / कुवा",
                  river_water: "नदि खोला",
                  rain_water: "आकासे पानी"
                })}
              />
            </Grid>
            <Grid item xs={4}>
              <QuestionAnswer
                question={electricSource[selectedLanguage]}
                answer={mapValue(secondaryHouseDetails.electricSource, {
                  electric: "राष्ट्रिय प्रसारण बिधुतलाईन",
                  local_elecrtic: "लघु जलबिधुत",
                  kerosene: "मट्टीतेल/टुकी",
                  solar: "सोलार",
                  bio_gas: "वायो ग्यास",
                  water_tight: "पानि घट्ट"
                })}
              />
            </Grid>
            <Grid item xs={4}>
              <QuestionAnswer
                question={cookingSource[selectedLanguage]}
                answer={mapValue(secondaryHouseDetails.cookingSource, {
                  wood: "दाउरा/काठ(साधरण चुलो )",
                  sudharieko_chu: "दाउरा/काठ(सुधारिएको चुलो )",
                  kerosene: "मट्टीतेल/स्टोभ",
                  lpg: "एल.पी.जी.ग्यास",
                  bio: "वायो ग्यास",
                  electric: "विधुत",
                  solar: "सौर्य उर्जा",
                  dung: "गुइठा"
                })}
              />
            </Grid>
          </Grid>
          {/* end */}

          <Grid
            item
            container
            direction="row"
            className={classes.eachQnAns}
            spacing={24}
          >
            <Grid item xs={4}>
              <QuestionAnswer
                question={toiletQuestion[selectedLanguage]}
                answer={mapValue(secondaryHouseDetails.toilet.isToilet, {
                  toilet_yes: "छ",
                  toilet_no: "छैन (शौचालय नभएको )",
                  no_use: "छ तर प्रयोग गरेको छैन"
                })}
              />
            </Grid>
            {secondaryHouseDetails.toilet.isToilet === "toilet_yes" && (
              <Grid item xs={4}>
                <QuestionAnswer
                  question={toiletType[selectedLanguage]}
                  answer={mapValue(secondaryHouseDetails.toilet.toiletYes, {
                    public_tank: "फ्लस भएको सार्वजनिक ढलमा मिसिने",
                    private_tank: "फ्लस भएको सेफ्टी ट्याङ्कमा मिसिने",
                    ordinary_tank: "साधारण",
                    shared_toilet: "सामुदायिक चर्पी"
                  })}
                />
              </Grid>
            )}
            {secondaryHouseDetails.toilet.isToilet === "toilet_no" && (
              <Grid item xs={4}>
                <QuestionAnswer
                  question={toiletTypeNo[selectedLanguage]}
                  answer={mapValue(secondaryHouseDetails.toilet.toiletNo, {
                    no_land: "जग्गा नभएर",
                    no_money: "बनाउने पैसा नभएर",
                    neighbour_use: "छिमेकीको प्रयोग गरेर"
                  })}
                />
              </Grid>
            )}
            <Grid item xs={4}>
              <QuestionAnswer
                question={anyBankAccount[selectedLanguage]}
                answer={this.getYesNo(secondaryHouseDetails.bankAccount)}
              />
            </Grid>
            {secondaryHouseDetails.numberofBankAccount && (
              <Grid item xs={4}>
                <QuestionAnswer
                  question={numberofBankAccount[selectedLanguage]}
                  answer={
                    secondaryHouseDetails.numberofBankAccount &&
                    getNepalNumber(secondaryHouseDetails.numberofBankAccount)
                  }
                />
              </Grid>
            )}

            {/* <Grid item xs={4}>
              <QuestionAnswer
                question={homeAge[selectedLanguage]}
                answer={
                  secondaryHouseDetails.houseAge &&
                  `${getNepalNumber(secondaryHouseDetails.houseAge)} बर्ष`
                }
              />
            </Grid> */}
          </Grid>

          <Grid
            item
            container
            direction="row"
            className={classes.eachQnAns}
            spacing={24}
          >
            <Grid item xs={4}>
              <QuestionAnswer
                question={saltUsed[selectedLanguage]}
                answer={
                  secondaryHouseDetails.saltUsed &&
                  mapValue(secondaryHouseDetails.saltUsed, {
                    iodine_salt: "आयोडिन्युक्त नून",
                    dhiko_salt: "ढिको नून",
                    open_salt: "खुल्ला नून"
                  })
                }
              />
            </Grid>

            <Grid item xs={4}>
              <QuestionAnswer
                question={hasinsur[selectedLanguage]}
                answer={this.getYesNo(secondaryHouseDetails.hasInsurance)}
              />
            </Grid>
          </Grid>

          {secondaryHouseDetails.hasInsurance && (
            <Grid
              item
              container
              direction="column"
              className={classes.eachQnAns}
            >
              <QuestionAnswer question={insuranceDetail[selectedLanguage]} />
              <Grid item xs={6}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {secondaryHouseDetails.insurance &&
                        secondaryHouseDetails.insurance.map(each => (
                          <TableCell align="left">
                            {mapValue(each.name, {
                              life_insurance: "जिबन बिमा"
                            })}
                          </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow>
                      {secondaryHouseDetails.insurance &&
                        secondaryHouseDetails.insurance.map(each => (
                          <TableCell align="left">
                            {each.count && getNepalNumber(each.count)}
                          </TableCell>
                        ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  container: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 2
  },
  table: {
    width: "auto"
  }
});

HouseIntro.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(HouseIntro);
