import {
  SAVE_HOUSE,
  SHOW_HOUSE,
  GET_SURVEY_ERRORS,
  SAVE_FAMILY,
  SAVE_DEATH,
  SAVE_MIGRATION
} from "../actions/types";

const initialState = {
  house: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SAVE_HOUSE:
      return {
        ...state,
        submitLoading: false,
        submit: action.payload.success
      };
    case SAVE_FAMILY:
      return {
        ...state,
        submitLoading: false,
        submit: action.payload.success
      };
    case SAVE_MIGRATION:
      return {
        ...state,
        submitLoading: false,
        submit: action.payload.success
      };
    case SAVE_DEATH:
      return {
        ...state,
        submitLoading: false,
        submit: action.payload.success
      };
    case SHOW_HOUSE:
      return {
        ...state,
        data: action.payload
      };
    case GET_SURVEY_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    default:
      return state;
  }
}
