import * as Actions from "../actions/bidhurBidhuwa.actions";

const initialState = {
  bidhurList: [],
  bidhuwaList: []
};

const bidhurBidhuwaReducer = function(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_BIDHURBIDHUWA_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.GET_BIDHUR_LIST:
      return {
        ...state,
        bidhurList: action.payload,
        loading: false
      };
    case Actions.GET_BIDHUWA_LIST:
      return {
        ...state,
        bidhuwaList: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export default bidhurBidhuwaReducer;
