import React from "react";
import { Input, Select, InputNumber } from "antd";
import {
  drinkingwaterLists,
  lightresourceLists,
  wastemanagementLists,
  cookingresourceLists,
  drinkingwaterplaceLists,
  toiletYesNo,
  noToiletLists,
  toiletresourceLists,
  roadLists,
  salttypeLists,
  houseUsedLivingOption,
  landUsedLiving,
  houseType,
  roofType,
  doHouseCriteriaFullfill,
  isHouseEarthquakeResistance,
  insuranceLists,
  deathReason,
  deathGender,
  agriProductionType,
  irrigationForLandList,
  seedNeed,
  businessLists,
  herbLists,
  bhattaLists
} from "../../../../variable/houseSurvey";
import { selectedLanguage } from "../../../../variable/global";

const { Option } = Select;

export default function OptionSelectionHouse({
  options,
  handleChange,
  handleUnitChange,
  handleAreaChange
}) {
  switch (options) {
    case "childDeathCount":
      return (
        <div>
          <InputNumber
            style={{ width: 80 }}
            onChange={value => handleChange("childDeathCount", value)}
          />
        </div>
      );
    case "welfareTitle":
      return (
        <div>
          <Select
            mode="multiple"
            style={{ width: 200 }}
            onChange={value => handleChange("welfareTitle", value)}
          >
            {bhattaLists.map(each => (
              <Option value={each.value}>{each[selectedLanguage]}</Option>
            ))}
          </Select>
        </div>
      );
    case "maleFemaleCount":
      return (
        <div>
          <InputNumber
            style={{ width: 80 }}
            onChange={value => handleChange("maleFemaleCount", value)}
          />
        </div>
      );
    case "homeBusinessName":
      return (
        <div>
          <Select
            mode="multiple"
            style={{ width: 200 }}
            onChange={value => handleChange("homeBusinessName", value)}
          >
            {businessLists.map(each => (
              <Option value={each.value}>{each[selectedLanguage]}</Option>
            ))}
          </Select>
        </div>
      );
    case "homeBusinessIncome":
      return (
        <div>
          <InputNumber
            style={{ width: 80 }}
            onChange={value => handleChange("homeBusinessIncome", value)}
          />
        </div>
      );
    case "herbBusinessName":
      return (
        <div>
          <Select
            mode="multiple"
            style={{ width: 200 }}
            onChange={value => handleChange("herbBusinessName", value)}
          >
            {herbLists.map(each => (
              <Option value={each.value}>{each[selectedLanguage]}</Option>
            ))}
          </Select>
        </div>
      );
    case "irrigation":
      return (
        <div>
          <Select
            style={{ width: 200 }}
            onChange={value => handleChange("irrigation", value)}
          >
            {irrigationForLandList.map(each => (
              <Option value={each.value}>{each[selectedLanguage]}</Option>
            ))}
          </Select>
        </div>
      );
    case "seedNeed":
      return (
        <div>
          <Select
            style={{ width: 200 }}
            onChange={value => handleChange("seedNeed", value)}
          >
            {seedNeed.map(each => (
              <Option value={each.value}>{each[selectedLanguage]}</Option>
            ))}
          </Select>
        </div>
      );
    case "area":
      return (
        <div>
          <InputNumber
            style={{ width: 80 }}
            onChange={value => handleAreaChange("ropani", value)}
          />
          &nbsp;रोपनी
          <InputNumber
            style={{ width: 80 }}
            onChange={value => handleAreaChange("aana", value)}
          />
          &nbsp;आना
          <InputNumber
            style={{ width: 80 }}
            onChange={value => handleAreaChange("paisa", value)}
          />
          &nbsp;पैसा
        </div>
      );
    case "production":
      return (
        <div>
          <InputNumber
            style={{ width: 200 }}
            onChange={value => handleChange("production", value)}
          />
          <Select
            style={{ width: 200 }}
            onChange={value => handleUnitChange(value)}
          >
            <Option value="kg">के.जी</Option>
            <Option value="plantCount">बोट संख्या</Option>
            <Option value="muri">मुरी</Option>
            <Option value="pathi">पाथी</Option>
          </Select>
        </div>
      );
    case "sale":
      return (
        <div>
          <InputNumber
            style={{ width: 200 }}
            onChange={value => handleChange("sale", value)}
          />
          <Select
            style={{ width: 200 }}
            onChange={value => handleUnitChange(value)}
          >
            <Option value="kg">के.जी</Option>
            <Option value="plantCount">बोट संख्या</Option>
            <Option value="muri">मुरी</Option>
            <Option value="pathi">पाथी</Option>
          </Select>
        </div>
      );
    case "deathReason":
      return (
        <div>
          <Select
            mode="multiple"
            style={{ width: 200 }}
            onChange={value => handleChange("deathReason", value)}
          >
            {deathReason.map(each => (
              <Option value={each.value}>{each[selectedLanguage]}</Option>
            ))}
          </Select>
        </div>
      );
    case "deathGender":
      return (
        <div>
          <Select
            style={{ width: 200 }}
            onChange={value => handleChange("deathGender", value)}
          >
            {deathGender.map(each => (
              <Option value={each.value}>{each[selectedLanguage]}</Option>
            ))}
          </Select>
        </div>
      );
    case "deathCertificate":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handleChange("deathCertificate", value)}
        >
          <Option value={true}>छ</Option>
          <Option value={false}>छैन</Option>
        </Select>
      );
    case "deathName":
      return (
        <Input
          type="text"
          style={{ width: 200 }}
          onChange={e => handleChange("deathName", e.target.value)}
          // onChange={handleinput("deathName")}
        />
      );
    case "deathAge":
      return (
        <InputNumber
          style={{ width: 200 }}
          onChange={value => handleChange("deathAge", value)}
        />
      );
    case "insuranceinsuranceType":
      return (
        <div>
          <Select
            style={{ width: 200 }}
            onChange={value => handleChange("name", value)}
          >
            {insuranceLists.map(each => (
              <Option value={each.value}>{each[selectedLanguage]}</Option>
            ))}
          </Select>
        </div>
      );
    case "insurancename":
      return (
        <div>
          <Select
            style={{ width: 200 }}
            onChange={value => handleChange("name", value)}
          >
            {insuranceLists.map(each => (
              <Option value={each.value}>{each[selectedLanguage]}</Option>
            ))}
          </Select>
        </div>
      );
    case "insurancecount":
      return (
        <InputNumber
          style={{ width: 200 }}
          onChange={value => handleChange("count", value)}
        />
      );
    case "waterSource":
      return (
        <Select
          style={{ width: 200 }}
          name="waterSource"
          onChange={value => handleChange("waterSource", value)}
        >
          {drinkingwaterLists.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "cookingSource":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handleChange("cookingSource", value)}
        >
          {cookingresourceLists.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "distanceToWaterSource":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handleChange("distanceToWaterSource", value)}
        >
          {drinkingwaterplaceLists.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "electricSource":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handleChange("electricSource", value)}
        >
          {lightresourceLists.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "wasteDisposal":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handleChange("wasteDisposal", value)}
        >
          {wastemanagementLists.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "toilet":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handleChange("toilet", value)}
        >
          {toiletYesNo.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "toiletYes":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handleChange("toiletYes", value)}
        >
          {toiletresourceLists.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "toiletNo":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handleChange("toiletNo", value)}
        >
          {noToiletLists.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "road":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handleChange("road", value)}
        >
          {roadLists.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "bankAccount":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handleChange("bankAccount", value)}
        >
          <Option value={true}>छ</Option>
          <Option value={false}>छैन</Option>
        </Select>
      );
    case "numberOfBankAccount":
      return (
        <InputNumber
          style={{ width: 200 }}
          onChange={value => handleChange("numberOfBankAccount", value)}
        />
      );
    case "saltUsed":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handleChange("saltUsed", value)}
        >
          {salttypeLists.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "houseCount":
      return (
        <InputNumber
          style={{ width: 200 }}
          onChange={value => handleChange("houseCount", value)}
        />
      );
    case "familyMemberAnotherHouse":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handleChange("familyMemberAnotherHouse", value)}
        >
          <Option value={true}>छ</Option>
          <Option value={false}>छैन</Option>
        </Select>
      );
    case "familyMemberAnotherHouseLocation":
      return (
        <Input
          type="text"
          style={{ width: 200 }}
          onChange={value =>
            handleChange("familyMemberAnotherHouseLocation", value)
          }
        />
      );
    case "totalRoom":
      return (
        <InputNumber
          style={{ width: 200 }}
          onChange={value => handleChange("totalRoom", value)}
        />
      );
    case "totalStorey":
      return (
        <InputNumber
          style={{ width: 200 }}
          onChange={value => handleChange("totalStorey", value)}
        />
      );
    case "HouseUsedLiving":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handleChange("HouseUsedLiving", value)}
        >
          {houseUsedLivingOption.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "landUsedLiving":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handleChange("landUsedLiving", value)}
        >
          {landUsedLiving.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "houseType":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handleChange("houseType", value)}
        >
          {houseType.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "roofType":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handleChange("roofType", value)}
        >
          {roofType.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "landDocument":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value =>
            handleChange("landDocument.hasLandDocument", value)
          }
        >
          <Option value={true}>छ</Option>
          <Option value={false}>छैन</Option>
        </Select>
      );
    case "doHouseCriteriaFullfill":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handleChange("doHouseCriteriaFullfill", value)}
        >
          {doHouseCriteriaFullfill.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "isHouseArchiving":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handleChange("isHouseArchiving", value)}
        >
          {isHouseEarthquakeResistance.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "hasHouseMapPass":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handleChange("hasHouseMapPass", value)}
        >
          <Option value={true}>छ</Option>
          <Option value={false}>छैन</Option>
        </Select>
      );
    default:
      return null;
  }
}
