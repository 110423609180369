import { Grid, Typography } from "@material-ui/core";
import Axios from "axios";
import React, { Component } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import isEmpty from "../../../../utils/validation/is-empty";
import {
  gaupalikaWard,
  houseDetails,
  selectedLanguage,
  wadaBibaranName,
  ward
} from "../../../../variable/global";
import Can from "../../../casl/Can";
import WardInfo from "../../home/WardInfo";
import ChartList from "../pariwarikBibaran/charts/ChartList.jsx";
import "./fixWidthofColumn.css";
import Infinite from "./infinite";

class Home extends Component {
  constructor(props) {
    super(props);
    const role = parseInt(sessionStorage.getItem("role"));
    const ward = parseInt(sessionStorage.getItem("ward"));
    var wardData = ward === 0 ? {} : { wardNumber: ward };
    this.state = {
      fetched: false,
      wardData,
      role,
      ward,
      charts: [],
      color: [],
      items: Array.from({ length: 20 }),
      per: 4,
      page: 0,
      hasMore: true,
      total: 11
    };
  }

  getTitle = (ward, object) => {
    if (isEmpty(ward)) return object[0][selectedLanguage];
    else return object[ward.wardNumber][selectedLanguage];
  };

  componentDidMount() {
    this.getAnalaysisHomeChartData(1);
  }

  getAnalaysisHomeChartData = page => {
    const ward = this.state.wardData.wardNumber
      ? this.state.wardData.wardNumber
      : 0;
    Axios.get(`/api/analysis/house/${page}/${this.state.per}/${ward}`)
      .then(res => {
        this.setState(prevState => {
          return {
            fetched: true,
            total: res.data.total,
            charts:
              page === 1
                ? res.data.data
                : prevState.charts.concat(res.data.data),
            hasMore: res.data.has_more,
            page: res.data.has_more ? prevState.page + 1 : prevState.page
          };
        });
        // this.setState({ charts: res.data.data, hasMore: res.data.has_more });
      })
      .catch(err => {
        console.error(err);
        // dispatch({
        // 	type: GET_ERRORS,
        // 	payload: err.response.data
        // });
      });
  };

  // componentDidUpdate(prevProps) {
  // 	if (this.props.chart !== prevProps.chart) {
  // 		this.setState({
  // 			charts: this.props.chart && this.props.chart
  // 		});
  // 	}
  // }

  onWardClick = wardData => {
    this.setState(
      {
        wardData,
        fetched: false,
        total: 11,
        charts: [],
        hasMore: true,
        page: 0
      },
      () => {
        this.getAnalaysisHomeChartData(1);
      }
    );
  };

  render() {
    const { loading } = this.props;
    const gaupalika = gaupalikaWard[selectedLanguage].split(" ");

    const { charts, wardData } = this.state;

    return (
      <div>
        <Can do="show" on={{ __type: "Home", ward: this.state.ward }}>
          <Typography variant="h4" gutterBottom>
            {wadaBibaranName[selectedLanguage]}
          </Typography>
          <div style={{ marginBottom: 25, marginTop: 20 }}>
            <Grid container spacing={2}>
              {ward.map((each, key) => (
                <Grid item>
                  <WardInfo
                    isActive={key + 1 == this.state.wardData.wardNumber}
                    backgroundColor={each.backgroundColor}
                    onWardClick={this.onWardClick}
                    unique={key + 1}
                    key={key}
                    wardNumber={each[selectedLanguage]}
                    wardName={each.ward}
                  />
                </Grid>
              ))}
              <Grid item>
                <WardInfo
                  isActive={undefined == this.state.wardData.wardNumber}
                  backgroundColor={"#00ACC1"}
                  unique={0}
                  wardNumber={gaupalika[0]}
                  wardName={gaupalika[1]}
                  onWardClick={this.onWardClick}
                />
              </Grid>
            </Grid>
          </div>
        </Can>
        <Typography
          variant="h4"
          gutterBottom
          style={{ margin: "10px 0px 20px 0px" }}
        >
          {this.getTitle(wardData, houseDetails)}
        </Typography>
        <Grid container spacing={2}>
          {!this.state.fetched ? (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <ClipLoader
                  sizeUnit={"px"}
                  size={84}
                  color={"#00ACC1"}
                  loading={loading}
                />
              </Grid>
            </Grid>
          ) : (
            <Infinite
              loading={
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <ClipLoader
                      sizeUnit={"px"}
                      size={84}
                      color={"#00ACC1"}
                      loading={loading}
                    />
                  </Grid>
                </Grid>
              }
              total={this.state.total}
              loadMore={this.getAnalaysisHomeChartData}
              hasMore={this.state.hasMore}
            >
              <Grid container spacing={2}>
                {charts ? (
                  charts.map((chart, key) => (
                    <Grid item className="fixWidthofColumn" key={key}>
                      <ChartList
                        chartData={{
                          title: chart.title,
                          route: chart.route,
                          label: chart.data.map(each => each.label),
                          data: chart.data.map(each => each.value)
                        }}
                        key={key}
                        match={this.props.match}
                      />
                    </Grid>
                  ))
                ) : (
                  <p>Loading...</p>
                )}
              </Grid>
            </Infinite>
          )}
        </Grid>
      </div>
    );
  }
}

export default Home;
