import Axios from "axios";
import { startSnackBar } from "../../../../../../actions/utilAction";

export const GET_HOUSEGENERIC_LIST = "GET_HOUSEGENERIC_LIST";
export const SET_HOUSEGENERIC_LOADING = "SET_HOUSEGENERIC_LOADING";
export const EDIT_HOUSE_FAMILYGENERIC = "EDIT_HOUSE_FAMILYGENERIC";

export const getEmptyHouseGeneric = () => dispatch => {
  dispatch({
    type: SET_HOUSEGENERIC_LOADING,
    payload: true
  });

  Axios.get(
    "/api/errorCorrection/emptyRequiredFieldHouse/houseEmptyFields"
  ).then(response => {
    return dispatch({
      type: GET_HOUSEGENERIC_LIST,
      payload: response.data
    });
  });
};

export function editHouseGeneric(data) {
  return dispatch =>
    Axios.post(
      "/api/errorCorrection/emptyRequiredFieldHouse/houseEmptyFieldsUpdate",
      data
    )
      .then(response => {
        dispatch(getEmptyHouseGeneric());

        dispatch(
          startSnackBar({
            open: true,
            variant: "success",
            // message: response.data.message
            message:
              "घर सर्वेक्षणमा खाली रहेको डाटा सफलतापूर्वक अद्यावधिक गरियो"
          })
        );
        return dispatch({
          type: EDIT_HOUSE_FAMILYGENERIC,
          payload: response.data
        });
      })
      .catch(err => console.log(err));
}
