import * as Actions from "../actions/answerer.actions";

const initialState = {
  list: [],
  answererList: []
};

const answererReducer = function(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ANSWERER_LIST:
      return {
        ...state,
        list: action.payload,
        loading: false
      };
    case Actions.GET_ANSWERERNAME_LIST:
      return {
        ...state,
        answererList: action.payload
      };
    default:
      return state;
  }
};

export default answererReducer;
