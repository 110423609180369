import React from "react";
import { Input, Select, InputNumber } from "antd";
import {
  job,
  primary,
  secondary,
  casteList,
  marriage,
  religionList,
  aadibasi,
  gender,
  industry,
  healthcondition,
  mothertongue,
  bloodgroup,
  votercard,
  unhealthy,
  mentaltype,
  mentalcondition,
  mentalCardYesNo,
  mentalCardtype,
  skillDetail
} from "../../../../variable/houseSurvey";
import { selectedLanguage } from "../../../../variable/global";

const { Option } = Select;

export default function OptionSelection({ options, handleChange }) {
  const handlechange = (name, value) => {
    handleChange(name, value);
  };
  switch (options) {
    case "skill":
      return (
        <Select
          mode="multiple"
          style={{ width: 200 }}
          placeholder="Please select"
          onChange={value => handlechange("title", value)}
        >
          {skillDetail.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "motherTongue":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handlechange("motherTongue", value)}
        >
          {mothertongue.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "bloodGroup":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handlechange("bloodGroup", value)}
        >
          {bloodgroup.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "voterId":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handlechange("voterId", value)}
        >
          <Option value={true}>छ</Option>
          <Option value={false}>छैन</Option>
        </Select>
      );
    case "type":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handlechange("type", value)}
        >
          {mentaltype.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "condition":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handlechange("condition", value)}
        >
          {mentalcondition.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "hasCard":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handlechange("hasCard", value)}
        >
          <Option value={true}>छ</Option>
          <Option value={false}>छैन</Option>
        </Select>
      );
    case "cardType":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handlechange("cardType", value)}
        >
          {mentalCardtype.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "unhealthyDisease":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handlechange("unhealthyDisease", value)}
        >
          {unhealthy.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "healthCondition":
      return (
        <Select
          name="healthCondition"
          style={{ width: 200 }}
          onChange={value => handlechange("healthCondition", value)}
        >
          {healthcondition.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "primaryDetails":
      return (
        <Select
          name="primaryDetails"
          style={{ width: 200 }}
          onChange={value => handlechange("primaryDetails", value)}
        >
          {primary.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "secondaryDetails":
      return (
        <Select
          name="secondaryDetails"
          style={{ width: 200 }}
          onChange={value => handlechange("secondaryDetails", value)}
        >
          {secondary.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "caste":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handlechange("caste", value)}
        >
          {aadibasi.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "ethnics":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handlechange("ethnics", value)}
        >
          {casteList.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "religion":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handlechange("religion", value)}
        >
          {religionList.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "gender":
      return (
        <Select
          style={{ width: 200 }}
          onChange={value => handlechange("gender", value)}
        >
          {gender.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "contactNumber":
      return (
        <InputNumber
          style={{ width: 200 }}
          onChange={value => handlechange("contactNumber", value)}
        />
      );
    case "martialStatus":
      return (
        <Select
          name="martialStatus"
          style={{ width: 200 }}
          onChange={value => handlechange("martialStatus", value)}
        >
          {marriage.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "job":
      return (
        <Select
          name="levelTwoJob"
          style={{ width: 200 }}
          onChange={value => handlechange("levelTwoJob", value)}
        >
          {job.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    case "business":
      return (
        <Select
          name="levelTwoJob"
          style={{ width: 200 }}
          onChange={value => handlechange("levelTwoJob", value)}
        >
          {industry.map(each => (
            <Option value={each.value}>{each[selectedLanguage]}</Option>
          ))}
        </Select>
      );
    default:
      return null;
  }
}
