import React, { useEffect, useState } from "react";
import { Table, Spin, Input } from "antd";
import { Typography, Button, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmptyHouseNumberDuplicate,
  editHouseNumberDuplicate
} from "./store/actions/houseNumberDuplicate.actions";
import ConfirmDialog from "../common/confirmDialog";
import MTextField from "@material-ui/core/TextField";
// import { SnackBar } from "../../../common/Snackbar/Snackbar";
import Axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { selectedLanguage } from "../../../../variable/global";

const { Column } = Table;

export default function HouseNumberDuplicate(props) {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(
    ({ dataCorrection }) => dataCorrection.duplicacy.houseNumberDuplicate
  );
  const [data, setData] = useState(list);
  const [open, setOpen] = React.useState(false);
  const [houseHelperText, setHouseHelperText] = useState({});
  const [gharNumber, setHouseNumber] = useState("");
  const [isHouseNumberValid, setIsHouseNumberValid] = useState(false);
  const [houseId, setHouseId] = useState("");

  const handleClickOpen = id => () => {
    setHouseId(id);
    setOpen(true);
  };

  const handleClose = value => {
    setHouseNumber("");
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getEmptyHouseNumberDuplicate());
  }, [dispatch]);

  useEffect(() => {
    setData(list);
  });

  const handleChange = (name, value) => {
    setHouseNumber(value);
  };

  const onSave = () => {
    let data = {
      houseNumber: gharNumber,
      houseId: houseId
    };
    dispatch(editHouseNumberDuplicate(data));
    handleClose();
  };

  let mapColumn = [
    {
      title: "सर्वेक्षण काेडः",
      dataIndex: "dataCollectionCode",
      key: "dataCollectionCode",
      width: 200
    },
    {
      title: "वडा नं",
      dataIndex: "wardNumber",
      key: "wardNumber",
      width: 200
    },
    {
      title: "उत्तरदाताको नाम",
      dataIndex: "answererName",
      key: "answererName",
      width: 200
    },
    {
      title: "Ghar Number",
      dataIndex: "houseNumber",
      key: "houseNumber",
      width: 200
    }
  ];

  return (
    <div>
      <Typography variant="h4" component="h2" align="center">
        घर नं
      </Typography>
      <Grid container spacing={24}>
        {loading ? (
          // getLoading(5)
          <div style={{ display: "block", margin: "0 auto" }}>
            <Spin size="large" />
          </div>
        ) : (
          <Table
            style={{ width: "100%", margin: "0px 5px" }}
            dataSource={data.duplicateData}
            rowKey="uid"
          >
            <Column
              title={<span className="font-semibold">क्र.श</span>}
              dataIndex={"sn"}
              key={"sn"}
              render={(text, record, index) => <p>{index + 1}</p>}
            />

            {mapColumn.map(column => (
              <Column {...column} />
            ))}
            <Column
              title={<span className="font-semibold">क्रियाकलाप</span>}
              key="action"
              render={each => {
                return (
                  <span>
                    <Button color="primary" onClick={handleClickOpen(each.key)}>
                      सच्चाउनुहोस
                    </Button>
                  </span>
                );
              }}
            />
          </Table>
        )}
      </Grid>
      <ConfirmDialog
        open={open}
        onChange={onSave}
        onClose={handleClose}
        title="घर नं"
        fullWidth={false}
      >
        <Grid xs={6} style={{ marginBottom: 10 }}>
          <MTextField
            id="gharNumber"
            name="gharNumber"
            type="number"
            error={!gharNumber || !isHouseNumberValid}
            helperText={!gharNumber || houseHelperText[selectedLanguage]}
            label="घर नं"
            style={{
              width: "100%",
              marginLeft: `${8 * 1}px`,
              marginTop: `${8 * 3}px`,
              marginBottom: `${8 * 3}px`
            }}
            // className={classes.component}
            value={gharNumber}
            onChange={event => {
              handleChange(event.target.name, event.target.value);
              let houseHelperText = {};
              let isHouseNumberValid = false;
              if (event.target.value) {
                Axios.get(`/api/house/isHouseNumberExist/${event.target.value}`)
                  .then(result => {
                    if (result.data.success) {
                      isHouseNumberValid = false;
                      houseHelperText = {
                        english: "House number is already taken.",
                        nepali: "घर नम्बर पहिले नै लिइएको छ।"
                      };
                    } else {
                      isHouseNumberValid = true;
                      houseHelperText = {
                        english: "House number is available.",
                        nepali: "घर नम्बर उपलब्ध छ।"
                      };
                    }
                    setHouseHelperText(houseHelperText);
                    setIsHouseNumberValid(isHouseNumberValid);
                  })
                  .catch(err => {});
              } else {
                houseHelperText = {
                  english: "please select unique house number in english",
                  nepali: "कृपया अंग्रेजीमा अद्वितीय घर नम्बर चयन गर्नुहोस्"
                };
                this.props.setHouseNumberValid(
                  houseHelperText,
                  isHouseNumberValid
                );
              }
            }}
            margin="normal"
            variant="outlined"
          />
        </Grid>
        {/* <Input
          type="text"
          value={text}
          onChange={({ target: { value } }) => setText(value)}
        /> */}
      </ConfirmDialog>
    </div>
  );
}
