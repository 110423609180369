import { GET_FILENAME_LIST, SET_ANALYSIS_LOADING } from "../actions/types";

const initialState = {
  loading: false,
  reportList: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_ANALYSIS_LOADING:
      return {
        loading: true
      };
    case GET_FILENAME_LIST:
      return {
        ...state,
        loading: false,
        reportList: action.payload
      };
    default:
      return state;
  }
}
