import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Typography,
  TextField,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import { green, lightBlue, red } from "@material-ui/core/colors";
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import classnames from "classnames";
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import AlertDialog from "../../../common/frequent/dialog/AlertDialog";
import Axios from "axios";
// import Grid from "antd/lib/card/Grid";
import ClipLoader from "react-spinners/ClipLoader";
import { Empty } from "antd";
import isEmpty from "../../../../utils/validation/is-empty";
import TablePaginationActionsWrapped from "../../log/TablePaginationActions";

// import PreviewModal from "../../common/frequent/dialog/PreviewModal";

class ListUser extends Component {
  state = {
    page: 0,
    rowsPerPage: 10,
    id: "",
    content: "",

    personId: null,
    deleteDialog: false,
    open: false,
    persons: [],
    personName: "",
    personPhone: "",
    personDesignation: "",
  };

  filterData = () => {
    const { temp, personName, personPhone, personDesignation } = this.state;
    var personNameFilter = isEmpty(personName)
      ? temp
      : temp.filter(({ name }) => name.toString().includes(personName));

    var personPhoneFilter = isEmpty(personPhone)
      ? personNameFilter
      : personNameFilter.filter(({ primaryPhone }) =>
          primaryPhone.toString().startsWith(personPhone)
        );
    var personDesignationFilter = isEmpty(personDesignation)
      ? personPhoneFilter
      : personPhoneFilter.filter(({ designation: { name } }) =>
          name.toString().includes(personDesignation)
        );

    this.setState({ persons: personDesignationFilter });
  };

  clearFilter = () => {
    this.setState({
      sn: "",
      houseNumber: "",
      wardNumber: "",
      contactNumber: "",
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.persons !== this.props.persons) {
      console.log("persons props", this.props.persons);
      this.setState({
        persons: this.props.persons,
        temp: this.props.persons,
      });
    }
  }

  removePerson = () => {
    const { personId } = this.state;
    Axios.delete(`/api/sms/person/delete/${personId}`)
      .then((res) => {
        res.data.success && this.handleClose();
        this.props.fetchPerson();
      })
      .catch((err) => {});
  };

  handleDeleteOpen = (personId) => (event) => {
    this.setState({ deleteDialog: true, personId: personId });
  };

  handleClickOpen = () => {
    this.setState({ deleteDialog: true });
  };
  handleClose = () => {
    this.setState({ deleteDialog: false });
  };

  handlePreviewOpen = (event) => {
    this.setState({ open: true, id: event.target.id });
  };

  handlePreviewClose = () => {
    this.setState({ open: false, id: null });
  };

  handleChanges = (event) => {
    console.log("data", event.target.name, event.target.value);
    this.setState({ [event.target.name]: event.target.value }, () =>
      this.filterData()
    );
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const { persons, page, rowsPerPage } = this.state;
    console.log("persons", persons);
    return (
      <Fragment>
        <Table className={classes.table}>
          <AlertDialog
            open={this.state.deleteDialog}
            handleClose={this.handleClose}
            negButtonClick={this.handleClose}
            posButtonClick={this.removePerson}
            title="Are you sure you want to delete the user ?"
            posButton="agree"
            negButton="disagree"
          />
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>
                <TextField
                  id="outlined-wardNumber"
                  label={"Name"}
                  className={classes.textFieldWN}
                  name="personName"
                  value={this.state.personName}
                  onChange={this.handleChanges}
                  margin="normal"
                  variant="outlined"
                />
              </TableCell>
              <TableCell>
                <TextField
                  id="outlined-wardNumber"
                  label="Phone"
                  className={classes.textFieldWN}
                  name="personPhone"
                  value={this.state.personPhone}
                  type="number"
                  onChange={this.handleChanges}
                  margin="normal"
                  variant="outlined"
                />
              </TableCell>

              <TableCell>
                <TextField
                  id="outlined-wardNumber"
                  label="Designation"
                  className={classes.textFieldWN}
                  name="personDesignation"
                  value={this.state.personDesignation}
                  onChange={this.handleChanges}
                  margin="normal"
                  variant="outlined"
                />
              </TableCell>

              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {persons &&
              persons
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((person, id) => (
                  <TableRow key={id}>
                    <TableCell component="th" scope="row">
                      {id + 1}
                    </TableCell>
                    <TableCell align="left">{person.name}</TableCell>
                    <TableCell align="left">{person.primaryPhone}</TableCell>
                    <TableCell align="left">
                      {person.designation && person.designation.name}
                    </TableCell>
                    <TableCell align="left">
                      {/* <VisibilityIcon
												id={person._id}
												onClick={this.handlePreviewOpen}
												className={classnames(classes.preview, classes.icon)}
											/> */}
                      <Link to={`/sms/people/edit/${person._id}`}>
                        <Icon
                          // onClick={this.handleEdit(person_id)}
                          className={classnames(classes.edit, classes.icon)}
                        />
                      </Link>
                      <DeleteIcon
                        onClick={this.handleDeleteOpen(person._id)}
                        className={classnames(classes.delete, classes.icon)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
          {persons && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
                  colSpan={7}
                  count={persons.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
        {persons.length === 0 && (
          <Grid
            style={{ marginTop: 20 }}
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <Grid item>
              <Empty />
            </Grid>
          </Grid>
        )}
      </Fragment>
    );
  }
}

const styles = (theme) => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  fab: {
    float: "right",
  },
  icon: {
    margin: theme.spacing.unit * 1,
    cursor: "pointer",
  },
  preview: {
    color: lightBlue[500],
  },
  edit: {
    color: green[700],
  },
  delete: {
    color: red[500],
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
});

export default withStyles(styles)(ListUser);
