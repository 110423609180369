import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import getNepalNumber from "../../../../../utils/getNepaliNumber";
import { no, selectedLanguage, yes } from "../../../../../variable/global";
import {
  aana,
  gharJaggaTitle,
  hoousesType,
  houseCriteriaFullfill,
  houseLandUsedLiving,
  houseMapPass,
  houseRent,
  houseroofType,
  paisa,
  road,
  ropani,
  totalHouseCount,
  totalHouseRoom,
  totalHouseStorey,
  totalMatanCount,
  wasteDisposal
} from "../../../../../variable/houseSurvey";
import {
  QuestionAnswer,
  Title
} from "../../../../common/frequent/QuestionAnswer";

function mapValue(value, obj) {
  return obj[value];
}
class SearchByHouseNumber extends Component {
  getYesNo = data => (data ? yes[selectedLanguage] : no[selectedLanguage]);
  render() {
    //
    // const totalArea = { english: "", nepali: "जम्मा जग्गा क्षेत्रफल" };
    // const ropani = { english: "", nepali: "रोपनी" };
    // const aana = { english: "", nepali: "आना" };
    // const paisa = { english: "", nepali: "पैसा" };

    //
    const { classes, houseland } = this.props;
    return (
      <Grid item container direction="row">
        <Title title={gharJaggaTitle} />
        <Grid item container>
          <Grid item container direction="row">
            {houseland.houseCount && (
              <Grid item xs={6}>
                <QuestionAnswer
                  variant="h6"
                  question={totalHouseCount[selectedLanguage]}
                  answer={
                    houseland &&
                    houseland.houseCount &&
                    getNepalNumber(houseland.houseCount)
                  }
                />
              </Grid>
            )}
            {houseland.matanCount && (
              <Grid item xs={6}>
                <QuestionAnswer
                  variant="h6"
                  question={totalMatanCount[selectedLanguage]}
                  answer={
                    houseland &&
                    houseland.matanCount &&
                    getNepalNumber(houseland.matanCount)
                  }
                />
              </Grid>
            )}
          </Grid>
          <Grid item container direction="row">
            {houseland.totalStorey && (
              <Grid item xs={6}>
                <QuestionAnswer
                  variant="h6"
                  question={totalHouseStorey[selectedLanguage]}
                  answer={
                    houseland &&
                    houseland.totalStorey &&
                    getNepalNumber(houseland.totalStorey)
                  }
                />
              </Grid>
            )}
            {houseland.totalRoom && (
              <Grid item xs={6}>
                <QuestionAnswer
                  variant="h6"
                  question={totalHouseRoom[selectedLanguage]}
                  answer={
                    houseland &&
                    houseland.totalRoom &&
                    getNepalNumber(houseland.totalRoom)
                  }
                />
              </Grid>
            )}
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={6}>
              <QuestionAnswer
                variant="h6"
                question={houseMapPass[selectedLanguage]}
                answer={this.getYesNo(houseland.hasHouseMapPass)}
              />
            </Grid>
            <Grid item xs={6}>
              <QuestionAnswer
                variant="h6"
                question={houseCriteriaFullfill[selectedLanguage]}
                answer={mapValue(houseland.doHouseCriteriaFullfill, {
                  criteria_yes: "छ",
                  criteria_no: "छैन",
                  donot_know_cri: "थाहा छैन"
                })}
              />
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={6}>
              <QuestionAnswer
                variant="h6"
                question={houseLandUsedLiving[selectedLanguage]}
                answer={mapValue(houseland.landUsedLiving, {
                  niji: "निजि आफ्नै",
                  rent: "भाडामा",
                  qarter: "संस्थागत (सरकारी वा संस्थाको क्वार्टर )"
                })}
              />
            </Grid>
            <Grid item xs={6}>
              <QuestionAnswer
                variant="h6"
                question={houseroofType[selectedLanguage]}
                answer={mapValue(houseland.roofType, {
                  steel: "जस्ता पाता",
                  roof_grass: "फुस वा खरको",
                  tiles: "टायल/खपडा/ढुंगा",
                  rcc: "सिमेन्ट/ ढलान",
                  wooden: "काठ/ फल्याक",
                  mud: "माटो"
                })}
              />
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={6}>
              <QuestionAnswer
                variant="h6"
                question={hoousesType[selectedLanguage]}
                answer={mapValue(houseland.houseType, {
                  rcc: "आरसीसी",
                  stone_cement: "ढुंगाको घर (सिमेन्टको जोडाइ)",
                  brick_cement: "पक्कि इट्टाको घर (सिमेन्टको जोडाइ)",
                  stone_mud: "ढुंगाको घर (माटोको जोड़ाई)",
                  brick_mud: "काचो इट्टाको घर (माटोको जोड़ाई)",
                  bamboo: "बास/टाटीको घर",
                  jasta_pata: "जस्तापाताले बेरेको",
                  wooden: "काठको खम्बा (काठको घर )"
                })}
              />
            </Grid>
            <Grid item xs={6}>
              <QuestionAnswer
                variant="h6"
                question={wasteDisposal[selectedLanguage]}
                answer={mapValue(houseland.wasteDisposal, {
                  self_managed: "आफै व्यवस्थापन गर्ने",
                  garbage_burn: "संकलित फोहोर जलाउने",
                  gobar_gas: "गोबर ग्यास प्लान्ट प्रायोग गर्ने",
                  private_org: "निजि संस्थाले घरबाटै उठाउने",
                  unmanaged: "अव्यवस्थित तबरले बाहिर जताभावी फ्याल्ने"
                })}
              />
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={6}>
              <QuestionAnswer
                variant="h6"
                question={road[selectedLanguage]}
                answer={mapValue(houseland.road, {
                  black_pitched: "पक्कि/कालोपत्रो सडक",
                  gravel: "ग्रावेल सडक",
                  dusty: "धुले सडक (कच्ची)",
                  goreto: "गोरेटो बाटो जोडिएको"
                })}
              />
            </Grid>

            <Grid item xs={6}>
              <QuestionAnswer
                variant="h6"
                question={houseRent[selectedLanguage]}
                answer={this.getYesNo(houseland.houseRent)}
              />
            </Grid>
          </Grid>

          <Grid item container direction="column">
            <QuestionAnswer question="जम्मा जग्गा क्षेत्रफल" />
            <Grid item>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      {ropani[selectedLanguage]}
                    </TableCell>
                    <TableCell align="left">{aana[selectedLanguage]}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">
                      {houseland &&
                        houseland.area &&
                        houseland.area.ropani &&
                        getNepalNumber(houseland.area.ropani)}
                    </TableCell>
                    <TableCell align="left">
                      {houseland &&
                        houseland.area &&
                        houseland.area.aana &&
                        getNepalNumber(houseland.area.aana)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  table: {
    width: "auto"
  }
});

SearchByHouseNumber.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SearchByHouseNumber);
