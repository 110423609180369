import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Stepper from "@material-ui/core/Stepper";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import React, { Component } from "react";
import { selectedLanguage } from "../../../variable/global";
import primaryHouse from "../../../utils/validation/primaryHouse";
import houseSurveyValidation from "../../../utils/validation/houseSurvey";
import { stepperStep } from "../../../variable/houseSurvey";
import AgriLivestock from "./pages/AgriLivestock";
import Business from "./pages/Business";
import Death from "./pages/Death";
import Economics from "./pages/Economics";
import FamilyDetail from "./pages/FamilyDetail";
import HouseDetail from "./pages/HouseDetail";
import Insurance from "./pages/Insurance";
import PrimaryHouse from "./pages/PrimaryHouse.jsx";
import SecondaryHouse from "./pages/SecondaryHouse";
import SocialWelfare from "./pages/SocialWelfare";
import WomenChild from "./pages/WomenChild";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addHouseSurvey } from "../../../actions/houseSurveyAction";
import Axios from "axios";
import { message } from "antd";

class index extends Component {
  addMarker = e => {
    console.log("e", e);
    this.setState({
      gpsFetched: false,
      lat: e.latlng.lat,
      lng: e.latlng.lng
    });
  };
  // for leaflet maps
  resetMap = latlng => {
    const { set, lat, lng } = latlng;
    this.setState({ lat, lng, gpsFetched: set });
  };

  handleMap = (name, value) => {
    this.setState({ [name]: value });
  };

  // name be name,gender,age,reason

  state = {
    isHouseNumberValid: false,
    houseHelperText: {
      english: "please select unique house number in english",
      nepali: "कृपया अंग्रेजीमा अद्वितीय घर नम्बर चयन गर्नुहोस्"
    },
    // maps
    fileList: {
      house: []
    },
    gpsFetched: false,
    lat: 27.704206,
    lng: 85.331871,
    zoom: 15,
    // page Level State
    primaryHouse: {},
    familyDetail: {
      dateOfBirth: "२०१९-०३-०३",
      age: 46
    },
    // death state
    death: {
      anyDeath: null,
      deathCount: 1
    },
    insurance: {},
    secondaryHouse: {},
    houseDetail: {},
    agriLivestock: {},
    economics: {},
    business: {},
    womenChild: {},
    socialWelfare: {},

    activeStep: 0,
    completed: [],
    text: "",
    errors: {
      // primaryHouse: {},
      // familyDetail: {},
      // death: {},
      // insurance: {},
      // secondaryHouse: {},
      // houseDetail: {},
      // agriLivestock: {},
      // economics: {},
      // business: {},
      // womenChild: {},
      // socialWelfare: {}
    }
  };

  // imageUpload
  handleChangeFile = (name, value, imageName) => {
    //name ma house and value ma list of filelist
    console.log("CF", value);
    this.setState(
      prevState => ({
        ...prevState,
        fileList: {
          ...prevState.fileList,
          [name]: {
            image: value.fileList,
            fileName:
              prevState.primaryHouse.gharNumber + "_" + imageName + ".jpg"
          }
        }
      }),
      () => {
        if (value.fileList.length > 0) this.handleUpload(name, imageName);
        if (value.fileList.length === 0) this.removeUpload(name, imageName);
      }
    );
  };

  // upload image
  handleUpload = (name, imageName) => {
    const fileList = this.state.fileList[name];
    const data = new FormData();
    const imageObject = fileList.image[0].originFileObj;
    let fileName =
      this.state.primaryHouse.gharNumber + "_" + imageName + ".jpg";
    data.append("file", imageObject, fileName);

    Axios.post("/api/imageUpload/upload", data, {
      onUploadProgress: ProgressEvent => {
        this.setState({
          loaded: Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)
        });
      }
    })
      .then(res => {
        // then print response status
        message.success("upload successfully.");
        console.log(res.statusText);
      })
      .catch(err => message.error("upload failed."));
  };

  // remove uploaded image
  removeUpload = imageName => {
    let fileName =
      this.state.primaryHouse.gharNumber + "_" + imageName + ".jpg";

    Axios.get(`/api/imageUpload/delete/${fileName}`)
      .then(res => {
        // then print response status
        message.success("deleted successfully.");
        console.log(res.statusText);
      })
      .catch(err => message.error("upload failed."));
  };

  // beforeUpload = (name,value) => {
  // 	this.setState(prevstate => ({
  // 		...prevstate,
  // 		fileList: {
  // 			...prevstate.fileList,
  // 			[name]: value
  // 		}
  // 	}));

  // 	this.setState(state => ({
  // 		fileList: [...state.fileList, file]
  // 	}));
  // 	return false;
  // };

  setHouseNumberValid = (houseHelperText, isHouseNumberValid) => {
    this.setState({
      houseHelperText,
      isHouseNumberValid
    });
  };
  getSteps = () => {
    return stepperStep.map(each => each[selectedLanguage]);
  };

  getStepContent = step => {
    const {
      isHouseNumberValid,
      houseHelperText,
      position,
      zoom,
      primaryHouse,
      familyDetail,
      death,
      deathDetail,
      insurance,
      secondaryHouse,
      houseDetail,
      agriLivestock,
      economics,
      business,
      womenChild,
      socialWelfare,
      lat,
      lng,
      gpsFetched,
      errors,
      fileList
      // women child end
    } = this.state;
    switch (step) {
      case 0:
        return (
          <PrimaryHouse
            isHouseNumberValid={this.state.isHouseNumberValid}
            houseHelperText={this.state.houseHelperText}
            setHouseNumberValid={this.setHouseNumberValid}
            handleUpload={this.handleUpload}
            handleChangeFile={this.handleChangeFile}
            fileList={fileList}
            isEdit={false}
            errors={errors}
            handleChange={this.handleChange}
            primaryHouse={primaryHouse}
            addMarker={this.addMarker}
            handleMap={this.handleMap}
            lat={lat}
            lng={lng}
            zoom={zoom}
            gpsFetched={gpsFetched}
            resetMap={this.resetMap}
          />
        );
      case 1:
        return (
          <FamilyDetail
            isEdit={false}
            errors={errors}
            handleChange={this.handleChange}
            familyDetail={familyDetail}
            isHouseNumberValid={this.state.isHouseNumberValid}
            houseHelperText={this.state.houseHelperText}
            setHouseNumberValid={this.setHouseNumberValid}
            handleUpload={this.handleUpload}
            handleChangeFile={this.handleChangeFile}
            handleResetFamily={this.handleResetFamily}
            handleMemberNumber={this.handleMemberNumber}
            fileList={fileList}
          />
        );
      case 2:
        return (
          <Death
            errors={errors}
            handleResetDeath={this.handleResetDeath}
            handleChange={this.handleChange}
            death={death}
            deathDetail={deathDetail}
            handleChangeDeath={this.handleChangeDeath}
          />
        );
      case 3:
        return (
          <Insurance
            errors={errors}
            handleChange={this.handleChange}
            insurance={insurance}
          />
        );
      case 4:
        return (
          <SecondaryHouse
            errors={errors}
            handleChange={this.handleChange}
            secondaryHouse={secondaryHouse}
          />
        );
      case 5:
        return (
          <HouseDetail
            errors={errors}
            handleChange={this.handleChange}
            houseDetail={houseDetail}
          />
        );
      case 6:
        return (
          <AgriLivestock
            errors={errors}
            handleChange={this.handleChange}
            agriLivestock={agriLivestock}
          />
        );
      case 7:
        return (
          <Economics
            errors={errors}
            handleChange={this.handleChange}
            economics={economics}
          />
        );
      case 8:
        return (
          <Business
            errors={errors}
            handleChange={this.handleChange}
            business={business}
          />
        );
      case 9:
        return (
          <WomenChild
            errors={errors}
            handleChange={this.handleChange}
            womenChild={womenChild}
          />
        );
      case 10:
        return (
          <SocialWelfare
            errors={errors}
            handleChange={this.handleChange}
            socialWelfare={socialWelfare}
          />
        );
      default:
        return "Unknown step";
    }
  };

  totalSteps = () => this.getSteps().length;

  handleNext = () => {
    let activeStep;

    if (this.isLastStep() && !this.allStepsCompleted()) {
      // It's the last step, but not all steps have been completed
      // find the first step that has been completed
      const steps = this.getSteps();
      activeStep = steps.findIndex(
        (step, i) => !this.state.completed.indexOf(i) >= 0
      );
    } else {
      activeStep = this.state.activeStep + 1;
    }
    this.setState({
      activeStep
    });
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  handleStep = step => () => {
    this.setState({
      activeStep: step
    });
  };

  handleComplete = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const { completed, activeStep } = this.state;
    const toBeValidate = [
      this.state.primaryHouse,
      this.state.familyDetail,
      this.state.death,
      this.state.insurance,
      this.state.secondaryHouse,
      this.state.houseDetail,
      this.state.agriLivestock,
      this.state.economics,
      this.state.business,
      this.state.womenChild,
      this.state.socialWelfare
    ];
    console.log("activeStep", activeStep);
    const errorData = houseSurveyValidation[activeStep](
      toBeValidate[activeStep]
    );
    console.log("errorData", errorData);
    if (errorData.isValid) {
      completed.push(activeStep);
      this.setState({ completed });

      /**
       * Sigh... it would be much nicer to replace the following if conditional with
       * `if (!this.allStepsComplete())` however state is not set when we do this,
       * thus we have to resort to not being very DRY.
       */
      if (completed.length !== this.totalSteps()) {
        this.handleNext();
      }
      return;
    }
    this.setState({ errors: errorData });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      completed: [],
      skipped: []
    });
  };

  handleResetDeath = index => {
    var { death } = this.state;
    let { deathCount } = death;
    if (index >= 0) {
      let deathData = {};
      for (let i = index + 1; i < deathCount; i++) {
        deathData[`deathName${i - 1}`] = deathData[`deathName${i}`];
        deathData[`deathGender${i - 1}`] = deathData[`deathGender${i}`];
        deathData[`deathAge${i - 1}`] = deathData[`deathAge${i}`];
        deathData[`deathReason${i - 1}`] = deathData[`deathReason${i}`];
      }
      console.log("all", deathData);
      this.setState(state => ({
        ...state,
        death: {
          ...death,
          ...deathData,
          deathCount: death.deathCount - 1
        }
      }));
    }
  };

  handleResetDeath = index => {
    var { death } = this.state;

    this.setState(state => ({
      ...state,
      death: {
        ...death,
        deathCount: death.deathCount - 1,
        [`deathName${index}`]: "",
        [`deathGender${index}`]: "",
        [`deathAge${index}`]: "",
        [`deathReason${index}`]: []
      }
    }));
  };

  handleResetFamily = index => {
    var { familyDetail } = this.state;
    let { totalMember } = familyDetail;
    if (index >= 0) {
      let familyDetailData = {};
      for (let i = index + 1; i < totalMember; i++) {
        familyDetailData[`fullName${i - 1}`] = familyDetail[`fullName${i}`];
        familyDetailData[`answererRelation${i - 1}`] =
          familyDetail[`answererRelation${i}`];
        familyDetailData[`dateOfBirth${i - 1}`] =
          familyDetail[`dateOfBirth${i}`];
        familyDetailData[`age${i - 1}`] = familyDetail[`age${i}`];
        familyDetailData[`contactNumber${i - 1}`] =
          familyDetail[`contactNumber${i}`];
        familyDetailData[`gender${i - 1}`] = familyDetail[`gender${i}`];
        familyDetailData[`livingStatus${i - 1}`] =
          familyDetail[`livingStatus${i}`];
        familyDetailData[`abroadReason${i - 1}`] =
          familyDetail[`abroadReason${i}`];
        familyDetailData[`bideshiyekoYear${i - 1}`] =
          familyDetail[`bideshiyekoYear${i}`];
        familyDetailData[`abroadCountry${i - 1}`] =
          familyDetail[`abroadCountry${i}`];
        familyDetailData[`casteList${i - 1}`] = familyDetail[`casteList${i}`];
        familyDetailData[`aadibasi${i - 1}`] = familyDetail[`aadibasi${i}`];
        familyDetailData[`pahadi${i - 1}`] = familyDetail[`pahadi${i}`];
        familyDetailData[`religionList${i - 1}`] =
          familyDetail[`religionList${i}`];
        familyDetailData[`marriage${i - 1}`] = familyDetail[`marriage${i}`];
        familyDetailData[`education${i - 1}`] = familyDetail[`education${i}`];
        familyDetailData[`preprimary${i - 1}`] = familyDetail[`preprimary${i}`];
        familyDetailData[`primary${i - 1}`] = familyDetail[`primary${i}`];
        familyDetailData[`secondary${i - 1}`] = familyDetail[`secondary${i}`];
        familyDetailData[`occupation${i - 1}`] = familyDetail[`occupation${i}`];
        familyDetailData[`job${i - 1}`] = familyDetail[`job${i}`];
        familyDetailData[`security${i - 1}`] = familyDetail[`security${i}`];
        familyDetailData[`teacher${i - 1}`] = familyDetail[`teacher${i}`];
        familyDetailData[`industry${i - 1}`] = familyDetail[`industry${i}`];
        familyDetailData[`healthcondition${i - 1}`] =
          familyDetail[`healthcondition${i}`];
        familyDetailData[`unhealthy${i - 1}`] = familyDetail[`unhealthy${i}`];
        familyDetailData[`mentalyesno${i - 1}`] =
          familyDetail[`mentalyesno${i}`];
        familyDetailData[`mentaltype${i - 1}`] = familyDetail[`mentaltype${i}`];
        familyDetailData[`mentalcondition${i - 1}`] =
          familyDetail[`mentalcondition${i}`];
        familyDetailData[`mentalCardYesNo${i - 1}`] =
          familyDetail[`mentalCardYesNo${i}`];
        familyDetailData[`mentalCardtype${i - 1}`] =
          familyDetail[`mentalCardtype${i}`];
        familyDetailData[`mothertongue${i - 1}`] =
          familyDetail[`mothertongue${i}`];
        familyDetailData[`raiType${i - 1}`] = familyDetail[`raiType${i}`];
        familyDetailData[`bloodgroup${i - 1}`] = familyDetail[`bloodgroup${i}`];
        familyDetailData[`votercard${i - 1}`] = familyDetail[`votercard${i}`];
      }
      console.log("all", familyDetailData);
      this.setState(state => ({
        ...state,
        familyDetail: {
          ...familyDetail,
          ...familyDetailData,
          totalMember: familyDetail.totalMember - 1
        }
      }));
    }
  };

  isStepComplete(step) {
    return this.state.completed.indexOf(step) !== -1;
  }

  completedSteps() {
    return this.state.completed.length;
  }

  allStepsCompleted() {
    return this.completedSteps() === this.totalSteps();
  }

  isLastStep() {
    return this.state.activeStep === this.totalSteps() - 1;
  }

  handleChange = (name, value) => {
    console.log("handleChange", name.value);
    const data = name.split(".");
    const wc = this.state[data[0]];
    const assign = { [data[1]]: value };
    this.setState(state => ({
      ...state,
      [data[0]]: {
        ...wc,
        ...assign
      }
    }));
  };
  onSubmit = () => {
    const data = _.pick(
      this.state,
      "lat",
      "lng",
      "primaryHouse",
      "familyDetail",
      "death",
      "insurance",
      "secondaryHouse",
      "houseDetail",
      "agriLivestock",
      "economics",
      "business",
      "womenChild",
      "socialWelfare"
    );

    this.props.addHouseSurvey(data);
  };

  render() {
    const { classes } = this.props;
    const steps = this.getSteps();
    const { activeStep } = this.state;
    console.log(
      "member",
      this.state.familyDetail.totalMember,
      this.state.familyDetail.familyMale
    );
    return (
      <div>
        <Stepper alternativeLabel nonLinear activeStep={activeStep}>
          {steps.map((label, index) => {
            const props = {};
            const buttonProps = {};
            return (
              <Step key={label} {...props}>
                <StepButton
                  onClick={this.handleStep(index)}
                  completed={this.isStepComplete(index)}
                  {...buttonProps}
                >
                  {label}
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {this.allStepsCompleted() ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed - you&apos;re finished
              </Typography>
              <Button onClick={this.handleReset}>Reset</Button>
            </div>
          ) : (
            <div>
              <Card className={classes.card}>
                <CardHeader
                  title={steps[activeStep]}
                  // subheader="* चिन्ह भएका अनिबार्य भर्नुहोला "
                />
                <CardContent>{this.getStepContent(activeStep)}</CardContent>
              </Card>
              {/* <Typography className={classes.instructions}>

							</Typography> */}
              <div>
                <Button
                  disabled={activeStep === 0}
                  onClick={this.handleBack}
                  className={classes.button}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleNext}
                  className={classes.button}
                >
                  Next
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.onSubmit}
                >
                  submit
                </Button>

                {activeStep !== steps.length &&
                  (this.state.completed.indexOf(this.state.activeStep) >= 0 ? (
                    <Typography variant="caption" className={classes.completed}>
                      Step {activeStep + 1} already completed
                    </Typography>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleComplete}
                    >
                      {this.completedSteps() === this.totalSteps() - 1
                        ? "Finish"
                        : "Complete Step"}
                    </Button>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  root: {
    width: "98%",
    marginTop: theme.spacing.unit * 3
  },
  table: {
    minWidth: 500
  },
  tableWrapper: {
    overflowX: "auto"
  },
  fab: {
    float: "right"
  },
  button: {
    marginRight: theme.spacing.unit
  },
  backButton: {
    marginRight: theme.spacing.unit
  },
  completed: {
    display: "inline-block"
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  },
  card: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 0
  }
});

index.propTypes = {
  classes: PropTypes.object.isRequired,
  addHouseSurvey: PropTypes.func.isRequired
};

const mapStateToProps = state => ({});

export default withStyles(styles)(
  connect(mapStateToProps, { addHouseSurvey })(index)
);
